import { Box, Center, Loader } from '@mantine/core';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { IStaticDataCookiesRes, useFetchStaticDataCookiesQuery } from 'shared/api/users';
import { useAppSelector } from 'store';


const setCookies = ({ cookies, expires_at }: IStaticDataCookiesRes) => {
  const domainWithPort = `.${document.location.host.split('.').slice(-2).join('.')}`;
  const domain = domainWithPort.split(':')[0];
  Object.entries(cookies).forEach(([cookieName, cookieValue]) => {
    document.cookie = `${cookieName}=${cookieValue};expires=${expires_at};path=/;domain=${domain};`;
  });
};

export const AllocationCookies = ({ cfnCookies, children }: { cfnCookies?: IStaticDataCookiesRes, children?: ReactElement }) => {
  const [cookiesArePresent, setCookiesArePresent] = useState(false);

  useEffect(() => {
    if (cfnCookies) {
      setCookies(cfnCookies);
      setCookiesArePresent(true);
    } if (!cfnCookies) {
      setCookiesArePresent(false);
    }
  }, [cfnCookies]);

  if (!cookiesArePresent) {
    return <Box pos="relative" w="100%" h={300}>
      <Center>
        <Loader />
      </Center>
    </Box>
  }

  return <>
    {children}
  </>;
};


export default function AllocationRunsOutlet() {
  const oci = useAppSelector(getViewingOpCoId);
  const cookiesRes = useFetchStaticDataCookiesQuery(oci);

  return (
    <AllocationCookies cfnCookies={cookiesRes.isFetching ? undefined : cookiesRes.data}>
      <Outlet />
    </AllocationCookies>
  );
}