import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchAggregatedAssetEvents } from 'amp/api/assetEvents';
import { AggregationTypes, IAggregatedAssetEvent } from 'shared/types/aggregatedEvents';
import { IAsyncDataSlice } from 'shared/types/store';
import { AppDispatch, RootState } from 'store';
import { getProjectionsConsumptionLoading } from './selectors';

type BulkFetchCustomerAggregatedEventsResponse = {
  data?: Record<string, IAggregatedAssetEvent[]>,
  status: 'SUCCESS' | 'FAILED',
  serializedArgs: string
};

export type IBulkFetchCustomerAggregatedEventsArgs = {
  startDate: string
  endDate: string
  resolution: string
  customerIds: string[]
}

interface IEventsSlice {
  projectionsAggregatedLoadResp: IAsyncDataSlice<Record<string, IAggregatedAssetEvent[]>>,
}

const initialState: IEventsSlice = {
  projectionsAggregatedLoadResp: {
    data: {},
    pagination: null,
    isFetching: false,
    fetchFailed: false,
    lastReceived: null,
  },
}


export const bulkFetchProjectionsCustomerAggregatedEvents = createAsyncThunk<BulkFetchCustomerAggregatedEventsResponse | undefined, IBulkFetchCustomerAggregatedEventsArgs, {
    dispatch: AppDispatch,
    state: RootState,
  }>(
    'amp__events/fetchProjectionsPageCustomerLoad',
    async ({startDate, endDate, resolution, customerIds}, {getState, dispatch}) => {
      const sortedIds = customerIds.sort();
      const serializedArgs = JSON.stringify({startDate, endDate, resolution, customerIds: sortedIds});
      dispatch(eventsSlice.actions.initializeProjectionsPageAggregatedLoad({ serializedArgs }));
      if (getProjectionsConsumptionLoading(getState())) {
        // short circuit
        return undefined;
      }
      dispatch(eventsSlice.actions.setProjectionsPageAggregatedLoadLoading({ isFetching: true }));

      const qs = new URLSearchParams();
      qs.set('start', startDate);
      qs.set('end', endDate);
      qs.set('resolution', resolution);
      qs.set('aggregation_type', AggregationTypes.TOTAL_LOAD);

      try {
        const eventsByCustomerId: Record<string, IAggregatedAssetEvent[]> = {};
        const invRes = await Promise.all(customerIds.map(cid => fetchAggregatedAssetEvents(qs.toString() + `&customer_id=${cid}`)));
        invRes.forEach(res => {
          res.data.data.forEach(event => {
            if (event.customer_id in eventsByCustomerId) {
              eventsByCustomerId[event.customer_id].push(event);
            } else {
              eventsByCustomerId[event.customer_id] = [event];
            }
          });
        });
        return {data: eventsByCustomerId, status: 'SUCCESS', serializedArgs };
      } catch (err) {
        return {status: 'FAILED', serializedArgs };
      }
    },
  );

  const eventsSlice = createSlice({
    name: 'amp__events',
    initialState,
    reducers: {
      setProjectionsPageAggregatedLoadLoading: (state, action: PayloadAction<{isFetching: boolean}>) => {
        state.projectionsAggregatedLoadResp.isFetching = action.payload.isFetching;
      },

      initializeProjectionsPageAggregatedLoad: (state, action: PayloadAction<{serializedArgs: string}>) => {
        const { serializedArgs } = action.payload;
        if (state.projectionsAggregatedLoadResp.serializedArgs !== serializedArgs) {
          state.projectionsAggregatedLoadResp.isFetching = false;
          state.projectionsAggregatedLoadResp.serializedArgs = serializedArgs;
        }
      },
    },

    extraReducers: (builder) => {
      builder.addCase(bulkFetchProjectionsCustomerAggregatedEvents.fulfilled, (state, action) => {
        // short circuited because a request was in-flight
        if (!action.payload) return;

        const projectionsEventsState = state.projectionsAggregatedLoadResp;

        // short circuited because a new request was dispatched
        if (projectionsEventsState.serializedArgs !== action.payload.serializedArgs) return;

        projectionsEventsState.isFetching = false;

        if (action.payload.data) {
          projectionsEventsState.data = action.payload.data;
          projectionsEventsState.fetchFailed = false;
          projectionsEventsState.lastReceived = new Date();
        } else {
          projectionsEventsState.fetchFailed = true;
        }
      });
    }
  });

export default eventsSlice.reducer;