import { Group, Skeleton, Stack, Text } from '@mantine/core';
import Highcharts from 'highcharts';
import { sum } from 'ramda';
import { useMemo } from 'react';

import { useFetchEmissionsQuery, useFetchGenerationQuery } from 'amp/api/generators';
import { useGenerator } from 'amp/store/generators/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';
import { getMeasurementPreference } from 'shared/store/user/selectors';
import { IEmissionsFromGenerationData } from 'shared/types/aggregatedEvents';
import { IAssetEvent, IUtilityGenerationData } from 'shared/types/assetEvents';
import { MeasurementDisplayPreference } from 'shared/types/user';
import { numberToString } from 'shared/utils/strings';
import { useAppSelector } from 'store';


export default function GenerationChart({
  generatorId, startDate, endDate, resolution,
}: { generatorId: string, startDate: Date, endDate: Date, resolution: string }) {
  const oci = useAppSelector(getViewingOpCoId);
  const measurement = useAppSelector(getMeasurementPreference);
  const isMetric = measurement === MeasurementDisplayPreference.METRIC;
  const tonsConversion = isMetric ? 1.10231 : 1;
  const lbsConversion = isMetric ? 2.20462 : 1;

  const generatorRes = useGenerator(generatorId);
  const generator = generatorRes.data;

  const generationRes = useFetchGenerationQuery({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), generatorIds: [generatorId], resolution, customerIds: [generator?.customer_id || oci],
  }, { skip: !generatorId });
  const generation = generationRes.data?.data || [];

  const emissionsRes = useFetchEmissionsQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    resolution,
    generatorIds: [generatorId],
    customerIds: [generator?.customer_id || oci],
  });

  const [co2EmissionsTonsSeriesData, co2RateLbsPerMWhSeriesData] = useMemo(() => {
    if (!emissionsRes.data) return [[], []];

    const emissionsSeriesData: number[][] = [];
    const emissionRateSeriesData: number[][] = [];


    emissionsRes.data.data.forEach((event: IAssetEvent) => {
      const eventData = event.data as IEmissionsFromGenerationData;

      emissionsSeriesData.push([new Date(event.start_date).valueOf(), Math.round((eventData.sum_co2e_mass_lb / 2_000) / tonsConversion)]);
      emissionRateSeriesData.push([new Date(event.start_date).valueOf(), Math.round(eventData.mean_co2e_rate_lb_per_mwh / lbsConversion)]);
    });

    return [emissionsSeriesData, emissionRateSeriesData];
  }, [emissionsRes.data, lbsConversion, tonsConversion]);


  const chartOptions: Highcharts.Options = {
    tooltip: {
      valueSuffix: ' MWh',
      valueDecimals: 1,
    },
    series: [
      {
        type: 'column',
        name: 'Generation',
        color: 'var(--color-green-3)',
        data: generation.map(ae => {
          const generationData = ae.data as IUtilityGenerationData;
          return [new Date(ae.start_date).getTime(), generationData.sum_generated_wh / 1_000_000];
        }),
      }
    ]
  };

  const emissionsChartOptions: Highcharts.Options = {
    series: [
      {
        name: `Total Carbon Emissions (${isMetric ? 'metric ' : ''}tons of CO<sub>2</sub>e)`,
        data: co2EmissionsTonsSeriesData,
        type: 'area',
        color: '#4E566D',
      },
      {
        name: `Generated Carbon Intensity (${isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh)`,
        data: co2RateLbsPerMWhSeriesData,
        type: 'line',
        color: '#7FAAD1',
        yAxis: 1,
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(180, 180, 180, 0.5)'], // start
            [1, 'rgba(180, 180, 180, 0)'] // end
          ]
        },
      },
    },
    yAxis: [
      {
        title: {
          text: `<span style="color: #4E566D">\u25CF</span> ${isMetric ? 'metric ' : ''}tons of CO<sub>2</sub>e`,
          useHTML: true,
        },
        min: 0,
      },
      {
        opposite: true,
        title: {
          text: `<span style="color: #7FAAD1">\u25CF</span> CO<sub>2</sub>e ${isMetric ? 'kgs' : 'lbs'}/MWh`,
          useHTML: true,
        },
        min: 0,
      }
    ]
  };

  const asGenerationData = generation.map(ae => ae.data as IUtilityGenerationData);
  const totalGenerationMWh = sum(asGenerationData.map(aeData => aeData.sum_generated_wh)) / 1_000_000.0;
  const totalCo2EmissionsTons = sum(co2EmissionsTonsSeriesData.map(([x, y]) => y)) / tonsConversion;
  const avgCo2EmissionsLbsPerMWh = ((totalCo2EmissionsTons * 2000) / (totalGenerationMWh || 1)) / lbsConversion;
  return (
    <Stack gap={16}>
      <BasePaper titleContent="Energy Generation">
        <Group p="16px" pt="0px">
          <Stack gap={4} w="250px">
            <Skeleton visible={generationRes.isLoading}>
              <Group gap="xs" align="baseline">
                <Text fz={32} fw={700}>{numberToString(Math.round(totalGenerationMWh))}</Text>
                <Text c="var(--color-grey-4)" fz={20}>MWh</Text>
              </Group>
            </Skeleton>
            <Text c="var(--color-grey-4)" fz={12}>Total Generation</Text>
          </Stack>
        </Group>
        <BaseChart yAxisTitle="MWh Generated" overrideOptions={chartOptions} />
      </BasePaper>

      <BasePaper titleContent="Carbon Emissions">
        <Group p="16px" pt="0px">
          <Stack gap={4} w="350px">
            <Skeleton visible={emissionsRes.isLoading}>
              <Group gap="xs" align="baseline">
                <Text fz={32} fw={700}>{numberToString(Math.round(totalCo2EmissionsTons))}</Text>
                <Text c="var(--color-grey-4)" fz={20}>{isMetric && 'm'}Tons CO<sub>2</sub>e</Text>
              </Group>
            </Skeleton>
            <Text c="var(--color-grey-4)" fz={12}>Total Carbon Emissions</Text>
          </Stack>
          <Stack gap={4} w="350px">
            <Skeleton visible={emissionsRes.isLoading}>
              <Group gap="xs" align="baseline">
                <Text fz={32} fw={700}>{isNaN(avgCo2EmissionsLbsPerMWh) ? 'N/A' : numberToString(Math.round(avgCo2EmissionsLbsPerMWh))}</Text>
                <Text c="var(--color-blue-1)" fz={20}>{isMetric ? 'kg' : 'lb'} CO<sub>2</sub>e/MWh</Text>
              </Group>
            </Skeleton>
            <Text c="var(--color-grey-4)" fz={12}>Generated Carbon Intensity</Text>
          </Stack>
        </Group>
        <BaseChart overrideOptions={emissionsChartOptions} />
      </BasePaper>
    </Stack>
  );
}