import { useAdminPaginateAssetsQuery } from "admin/api/assets";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'description',
    displayValue: 'Description',
  },
  {
    key: 'type',
    displayValue: 'Type',
  },
  {
    key: 'meta',
    displayValue: 'Metadata',
  },
  {
    key: 'location',
    displayValue: 'Location data',
  },
]

const CustomerAssetsView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateAssetsQuery({
    page,
    perPage,
    customerId,
  }, {skip: !customerId});

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onClickRow = (assetId: string) => {
    nav(`/admin/customers/${customerId}/assets/${assetId}`);
  };

  const pagination = data?.meta.pagination;
  const assets = data?.data || [];

  return <AdminViewContainer title="Assets">
    <BaseTable
      rows={assets.map(asset => ({
        id: asset.id,
        name: asset.name,
        description: asset.description,
        type: asset.asset_type,
        location: JSON.stringify(asset.location),
        meta: JSON.stringify(asset.meta),
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default CustomerAssetsView;