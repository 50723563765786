import { Group, Select, Title } from '@mantine/core';

import Tabs from 'amp/components/Tabs';
import { Outlet } from 'react-router-dom';
import { LOGIN_URL } from 'shared/constants/resources';
import { getCurrentUserRole, getRawHasRolePermission } from 'shared/store/user/selectors';
import { setRoleOverride } from 'shared/store/user/slice';
import { UserRole } from 'shared/types/user';
import { useAppDispatch, useAppSelector } from 'store';
import './style.css';


const roleOptions = Object.entries(UserRole).map(([label, value]) => ({
  value,
  label
}));

export default function SettingsOutlet() {
  const isSadmin = useAppSelector(s => getRawHasRolePermission(s, UserRole.SADMIN));
  const role = useAppSelector(getCurrentUserRole);
  const dispatch = useAppDispatch();
  const setRole = (role: UserRole | null) => {
    dispatch(setRoleOverride(role || null));
  }

  return <div>
    <Group justify='space-between' p="16px">
      <Title order={1} className="settings-root--title">Settings</Title>
      <Group>
        {isSadmin &&
          <Select
            label="Override role"
            description="Singularity admins can explicitly set their role to view the app without having to logout/login"
            maw={280}
            data={roleOptions}
            value={role}
            onChange={(e) => e && setRole(e as UserRole)}
            clearable
          />
        }
        <a href={`${LOGIN_URL}/logout?product=amp`}>Logout</a>
      </Group>
    </Group>
    <Tabs tabs={[
      {title: 'Preferences', path: '/dashboard/settings/preferences'},
      {title: 'Users', path: '/dashboard/settings/users', disabled: true},
      {title: 'Audit Logs', path: '/dashboard/settings/audit-logs'},
      {title: 'Certificate Management', path: '/dashboard/settings/certificate-props'},
    ]}/>
    <Outlet />
  </div>;
}