import { queries } from "shared/api/queries";
import { IDashboardPaginationResponse } from "shared/types/api";
import { IAsset } from "shared/types/asset";


interface IListAssetsArgs {
  page: number
  perPage: number
  customerId: string
}


const adminAssetsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateAssets: build.query<IDashboardPaginationResponse<IAsset>, IListAssetsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        qs.set('customer_id', body.customerId);

        return {
          url: `/admin/customer_assets/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'Assets', id: 'PAGE'}],
    }),

    adminFetchAsset: build.query<{data: IAsset}, string>({
      query: (id) => `/admin/customer_assets/${id}`,
    }),

  }),
  overrideExisting: false,
});


export const {
  useAdminFetchAssetQuery,
  useAdminPaginateAssetsQuery,
} = adminAssetsQueries;