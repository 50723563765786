import { Button, Title } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';

import { usePaginateCertPropDefsQuery } from 'amp/api/certPropDefs';
import BaseTable, { IBaseTableColumn } from 'shared/components/Table/baseTable';

import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useAppSelector } from 'store';
import './style.css';


const certificatePropertyDefinitionsTableColumns: IBaseTableColumn[] = [
  {key: 'id', displayValue: 'ID'},
  {key: 'customer_id', displayValue: 'Customer ID'},
  {key: 'display_name', displayValue: 'Display Name'},
  {key: 'code_name', displayValue: 'Code Name'},
  {key: 'description', displayValue: 'Description'},
  {key: 'property_type', displayValue: 'Type'},
];


export default function SettingsCertificatePropDefsView() {
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const nav = useAmpNav();

  const res = usePaginateCertPropDefsQuery({page, perPage, customerId: oci});
  const pagination = res.data?.meta.pagination;
  const totalPages = Math.ceil((pagination?.total || 1) / perPage);

  const onTableRowClick = (certPropertyId: string) => {
    nav(`/dashboard/settings/certificate-props/${certPropertyId}`);
  };

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setParams(newParams => {
      // reset the page when search params change
      if (paramName !== 'p') {
        newParams.delete('p');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }

      return newParams;
    })
  };

  const onPageChange = (newPage: number) => {
    onParamChange('p', newPage.toString());
  };

  const rows = res.data?.data.map((row) => ({
    id: row.id,
    customer_id: row.customer_id,
    display_name: row.display_name,
    code_name: row.code_name,
    description: row.description,
    property_type: row.property_type, // TODO map all property types to something human readable: e.g. json -> structured data
  })) || [];

  return (
    <div>
      <div className="cert-prop-defs--title-container">
        <Title order={1} m={'lg'}>
          Certificate Property Definitions
        </Title>
        <Button component={AmpLink} to="/dashboard/settings/certificate-props/create" className="cert-prop-defs--create-button">
          CREATE
        </Button>
      </div>
      <BaseTable
        columnNames={certificatePropertyDefinitionsTableColumns}
        rows={rows}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onTableRowClicked={onTableRowClick}
      />
    </div>
  );
}