import { Button, Group, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchScheduledTaskQuery, useAdminResetScheduledTaskMutation, useAdminSetScheduledTaskDoneMutation } from "admin/api/scheduledTasks";
import AdminViewContainer from "admin/components/ViewContainer";
import { timestampToLongDate } from "shared/utils/dates";
import './style.css';


const ScheduledTaskView = () => {
  const { taskId='' } = useParams<{taskId: string}>();
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchScheduledTaskQuery(taskId, {skip: !taskId});

  const [reset] = useAdminResetScheduledTaskMutation();
  const [done] = useAdminSetScheduledTaskDoneMutation();

  const cannotReset = !(data?.data) || data.data.status !== 'error';

  if (isError) {
    return <div>Failed to load task</div>;
  }

  const task = data?.data;

  return <AdminViewContainer title={task?.name || taskId} actions={
    <>
      <Button mr={16} onClick={() => done(taskId)} variant="light" size="xs">Set task as done</Button>
      <Button mr={16} onClick={() => reset(taskId)} variant="light" size="xs" disabled={cannotReset}>Reset task</Button>
    </>
  }>
    {(isLoading || !task) && <Loader />}
    {task && <div>
      <Group>
        <div>ID</div>
        <div>{task.id}</div>
      </Group>
      <Group>
        <div>Name</div>
        <div>{task.name}</div>
      </Group>
      <Group>
        <div>Status</div>
        <div>{task.status}</div>
      </Group>
      <Group>
        <div>Data</div>
        <pre>{JSON.stringify(task.data, undefined, 2)}</pre>
      </Group>
      <Group>
        <div>Metadata</div>
        <pre>{JSON.stringify(task.meta, undefined, 2)}</pre>
      </Group>
      <Group>
        <div>Last updated</div>
        <div>{timestampToLongDate(task.updated_at)}</div>
      </Group>
      <Group>
        <div>Scheduled for</div>
        <div>{timestampToLongDate(task.scheduled_for)}</div>
      </Group>
      <Group>
        <div>Expires at</div>
        <div>{task.ttl ? timestampToLongDate(task.ttl) : <em>does not expire</em>}</div>
      </Group>
    </div>}
  </AdminViewContainer>
};

export default ScheduledTaskView;
