import { Button, Group, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchRunQuery } from "admin/api/allocationRuns";
import { useAdminFetchUserQuery } from "admin/api/users";
import AdminViewContainer from "admin/components/ViewContainer";
import { isAxiosError } from "axios";
import { useState } from "react";
import { API } from "shared/api";
import './style.css';


const CustomerAllocationRunView = () => {
  const { runId='' } = useParams<{runId: string}>();
  const [downloading, setDownloading] = useState(false);
  const [downloadErrMsg, setDownloadErrMsg] = useState('');
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchRunQuery(runId, {skip: !runId});

  const {
    data: userRes
  } = useAdminFetchUserQuery(data?.data.created_by || '', {skip: !data || !data.data.created_by});

  const onClickDownload = () => {
    setDownloading(true);
    API.get(`/api/admin/allocation_runs/${runId}/results-as-csv`)
      .then(res => {
        if (isAxiosError(res)) {
          throw res;
        }
        const errorFile = new File([res.data as string], 'errors.csv', {type: 'text/csv'});
        window.location.assign(window.URL.createObjectURL(errorFile));
      })
      .catch(err => {
        if (isAxiosError(err)) {
          const status = err.response?.status;
          const errData = err.response?.data;
          if (status && status < 500 && status >= 400 && 'error_message' in errData) {
            setDownloadErrMsg(errData.error_message as string);
          }
        } else {
          console.warn('Unrecognized error', err);
        }
      })
      .finally(() => {
        setDownloading(false);
      })
  }

  if (isError) {
    return <div>Failed to load allocation run</div>;
  }

  const run = data?.data;

  return <AdminViewContainer title={run?.description || <em>no note</em>} actions={
    <Group mb={16}>
      <Button size="xs" onClick={onClickDownload} loading={downloading} disabled={downloading}>Download CSV results</Button>
      {downloadErrMsg && <div className="admin-allocation-run-download--error">{downloadErrMsg}</div>}
    </Group>
  }>
    {(isLoading || !run) && <Loader />}
    {run &&
      <div>
        <Group>
          <div>Note</div>
          <div>{run.description}</div>
        </Group>
        <Group>
          <div>Status</div>
          <div>{run.status}</div>
        </Group>
        <Group>
          <div>Created by</div>
          <div>{userRes ? `${userRes.user.name} (${userRes.user.email})` : run.created_by}</div>
        </Group>
        <Group>
          <div>Metadata</div>
          <pre>{JSON.stringify(run.allocation_config, undefined, 2)}</pre>
        </Group>
      </div>
    }
  </AdminViewContainer>
};

export default CustomerAllocationRunView;
