import { LoadingOverlay } from '@mantine/core';
import { wrapCreateBrowserRouter } from '@sentry/react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Heatmap from 'highcharts/modules/heatmap';
import SolidGauge from 'highcharts/modules/solid-gauge';
import VariablePie from 'highcharts/modules/variable-pie';
import { useEffect } from 'react';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import adminRoutes, { AdminErrorBoundary, AdminOutlet } from 'admin/routes';
import { connect } from 'socket';
import './App.css';
import ampRoutes, { AmpErrorBoundary, AmpOutlet } from './amp/routes';
import { getCurrentUser, getIsBootstrapFailed, getIsBootstrapLoading, getIsBootstrapNotStarted, getWsToken } from './shared/store/user/selectors';
import { kickoffBootstrap } from './shared/store/user/slice';
import { useAppDispatch, useAppSelector } from './store';

if (typeof Highcharts === "object" && typeof window !== "undefined") {
  HighchartsMore(Highcharts);
  SolidGauge(Highcharts);
  VariablePie(Highcharts);
  Heatmap(Highcharts);
}

const routes = createRoutesFromElements(
  <>
    {/* TODO: lazy load AMP routes when we have other apps that don't want the AMP packages loaded */}
    <Route path="/dashboard" element={<AmpOutlet />} ErrorBoundary={AmpErrorBoundary}>
      {ampRoutes}
    </Route>

    <Route path="/admin" element={<AdminOutlet />} ErrorBoundary={AdminErrorBoundary}>
      {adminRoutes}
    </Route>

    {/* no match fallback rule */}
    <Route path="*" element={<Navigate to="/dashboard" replace />}/>
  </>
)

const createRouter = wrapCreateBrowserRouter(createBrowserRouter)

function App() {
  const dispatch = useAppDispatch();

  const router = createRouter(routes);

  const shouldBootstrap = useAppSelector(getIsBootstrapNotStarted);
  const loading = useAppSelector(getIsBootstrapLoading);
  const failed = useAppSelector(getIsBootstrapFailed);
  const wsToken = useAppSelector(getWsToken);
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (shouldBootstrap) {
      dispatch(kickoffBootstrap());
    }
  }, [shouldBootstrap, dispatch]);

  useEffect(() => {
    if (wsToken) {
      connect({
        wsToken,
        channels: [`user:${user.id}`, `customer:${user.customer_id}`],
      });
    }
  }, [wsToken, user]);


  // if bootstrap failed, the user will be redirected to login, don't render the app
  if (loading || failed) {
    return <div className="app-bootstrap--loading">
      <LoadingOverlay visible={true} />
    </div>
  }

  return <RouterProvider router={router}/>;
}

export default App;
