import { Box, Center, Loader } from '@mantine/core';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useBulkFetchGeneratorsQuery, useFetchGenerationQuery } from 'amp/api/generators';
import { useProjectionsBulkCustomerEvents } from 'amp/store/events/hooks';
import { useCustomersForecast } from 'amp/store/forecastRuns/hooks';
import { useGeneratorsForecast } from 'amp/store/generators/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { IAssetEvent, IEnergyConsumptionData, IUtilityGenerationData } from 'shared/types/assetEvents';
import { groupData } from 'shared/utils/chart';
import { getLastYearStart, getThisYearEnd, getYearsBetweenDates } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';

const customerColors = ['#4d7396', '#4263eb', '#748ffc', '#74c0fc'];
const generatorColors = ['#1a1a1a', '#686868', '#969696'];
const getLineColor = (idx: number, type: 'customer' | 'generator') => {
  if (type === 'customer') {
    const moddedIdx = idx % customerColors.length;
    return customerColors[moddedIdx];
  } else {
    const moddedIdx = idx % generatorColors.length;
    return generatorColors[moddedIdx];
  }
}

const resolutionToGrouping: Record<string, 'hour' | 'day' | 'month'> = {
  '1h': 'hour',
  '1d': 'day',
  '1M': 'month',
}

const ProjectionsChart = () => {
  const [params] = useSearchParams();
  const selectedGenIds = params.getAll('sg');
  const selectedCustomerIds = params.getAll('sc');
  const startDateStr = params.get('s') || getLastYearStart();
  const endDateStr = params.get('e') || getThisYearEnd();
  const startDate = useMemo(() => new Date(startDateStr), [startDateStr]);
  const endDate = useMemo(() => new Date(endDateStr), [endDateStr]);
  const resolution = params.get('r') || '1d';
  const grouping = resolutionToGrouping[resolution] || 'day';
  const oci = useAppSelector(getViewingOpCoId);
  const isAggregating = params.get('ag');
  const showForecasts = !!params.get('f');

  const generatorsRes = useBulkFetchGeneratorsQuery({ ids: selectedGenIds, customerId: oci });
  const generators = useMemo(() => generatorsRes.data?.data || [], [generatorsRes]);
  const forecastsRes = useGeneratorsForecast(selectedGenIds, getYearsBetweenDates(startDate, endDate));
  const loadForecastsRes = useCustomersForecast(selectedCustomerIds, getYearsBetweenDates(startDate, endDate));

  const opCoCustomers = new Set(generators.map(g => g.customer_id));
  const customerIds = Array.from(opCoCustomers).sort();

  const generationDataRes = useFetchGenerationQuery({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), resolution, generatorIds: selectedGenIds, customerIds,
  }, { skip: selectedGenIds.length === 0 });

  const customersRes = useUtilityCustomers();
  const customers = useMemo(() => customersRes.data || [], [customersRes]);
  const consumptionDataRes = useProjectionsBulkCustomerEvents({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), resolution, customerIds: selectedCustomerIds,
  });
  const consumptionDataByCustomer = useMemo(() => consumptionDataRes.data || {}, [consumptionDataRes]);

  const eventsByGenId: Record<string, IAssetEvent[]> = useMemo(() => {
    const generationData = generationDataRes.data?.data || [];
    const genIdsToEvents: Record<string, IAssetEvent[]> = {};
    generationData.forEach(ae => {
      if (!genIdsToEvents[ae.asset_id]) {
        genIdsToEvents[ae.asset_id] = [ae];
      } else {
        genIdsToEvents[ae.asset_id].push(ae);
      }
    });

    return genIdsToEvents;
  }, [generationDataRes.data]);

  const [generationSeries, consumptionSeries, projectedGenerationSeries, projectedConsumptionSeries] = useMemo(() => {
    if (isAggregating) {
      const generationWhByTimestamp: Record<string, number> = {};
      const consumptionKwhByTimestamp: Record<string, number> = {};
      const generationForecastWhByTimestamp: Record<string, number> = {};
      const consumptionForecastWhByTimestamp: Record<string, number> = {};
      loadForecastsRes.data?.filter(({start_date}) => {
        const date = new Date(start_date);
        return date >= startDate && date <= endDate;
      }).forEach(({start_date, y_axis_value_wh}) => {
        consumptionForecastWhByTimestamp[start_date] = y_axis_value_wh;
      });
      forecastsRes.data?.filter(({start_date}) => {
        const date = new Date(start_date);
        return date >= startDate && date <= endDate;
      }).forEach(({start_date, y_axis_value_wh}) => {
        generationForecastWhByTimestamp[start_date] = y_axis_value_wh;
      });
      Object.values(eventsByGenId).forEach(aeList => {
        aeList.forEach(ae => {
          const asGenData = ae.data as IUtilityGenerationData;
          if (!generationWhByTimestamp[ae.start_date]) {
            generationWhByTimestamp[ae.start_date] = asGenData.sum_generated_wh;
          } else {
            generationWhByTimestamp[ae.start_date] += asGenData.sum_generated_wh;
          }
        });
      });
      Object.values(consumptionDataByCustomer).forEach(aggEventList => {
        aggEventList.forEach(aggEvent => {
          const asConsumptionData = aggEvent.data as IEnergyConsumptionData;
          if (!consumptionKwhByTimestamp[aggEvent.start_date]) {
            consumptionKwhByTimestamp[aggEvent.start_date] = asConsumptionData.sum_consumed_kwh;
          } else {
            consumptionKwhByTimestamp[aggEvent.start_date] += asConsumptionData.sum_consumed_kwh;
          }
        });
      });
      const genSeries: Highcharts.SeriesLineOptions[] = [{
        type: 'line',
        name: 'Aggregated Generation',
        color: getLineColor(0, 'generator'),
        data: Object.entries(generationWhByTimestamp).map(([timestamp, sumWh]) => {
          const startDate = new Date(timestamp);
          return { x: startDate.valueOf(), y: sumWh / 1_000_000 };
        }).sort((a, b) => a.x - b.x),
        dashStyle: 'Solid',
      }];
      const dailyProjGenData = groupData(Object.entries(generationForecastWhByTimestamp).map(([timestamp, value]) => ({epoch: new Date(timestamp).valueOf(), value})), grouping);
      const dailyProjLoadData = groupData(Object.entries(consumptionForecastWhByTimestamp).map(([timestamp, value]) => ({epoch: new Date(timestamp).valueOf(), value})), grouping);
      const projGenSeries: Highcharts.SeriesLineOptions[] = showForecasts ? [{
        type: 'line',
        name: 'Projected Generation',
        color: getLineColor(0, 'generator'),
        data: dailyProjGenData.map(([x, sumWh]) => ({ x, y: sumWh / 1_000_000 })).sort((a, b) => a.x - b.x),
        dashStyle: 'Dash',
        lineWidth: 1,
      }] : [];
      const loadSeries: Highcharts.SeriesLineOptions[] = [{
        type: 'line',
        name: 'Aggregated Consumption',
        color: getLineColor(0, 'customer'),
        data: Object.entries(consumptionKwhByTimestamp).map(([timestamp, sumKwh]) => {
          const startDate = new Date(timestamp);
          return { x: startDate.valueOf(), y: sumKwh / 1_000 };
        }).sort((a, b) => a.x - b.x),
        dashStyle: 'Solid',
      }];
      const projLoadSeries: Highcharts.SeriesLineOptions[] = showForecasts? [{
        type: 'line',
        name: 'Projected Consumption',
        color: getLineColor(0, 'customer'),
        data: dailyProjLoadData.map(([x, sumWh]) => ({ x, y: sumWh / 1_000_000 })).sort((a, b) => a.x - b.x),
        dashStyle: 'Dash',
        lineWidth: 1,
      }]: [];
      return [genSeries, loadSeries, projGenSeries, projLoadSeries];
    } else {
      const genSeries: Highcharts.SeriesLineOptions[] = selectedGenIds.length > 0 ?
        Object.entries(eventsByGenId).map(([genId, events], idx) => {
          return {
            type: 'line',
            name: generators.find(g => g.id === genId)?.name || 'Unknown Generator',
            color: getLineColor(idx, 'generator'),
            data: events.map(ae => {
              const asGenData = ae.data as IUtilityGenerationData;
              const startDate = new Date(ae.start_date);
              return {
                y: asGenData.sum_generated_wh / 1_000_000,
                x: startDate.valueOf(),
              }
            }).sort(({x: x1}, {x: x2}) => x1 - x2),
            dashStyle: 'Solid',
          }
        }) : [];
      const loadSeries: Highcharts.SeriesLineOptions[] = selectedCustomerIds.length > 0 ?
        Object.entries(consumptionDataByCustomer).map(([custId, events], idx) => {
          return {
            type: 'line',
            name: customers.find(c => c.id === custId)?.name || 'Unknown Customer',
            color: getLineColor(idx, 'customer'),
            data: events.map(ae => {
              const asConsumptionData = ae.data as IEnergyConsumptionData;
              const startDate = new Date(ae.start_date);
              return {
                y: asConsumptionData.sum_consumed_kwh / 1_000,
                x: startDate.valueOf(),
              }
            }).sort(({x: x1}, {x: x2}) => x1 - x2),
            dashStyle: 'Solid',
          }
        }) : [];
        const projGenSeries: Highcharts.SeriesLineOptions[] = selectedGenIds.length > 0 && showForecasts ?
          Object.entries(forecastsRes.nonGroupedData || {}).map(([genId, events], idx) => {
            const dailyProjGenData = groupData(events.filter(({start_date}) => {
              const date = new Date(start_date);
              return date >= startDate && date <= endDate;
            }).map(ae => ({epoch: new Date(ae.start_date).valueOf(), value: ae.y_axis_value_wh})), grouping);
            return {
              type: 'line',
              name: `${generators.find(g => g.id === genId)?.name || 'Unknown Generator'} projection`,
              color: getLineColor(idx, 'generator'),
              data: dailyProjGenData.map(([x, value]) => ({y: value / 1_000_000, x})).sort(({x: x1}, {x: x2}) => x1 - x2),
              dashStyle: 'Dash',
              lineWidth: 1,
            }
          }) : [];
        const projLoadSeries: Highcharts.SeriesLineOptions[] = selectedCustomerIds.length > 0 && showForecasts ?
          Object.entries(loadForecastsRes.nonGroupedData || {}).map(([custId, events], idx) => {
            const dailyProjCustData = groupData(events.filter(({start_date}) => {
              const date = new Date(start_date);
              return date >= startDate && date <= endDate;
            }).map(ae => ({epoch: new Date(ae.start_date).valueOf(), value: ae.y_axis_value_wh})), grouping);
            return {
              type: 'line',
              name: `${customers.find(c => c.id === custId)?.name || 'Unknown Customer'} projection`,
              color: getLineColor(idx, 'customer'),
              data: dailyProjCustData.map(([x, value]) => ({y: value / 1_000_000, x})).sort(({x: x1}, {x: x2}) => x1 - x2),
              dashStyle: 'Dash',
              lineWidth: 1,
            }
          }) : [];
      return [genSeries, loadSeries, projGenSeries, projLoadSeries];
    }
  }, [isAggregating, consumptionDataByCustomer, eventsByGenId, selectedCustomerIds, selectedGenIds, customers, generators, forecastsRes, loadForecastsRes, grouping, endDate, startDate, showForecasts]);

  const hasAnythingSelected = selectedGenIds.length || selectedCustomerIds.length;
  const chartOptions: Highcharts.Options = {
    chart: {
      height: 320,
    },
    lang: {
      noData: hasAnythingSelected ? 'No data found for the selected time range' : 'Please select a generator or customer below to begin',
    },
    yAxis: {
      title: {
        text: "Energy (MWh)",
      },
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: ' MWh'
    },
    legend: {
      enabled: true,
      verticalAlign: 'bottom',
    },
    series: [...generationSeries, ...consumptionSeries, ...projectedGenerationSeries, ...projectedConsumptionSeries],
  }

  const isLoading = generationDataRes.isLoading || generationDataRes.isFetching || consumptionDataRes.isLoading;
  return (
    <>
      {isLoading && <Box pos="relative" w="100%" h="200px">
        <Center>
          <Loader />
        </Center>
      </Box>}
      {!isLoading && <BaseChart overrideOptions={chartOptions} />}
    </>
  )
}

export default ProjectionsChart;