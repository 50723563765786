import { Button, Select } from "@mantine/core";
import { useAdminPaginateWorkerJobsQuery, useAdminPauseWorkerJobMutation, useAdminRestartWorkerJobMutation } from "admin/api/workerJobs";
import CreateWorkerJobModal from "admin/components/CreateWorkerJobModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { MouseEvent, ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";
import { timestampToLongDate } from "shared/utils/dates";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'completion',
    displayValue: '% Complete',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'created',
    displayValue: 'Created',
  },
  {
    key: 'updated',
    displayValue: 'Updated',
  },
  {
    key: 'actions',
    displayValue: '',
  },
]
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const WorkerJobsView = () => {
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const status = params.get('s') || 'in_progress';
  const [pause] = useAdminPauseWorkerJobMutation();
  const [restart] = useAdminRestartWorkerJobMutation();
  const [createJobModalOpen, setCreateJobModalOpen] = useState(false);

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateWorkerJobsQuery({
    page,
    perPage,
    status,
  });

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onStatusChange = (newStatus: string | null) => {
    if (!newStatus) return;

    setSearchParams(newParams => {
      newParams.set('s', newStatus);
      newParams.set('p', '1');
      return newParams;
    });
  }

  const onClickRow = (jobId: string) => {
    nav(`/admin/async-work/jobs/${jobId}`);
  };

  const pagination = data?.meta.pagination;
  const jobs = data?.data || [];

  return <AdminViewContainer title="Jobs" actions={
    <div>
      <Button size="xs" onClick={() => setCreateJobModalOpen(true)}>Start new job</Button>
    </div>
  }>
    <CreateWorkerJobModal isOpen={createJobModalOpen} onClose={() => setCreateJobModalOpen(false)}/>
    <div>
      <Select maw={160} mt={16} mb={8} value={status} data={[
        {label: 'In Progress', value: 'in_progress'},
        {label: 'Paused', value: 'paused'},
        {label: 'Error', value: 'rejected'},
        {label: 'Done', value: 'done'},
        {label: 'Branch done', value: 'branch_done'},
      ]} onChange={onStatusChange} />
    </div>
    <BaseTable
      rows={jobs.map(job => {
        let actions: ReactNode = '';
        if (status === 'in_progress') {
          const onClickPause = (e: MouseEvent) => {
            e.stopPropagation();
            pause(job.id);
          }
          actions = <div><Button size="xs" onClick={onClickPause}>Pause</Button></div>;
        } else if (status === 'paused' || status === 'rejected') {
          const onClickRestart = (e: MouseEvent) => {
            e.stopPropagation();
            restart(job.id);
          }
          actions = <div><Button size="xs" onClick={onClickRestart}>Restart</Button></div>;
        }
        return {
          id: job.id,
          name: job.name,
          completion: job.meta.progress_percent ? Math.round(job.meta.progress_percent as number) : 'Unknown',
          created: timestampToLongDate(job.created_at, tz),
          updated: timestampToLongDate(job.updated_at, tz),
          actions,
        }
      })}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default WorkerJobsView;