import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, TextInput } from "@mantine/core";
import { useAdminSendAmpWelcomeEmailMutation } from "admin/api/users";
import { FormEvent, useEffect, useState } from "react";
import { IUser } from "shared/types/user";



const SendWelcomeEmailModal = ({
  onClose,
  isOpen,
  user,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, user: IUser}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inviter, setInviter] = useState('');
  const [send] = useAdminSendAmpWelcomeEmailMutation();

  useEffect(() => {
    setInviter('');
  }, [user]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!user || !inviter) {
      return
    }
    try {
      await send({userId: user.id, invited_by: inviter}).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setInviter('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Send AMP welcome email to user"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The name of the inviter</InputLabel>
          <InputDescription>This will appear in the email as &quot;(NAME) has invited you to join the Singularity Platform&quot;</InputDescription>
          <TextInput value={inviter} onChange={(e) => setInviter(e.target.value)}/>
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} disabled={inviter.length < 3} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default SendWelcomeEmailModal;