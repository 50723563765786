import { NavigateOptions, To, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store";
import { getViewingOpCoId } from "./store/ui/selectors";


export const useAmpNav = () => {
  const nav = useNavigate();
  const oci = useAppSelector(getViewingOpCoId);

  return (to: To, options?: NavigateOptions | undefined) => {
    if (typeof to === 'string') {
      const path = to.split('?')[0] || '';
      const qs = to.split('?')[1] || '';
      const query = new URLSearchParams(qs);
      if (!query.has('oci') && oci) {
        query.set('oci', oci);
      }
      const newTo = `${path}?${query}`;
      nav(newTo, options);
    } else {
      const query = new URLSearchParams(to.search || '');
      if (!query.has('oci') && oci) {
        query.set('oci', oci);
      }
      const newTo = {
        ...to,
        search: `${query}`,
      };
      nav(newTo, options);
    }
  }
}

export const usePageParams = ({defaultPage=1, defaultPerPage=10}: {defaultPage?: number, defaultPerPage?: number}) => {
  const [params] = useSearchParams();

  const page = isNaN(parseInt(params.get('p') || defaultPage?.toString())) ? defaultPage : parseInt(params.get('p') || defaultPage.toString());
  const perPage = isNaN(parseInt(params.get('ps') || defaultPerPage.toString())) ? defaultPerPage : parseInt(params.get('ps') || defaultPerPage.toString());

  return {
    page,
    perPage,
  }
}