import { IGenerator, dataToDisplayValues as genDTDV } from './generator';


interface IMarketCounterpartyLocation {
  us_state?: string | null
  lat_lng?: string[] | null
  zip_code?: string | null
  eia_balancing_authority?: string | null
}

interface IMarketCounterpartyMetadata {
  point_of_delivery: string | null
  raw_external_ids: string[] | null
}

// TODO: make a class that implements dataToDisplayValues?
export interface IMarketCounterparty {
  id: string
  customer_id: string
  created_at: string
  updated_at?: string
  parent_id?: string
  location: IMarketCounterpartyLocation
  // TODO: enum
  asset_type: string
  // TODO: enum
  base_resolution?: string

  name: string
  description?: string | null
  meta: IMarketCounterpartyMetadata
}

export type IGenerationSource = IGenerator | IMarketCounterparty

export const isGenerator = (subject: any): subject is IGenerator => {
  return typeof subject === 'object' && subject !== null && 'asset_type' in subject && subject.asset_type === 'generator'
}


export interface IFetchSourceResponse {
  source: IGenerationSource;
}


export const dataToDisplayValues = (source?: IGenerationSource) => {
  if (source?.asset_type === 'market_counterparty') {
    const asCounterparty = source as IMarketCounterparty;
    return {
      // base fields
      name: asCounterparty?.name || '',
      description: asCounterparty?.description || '',

      // location fields
      usState: asCounterparty?.location.us_state || '',
      zipCode: asCounterparty?.location.zip_code || '',
      eiaBalancingAuthority: asCounterparty?.location.eia_balancing_authority || '',

      // meta fields
      pointOfDelivery: asCounterparty?.meta.point_of_delivery || '',
      rawExternalIds: asCounterparty?.meta.raw_external_ids || [],
    }
  } else {
    const asGenerator = source as IGenerator;
    return genDTDV(asGenerator);
  }
};

export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
    name: 'name',
    description: 'description',
    us_state: 'usState',
    // TODO: decompose one field into multiple
    // lat_lng: 'latLng'
    zip_code: 'zipCode',
    eia_balancing_authority: 'eiaBalancingAuthority',
    raw_external_ids: 'rawExternalIds',
    point_of_delivery: 'pointOfDelivery',
  };

  Object.entries(errs).forEach(([field, errors]) => {
    if (errors.length && fieldMap[field]) {
      displayErrors[fieldMap[field]] = errors[0];
    }
  });

  return displayErrors;
};
