import { Button, Group, InputLabel, InputWrapper, Modal, Select } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useAdminPatchUserMutation } from "admin/api/users";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { getCurrentUser } from "shared/store/user/selectors";
import { IUser, UserStatus } from "shared/types/user";
import { useAppSelector } from "store";

import './style.css';


const statusOptions = Object.entries(UserStatus).map(([label, value]) => ({
  value,
  label
}));


const EditStatusModal = ({
  onClose,
  isOpen,
  user,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, user: IUser}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [patch] = useAdminPatchUserMutation();
  const currentUser = useAppSelector(getCurrentUser);
  const defaultStatus = useMemo(() => user.status || UserStatus.ACTIVE, [user]);
  const [status, setStatus] = useState(defaultStatus);

  useEffect(() => {
    setStatus(defaultStatus);
  }, [defaultStatus]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!user) {
      return
    }
    try {
      await patch({
        userId: user.id,
        customer_id: user.customer_id,
        role: user.role,
        status,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setStatus(UserStatus.ACTIVE);
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this user's status"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The status of the user</InputLabel>
          <Select data={statusOptions} value={status} onChange={(e) => e && setStatus(e as UserStatus)} />
          {user.id === currentUser.id && <div className="admin-edit-user-role--warning"><IconAlertCircle color="var(--color-se-red-1)"/>If you edit your status, you may immediately lose access to the platform.</div>}
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditStatusModal;