
import { sum } from 'ramda';
import { useMemo } from 'react';

import BaseChart from 'shared/components/Chart/baseChart';
import { IProgramAllocationSummaryResult } from 'shared/types/allocation';
import { ICustomer } from 'shared/types/customer';


const AllocationResultsOverviewGenMatchChart = ({customers, programResults}: {customers: ICustomer[], programResults: IProgramAllocationSummaryResult | undefined}) => {
  const [allocatedMwhByCustomer, totalAllocatedMWh] = useMemo(() => {
    if (!programResults) {
      return [{}, 0];
    }

    const mWhAllocatedByCustomerId: Record<string, number> = {};
    programResults.hourly_results.forEach(hourResult => {
      Object.values(hourResult.program_allocation_by_subscription_id).forEach(subResult => {
        if (!mWhAllocatedByCustomerId[subResult.customer_id]) {
          mWhAllocatedByCustomerId[subResult.customer_id] = 0;
        }
        const totalAllocatedForSubscription = subResult.allocated_wh / 1_000_000;
        mWhAllocatedByCustomerId[subResult.customer_id] += totalAllocatedForSubscription;
      });
    });

    const totalAllocated = sum(Object.values(mWhAllocatedByCustomerId));
    return [mWhAllocatedByCustomerId, totalAllocated];
  }, [programResults]);

  const hourlyResults = programResults?.hourly_results || [];
  const totalGenMWh = sum(hourlyResults.map(result => result.generation_mwh));
  const totalUnmatchedMWh = Math.max(totalGenMWh - totalAllocatedMWh, 0);

  const matchedPercentStr = ((totalAllocatedMWh / (totalGenMWh || 1)) * 100).toFixed(1);

  const customerSeries = Object.entries(allocatedMwhByCustomer).map(([customerId, mwhAllocated]) => {
    const customer = customers.find(c => c.id === customerId);
    return {
      name: customer?.name || 'unknown customer',
      y: mwhAllocated,
      z: 100,
    }
  });
  const pieChartOptions: Highcharts.Options = {
    chart: {
      type: 'variablepie',
      height: 400,
      width: 300,
    },
    legend: {
      enabled: false,
      alignColumns: false,
      padding: 0,
      margin: 0,
    },
    title: {
      verticalAlign: 'middle',
      align: 'center',
      floating: true,
      text: totalGenMWh > 0 ? `${matchedPercentStr}%` : 'No Generation',
      style: {
        color: 'var(--color-blue-3)',
        fontSize: '32px',
        fontWeight: '700',
      }
    },
    plotOptions: {
      variablepie: {
        innerSize: '50%',
        borderWidth: 0,
        borderRadius: 0,
        borderColor: '#fff',
        dataLabels: {
          alignTo: 'plotEdges',
          borderRadius: 4,
          shadow: true,
          backgroundColor: 'white',
          formatter: function() {
            const formattedNum = (((this.y || 0) / totalGenMWh) * 100).toFixed(1);
            return `${this.key}<br/><span><span style="color:${this.color}">●</span> <b>${formattedNum}</b> %</span>`;
          }
        },
        tooltip: {
          pointFormatter: function () {
            const formattedNum = this.y?.toLocaleString() || '';
            return `<span style="color:${this.color}">●</span> <b>${formattedNum}</b> MWh`;
          },
        }
      }
    },
    series: [{
      type: 'variablepie',
      zMin: 0,
      data: [
        ...customerSeries,
        {
          name: 'Program Excess',
          color: '#E9ECEF',
          y: totalUnmatchedMWh,
          z: 50,
        }
      ],
    }],
    colors: ["#C3FAE8", "#A5D8FF", "#91A7FF", "#4D7396"],
  };

  return <BaseChart overrideOptions={pieChartOptions}/>
};

export default AllocationResultsOverviewGenMatchChart;