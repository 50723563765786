import { Divider, Group, Loader } from '@mantine/core';
import { IconBolt, IconSitemap, IconUsers } from '@tabler/icons-react';
import { sum } from 'ramda';
import { useParams } from 'react-router-dom';

import { useAllocationRunInputData, useAllocationRunSummaryOutputData } from 'amp/store/allocationRuns/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import { getCurrentUser } from 'shared/store/user/selectors';
import { IAllocationRunInputData, IAllocationSummaryResult } from 'shared/types/allocation';
import { numberToSiFormat } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import AllocationDetails from './allocationDetails';
import EnergyGeneration from './generationChart';
import ProgramDetails from './programDetails';
import ProgramGenerationMatch from './programMatchChart';
import './style.css';


const AllocationSummary = ({ summary, inputs }: { summary: IAllocationSummaryResult['summary_results'], inputs: IAllocationRunInputData }) => {
  const generationByProgramId: Record<string, number> = {}
  Object.values(summary.by_subscription_id).forEach(subDetails => {
    generationByProgramId[subDetails.program_id] = subDetails.program_generation_mwh;
  });

  const generation = numberToSiFormat(summary.total_generation_mwh * 1_000_000);
  const load = numberToSiFormat(summary.total_load_mwh * 1_000_000);
  const programGeneration = numberToSiFormat(sum(Object.values(generationByProgramId)) * 1_000_000);
  const programGeneratorIds = new Set(inputs.generator_assignments.map(gen => gen.id));
  return (
    <BasePaper titleContent="Summary">
      <Group justify="space-evenly">
        <Group>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric">
              <IconBolt color="var(--color-grey-4)" className="allocation-results-summary-pane--icon" />
              {summary.num_generators}
            </div>
            <div className="allocation-results-summary-pane--label">Generators</div>
          </div>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric">{generation.value} <span className="allocation-results-summary-pane--unit">{generation.unitPrefix}Wh</span></div>
            <div className="allocation-results-summary-pane--label">Total Generation</div>
          </div>
        </Group>
        <Divider color="var(--color-grey-3)" orientation="vertical" />
        <Group>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric">
              <IconUsers color="var(--color-blue-1)" className="allocation-results-summary-pane--icon" />
              {summary.num_customers}
            </div>
            <div className="allocation-results-summary-pane--label">Customers</div>
          </div>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric blue">{load.value} <span className="allocation-results-summary-pane--unit">{load.unitPrefix}Wh</span></div>
            <div className="allocation-results-summary-pane--label">Total Consumption</div>
          </div>
        </Group>
        <Divider color="var(--color-grey-3)" orientation="vertical" />
        <Group>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric">
              <IconSitemap color="var(--color-green-2)" className="allocation-results-summary-pane--icon" />
              {summary.num_programs}
            </div>
            <div className="allocation-results-summary-pane--label">Programs</div>
          </div>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric green">{programGeneration.value} <span className="allocation-results-summary-pane--unit">{programGeneration.unitPrefix}Wh</span></div>
            <div className="allocation-results-summary-pane--label">Program Generation</div>
          </div>
          <div className="allocation-results-summary-pane-metric--container">
            <div className="allocation-results-summary-pane--metric green">
            <IconBolt  className="allocation-results-summary-pane--icon" />
              {programGeneratorIds.size}
            </div>
            <div className="allocation-results-summary-pane--label">Program Generators</div>
          </div>
        </Group>
      </Group>
    </BasePaper>
  )
};


const AllocationResultsOverview = () => {
  const { runId = '' } = useParams<{ runId: string }>();
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const oci = useAppSelector(getViewingOpCoId);

  const res = useAllocationRunSummaryOutputData(runId, oci || currentCustomerId);
  const inputsRes = useAllocationRunInputData(runId, oci || currentCustomerId);

  const result = res.data;
  const inputs = inputsRes.data;

  if (!result || res.isLoading || !inputs || inputsRes.isLoading) {
    return <Loader ml="45%" mt="20%" />
  }

  const summary = result.summary_results;

  return <div className="allocation-results-overview--container">
    <AllocationSummary summary={summary} inputs={inputs} />
    <Group mt="24px" mb="24px">
      <EnergyGeneration summary={summary} runId={runId} programs={inputs.programs} />
      <ProgramGenerationMatch summary={summary} programs={inputs.programs} />
    </Group>
    <ProgramDetails summary={summary} inputs={inputs} />
    <AllocationDetails programs={inputs.programs} subscriptionsSummary={summary.by_subscription_id} />
  </div>
};

export default AllocationResultsOverview;