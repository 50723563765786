import { Button, Group, Paper, Stack, Title } from '@mantine/core';
import { useFetchCertPropDefQuery } from 'amp/api/certPropDefs';
import { useParams } from 'react-router-dom';

import { AmpLink } from 'amp/components/Link';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useAppSelector } from 'store';
import './style.css';


export default function SettingsCertificatePropertyDefinitionView() {
  const { certPropDefId='' } = useParams<{certPropDefId: string}>();

  const oci = useAppSelector(getViewingOpCoId);
  const res = useFetchCertPropDefQuery({id: certPropDefId, customerId: oci});
  const certificatePropDef = res.data?.prop_def;

  if (!certificatePropDef) {
    return <div>loading...</div>
  }

  return (
    <Paper shadow="md" p="xl">
      <Stack>
        <div className="view-cert-prop-def--title-container">
          <Title size="h3">{certificatePropDef.display_name}</Title>
          <Button component={AmpLink} to={`/dashboard/settings/certificate-props/${certificatePropDef.id}/edit`} className="view-cert-prop-def--edit-button">
            EDIT
          </Button>
        </div>
        <Group>
          <div>Description:</div>
          <div>{certificatePropDef.description}</div>
        </Group>
        <Group>
          <div>Code Name:</div>
          <div>{certificatePropDef.code_name}</div>
        </Group>
        <Group>
          <div>Property Type:</div>
          <div>{certificatePropDef.property_type}</div>
        </Group>
        <Group>
          <div>ID:</div>
          <div>{certificatePropDef.id}</div>
        </Group>
        <Group>
          <div>Customer ID:</div>
          <div>{certificatePropDef.customer_id}</div>
        </Group>
      </Stack>
    </Paper>
  );
}