import { useSearchParams } from "react-router-dom";

import { useAdminPaginateCustomersQuery } from "admin/api/customers";
import AdminCustomersTable from "admin/components/CustomersTable";
import AdminViewContainer from "admin/components/ViewContainer";
import './style.css';


const CustomersView = () => {
  const [params, setSearchParams] = useSearchParams();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateCustomersQuery({
    page,
    perPage,
  });

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const pagination = data?.meta.pagination;
  const customers = data?.data || [];

  return <AdminViewContainer title="All customers">
    <AdminCustomersTable
      customers={customers}
      isLoading={isLoading || isFetching}
      onPageChange={onPageChange}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total_items || 0}
      currentPage={page}
    />
  </AdminViewContainer>
};

export default CustomersView;