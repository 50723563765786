import { Title } from '@mantine/core';
import { Outlet } from 'react-router-dom';

import Tabs from 'amp/components/Tabs';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useFetchStaticDataCookiesQuery } from 'shared/api/users';
import { useAppSelector } from 'store';
import { AllocationCookies } from '../AllocationRuns';

const ProjectionsOutlet = () => {
  const tabs = [
    { title: 'Overview', path: '/dashboard/projections' },
    { title: 'Console', path: '/dashboard/projections/console', disabled: true },
    { title: 'History', path: '/dashboard/projections/history', disabled: true },
  ];
  const oci = useAppSelector(getViewingOpCoId);
  const cookiesRes = useFetchStaticDataCookiesQuery(oci);

  return (
    <div>
      <div className="allocation-run-outlet-header--container">
        <Title size="24px" mt="16px" mb="16px">Projections</Title>
        <Tabs tabs={tabs} />
      </div>
      <AllocationCookies cfnCookies={cookiesRes.isFetching ? undefined : cookiesRes.data}>
        <Outlet />
      </AllocationCookies>
    </div>
  );
}

export default ProjectionsOutlet;