import { queries } from "shared/api/queries";
import { IDashboardPaginationResponse } from "shared/types/api";
import { IWorkerJob } from "shared/types/jobs";


interface IListJobsArgs {
  page: number
  perPage: number
  status: string
}

const jobPage: {type: 'WorkerJob', id: string} = {type: 'WorkerJob', id: 'PAGE'};

const adminWorkerJobsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateWorkerJobs: build.query<IDashboardPaginationResponse<IWorkerJob>, IListJobsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        qs.set('status', body.status);

        return {
          url: `/admin/worker_jobs/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [{type: 'WorkerJob', id: 'PAGE'}, ...res.data.map(({id}) => ({type: 'WorkerJob' as const, id}))] : [{type: 'WorkerJob', id: 'PAGE'}],
    }),

    adminFetchWorkerJob: build.query<{data: IWorkerJob}, string>({
      query: (id) => `/admin/worker_jobs/${id}`,
    }),

    adminPauseWorkerJob: build.mutation<{data: IWorkerJob}, string>({
      query: (id: string) => {
        return {
          url: `/admin/worker_jobs/${id}/pause`,
          method: 'POST',
        };
      },

      invalidatesTags: res => res ? [{type: 'WorkerJob', id: 'PAGE'}, {type: 'WorkerJob', id: res.data.id}] : [{type: 'WorkerJob', id: 'PAGE'}],
    }),

    adminRestartWorkerJob: build.mutation<{data: IWorkerJob}, string>({
      query: (id: string) => {
        return {
          url: `/admin/worker_jobs/${id}/restart`,
          method: 'POST',
        };
      },

      invalidatesTags: res => res ? [jobPage, {type: 'WorkerJob', id: res.data.id}] : [jobPage],
    }),

    adminCreateWorkerJob: build.mutation<{status: "ok", request_id: string}, {job_name: string, job_status: Record<string, unknown>}>({
      query: (body) => ({
        url: `/admin/worker_jobs/`,
        method: 'POST',
        body,
      }),

      invalidatesTags: res => res ? [jobPage]: [],
    }),

  }),
  overrideExisting: false,
});


export const {
  useAdminFetchWorkerJobQuery,
  useAdminPaginateWorkerJobsQuery,
  useAdminPauseWorkerJobMutation,
  useAdminRestartWorkerJobMutation,
  useAdminCreateWorkerJobMutation,
} = adminWorkerJobsQueries;