import axios, { AxiosError } from 'axios';

import { LOGIN_URL } from 'shared/constants/resources';


export const bootToLogin = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: 'amp',
  });

  window.location.href = `${LOGIN_URL}/?${params}`;
}

export const bootToLogout = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: 'amp',
  });

  window.location.href = `${LOGIN_URL}/logout?${params}`;
}

export const API = axios.create({
  timeout: 30000, // 30 second timeout
  headers: {
    'X-App-Version': `${process.env.REACT_APP_ENV || 'no-version'}`,
    'Accept': 'application/json',
  },
  withCredentials: true,
});


API.interceptors.response.use((x) => x,
  (err: Error | AxiosError) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      bootToLogin();
    } else {
      return err;
    }
  },
);
