import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setUser } from '@sentry/react'

import { fetchBootstrap } from 'shared/api/users'
import { receiveCustomers } from 'shared/store/customers/slice'
import { ICustomer, IParsedCustomerProperty } from 'shared/types/customer'
import { IFetchBootstrapResponse, IUser, IUserAttribute, UserRole } from 'shared/types/user'
import type { AppDispatch, RootState } from 'store'


type BootstrapStatus = 'FAILED' | 'IN_FLIGHT' | 'NOT_STARTED' | 'SUCCESS'

interface UserState {
  byId: Record<string, IUser>,
  bootstrap: {
    status: BootstrapStatus,
    userId: string | null,
    customer: ICustomer | null,
    wsToken: string | null,
    opcos: ICustomer[],
  },
  // for debugging purposes for Singularity admins
  sadminRoleOverride: null | UserRole,
}

const initialState: UserState = {
  byId: {},
  bootstrap: {
    status: 'NOT_STARTED',
    userId: null,
    customer: null,
    wsToken: null,
    opcos: [],
  },
  sadminRoleOverride: null,
}


export const kickoffBootstrap = createAsyncThunk<{data?: IFetchBootstrapResponse, status: BootstrapStatus} | undefined, undefined, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'user/kickoffBootstrap',
  async (_, {dispatch, getState}) => {

    if (getState().user.bootstrap.status === 'IN_FLIGHT') {
      // short circuit
      return undefined;
    }
    dispatch(userSlice.actions.updateBootstrapState('IN_FLIGHT'));

    try {
      const {data} = await fetchBootstrap();
      setUser({
        id: data.user.id,
        email: data.user.email,
        username: data.user.name,
      });
      dispatch(receiveCustomers([...data.opcos, data.customer]));
      // tracker.identify(data.user);
      return {data, status: 'SUCCESS'};
    } catch (err) {
      return {status: 'FAILED'};
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receiveUsers: (state, action: PayloadAction<IUser[]>) => {
      action.payload.forEach(user => {
        state.byId[user.id] = user;
      })
    },

    updateBootstrapState: (state, action: PayloadAction<BootstrapStatus>) => {
      state.bootstrap.status = action.payload;
    },

    receiveMyCustomerProperties: (state, action: PayloadAction<{properties: IParsedCustomerProperty[]}>) => {
      if (state.bootstrap.customer) {
        state.bootstrap.customer.attributes = action.payload.properties;
      }
    },

    receiveMyUserProperties: (state, action: PayloadAction<{properties: IUserAttribute[]}>) => {
      if (state.bootstrap.userId) {
        state.byId[state.bootstrap.userId].attributes = action.payload.properties;
      }
    },

    setRoleOverride: (state, action: PayloadAction<UserRole | null>) => {
      state.sadminRoleOverride = action.payload;
    },
  },


  extraReducers: (builder) => {
    builder.addCase(kickoffBootstrap.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      state.bootstrap.status = action.payload.status;


      if (action.payload.data) {
        const user = action.payload.data.user;
        state.bootstrap.userId = user.id;
        state.byId[user.id] = user;
        state.bootstrap.wsToken = action.payload.data.ws_token || null;
        state.bootstrap.opcos = action.payload.data.opcos;
        state.bootstrap.customer = action.payload.data.customer;
      }
    });
  }
})

export const { receiveUsers, receiveMyCustomerProperties, setRoleOverride, receiveMyUserProperties } = userSlice.actions

export const receiveUser = ({user}: {user: IUser}) => receiveUsers([user]);

export default userSlice.reducer