import { ReactNode } from "react";

import './style.css';


const AdminTitle = ({children}: {children?: ReactNode}) => {
  return <div className="admin-app--title">
    {children}
  </div>
}

export default AdminTitle;