import { IAggregatedAssetEvent } from 'shared/types/aggregatedEvents';
import { useAppDispatch, useAppSelector } from 'store';
import { getProjectionsConsumptionError, getProjectionsConsumptionLoading, getProjectionsConsumptionResponse, shouldFetchProjectionsConsumption } from './selectors';
import { IBulkFetchCustomerAggregatedEventsArgs, bulkFetchProjectionsCustomerAggregatedEvents } from './slice';

export const useProjectionsBulkCustomerEvents = ({ startDate, endDate, resolution, customerIds }: IBulkFetchCustomerAggregatedEventsArgs) => {
  const dispatch = useAppDispatch();
  const sortedIds = customerIds.sort();

  const serializedArgs = JSON.stringify({ startDate, endDate, resolution, customerIds: sortedIds });

  const shouldFetch = useAppSelector(s => shouldFetchProjectionsConsumption(s, serializedArgs));

  if (shouldFetch) {
    dispatch(bulkFetchProjectionsCustomerAggregatedEvents({ startDate, endDate, resolution, customerIds }));
  }
  const isLoading = useAppSelector(s => getProjectionsConsumptionLoading(s));
  const isError = useAppSelector(s => getProjectionsConsumptionError(s));
  const data = useAppSelector(s => getProjectionsConsumptionResponse(s));

  return {
    isLoading,
    isError,
    data: data.data as null | Record<string, IAggregatedAssetEvent[]>,
  }
}