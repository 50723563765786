const MapIconLarge = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9811 3.99392C13.3634 3.99392 14.7146 4.4038 15.8639 5.17173C17.0132 5.93966 17.909 7.03115 18.4379 8.30818C18.9669 9.58521 19.1053 10.9904 18.8357 12.3461C18.5661 13.7018 17.9005 14.947 16.9232 15.9244C16.9232 15.9244 16.9232 15.9245 16.9232 15.9244L12.6862 20.1604C12.499 20.3474 12.2452 20.4525 11.9806 20.4525C11.716 20.4525 11.4622 20.3474 11.275 20.1604L7.03911 15.9245C7.03911 15.9245 7.0391 15.9245 7.03911 15.9245C6.06174 14.9471 5.39614 13.7018 5.12651 12.3461C4.85687 10.9904 4.99529 9.5852 5.52427 8.30818C6.05326 7.03115 6.94904 5.93966 8.09834 5.17173C9.24765 4.4038 10.5989 3.99392 11.9811 3.99392ZM16.9732 3.51143C15.4956 2.52409 13.7583 1.9971 11.9811 1.9971C10.2039 1.9971 8.46666 2.52409 6.98898 3.51143C5.5113 4.49877 4.35958 5.90211 3.67947 7.544C2.99935 9.1859 2.82137 10.9926 3.16805 12.7356C3.51473 14.4787 4.37048 16.0798 5.62711 17.3365L9.86338 21.5727C10.425 22.1338 11.1867 22.4493 11.9806 22.4493C12.7745 22.4493 13.5359 22.1341 14.0975 21.5731L18.335 17.3366C19.5916 16.0799 20.4475 14.4787 20.7942 12.7356C21.1408 10.9926 20.9629 9.1859 20.2828 7.544C19.6026 5.90211 18.4509 4.49877 16.9732 3.51143ZM11.9811 8.98568C13.0839 8.98568 13.9779 9.87968 13.9779 10.9825C13.9779 12.0853 13.0839 12.9793 11.9811 12.9793C10.8783 12.9793 9.98429 12.0853 9.98429 10.9825C9.98429 9.87968 10.8783 8.98568 11.9811 8.98568ZM15.9748 10.9825C15.9748 8.77687 14.1867 6.98886 11.9811 6.98886C9.77549 6.98886 7.98748 8.77687 7.98748 10.9825C7.98748 13.1881 9.77549 14.9761 11.9811 14.9761C14.1867 14.9761 15.9748 13.1881 15.9748 10.9825Z" fill="#54637A"/>
  </svg>
);


export const getAsDOMElement = (clickable?: boolean) => {
  const container = document.createElement('div');
  if (clickable) {
    container.style.cursor = 'pointer';
  }
  container.innerHTML = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9811 3.99392C13.3634 3.99392 14.7146 4.4038 15.8639 5.17173C17.0132 5.93966 17.909 7.03115 18.4379 8.30818C18.9669 9.58521 19.1053 10.9904 18.8357 12.3461C18.5661 13.7018 17.9005 14.947 16.9232 15.9244C16.9232 15.9244 16.9232 15.9245 16.9232 15.9244L12.6862 20.1604C12.499 20.3474 12.2452 20.4525 11.9806 20.4525C11.716 20.4525 11.4622 20.3474 11.275 20.1604L7.03911 15.9245C7.03911 15.9245 7.0391 15.9245 7.03911 15.9245C6.06174 14.9471 5.39614 13.7018 5.12651 12.3461C4.85687 10.9904 4.99529 9.5852 5.52427 8.30818C6.05326 7.03115 6.94904 5.93966 8.09834 5.17173C9.24765 4.4038 10.5989 3.99392 11.9811 3.99392ZM16.9732 3.51143C15.4956 2.52409 13.7583 1.9971 11.9811 1.9971C10.2039 1.9971 8.46666 2.52409 6.98898 3.51143C5.5113 4.49877 4.35958 5.90211 3.67947 7.544C2.99935 9.1859 2.82137 10.9926 3.16805 12.7356C3.51473 14.4787 4.37048 16.0798 5.62711 17.3365L9.86338 21.5727C10.425 22.1338 11.1867 22.4493 11.9806 22.4493C12.7745 22.4493 13.5359 22.1341 14.0975 21.5731L18.335 17.3366C19.5916 16.0799 20.4475 14.4787 20.7942 12.7356C21.1408 10.9926 20.9629 9.1859 20.2828 7.544C19.6026 5.90211 18.4509 4.49877 16.9732 3.51143ZM11.9811 8.98568C13.0839 8.98568 13.9779 9.87968 13.9779 10.9825C13.9779 12.0853 13.0839 12.9793 11.9811 12.9793C10.8783 12.9793 9.98429 12.0853 9.98429 10.9825C9.98429 9.87968 10.8783 8.98568 11.9811 8.98568ZM15.9748 10.9825C15.9748 8.77687 14.1867 6.98886 11.9811 6.98886C9.77549 6.98886 7.98748 8.77687 7.98748 10.9825C7.98748 13.1881 9.77549 14.9761 11.9811 14.9761C14.1867 14.9761 15.9748 13.1881 15.9748 10.9825Z" fill="#54637A"/>
    </svg>
  `;
  return container;
}

export default MapIconLarge;