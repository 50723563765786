import { Button, Group, InputLabel, Modal } from "@mantine/core";
import { useAdminChangeCustomerMutation } from "admin/api/users";
import { FormEvent, useState } from "react";
import { getCurrentUser } from "shared/store/user/selectors";
import { ICustomer } from "shared/types/customer";
import { useAppSelector } from "store";


const SwitchCustomerModal = ({
  onClose,
  isOpen,
  customer,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customer: ICustomer}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [change] = useAdminChangeCustomerMutation();
  const user = useAppSelector(getCurrentUser);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customer) {
      return
    }
    try {
      await change({customerId: customer.id}).unwrap();
      onClose(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Change your customer ID"
    >
      <form onSubmit={onSubmit}>
        <InputLabel>Confirm customer change</InputLabel>
        <p>Confirm that you want to change your customer ID from {user.customer_id} to {customer.id}</p>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default SwitchCustomerModal;