import { isDefined } from 'shared/utils/data';
import { IDashboardPagination } from './api';


// TODO this is more generic on the backend than just for CertificatePropertyDefinitions
//   this structure will likely be used elsewhere on the frontend
export enum CertificatePropertyType {
  DATE = 'date',
  DATETIME = 'datetime',
  JSON = 'json',
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

// TODO: make a class that implements dataToDisplayValues?
export interface ICertificatePropertyDefinition {
  id: string
  customer_id: string
  display_name: string
  code_name: string
  description: string
  property_type: CertificatePropertyType
}

export interface ICreateCertificatePropertyDefinitionBody {
  display_name: string
  description: string
  property_type: CertificatePropertyType
}


export interface IListCertificatePropertyDefinitionsResponse {
  data: ICertificatePropertyDefinition[];
  meta: {
    pagination: IDashboardPagination
  }
}


export interface IFetchCertificatePropertyDefinitionResponse {
  prop_def: ICertificatePropertyDefinition;
}


export const dataToDisplayValues = (certificatePropertyDefinition?: ICertificatePropertyDefinition) => {
  return {
    id: certificatePropertyDefinition?.id || '',
    customer_id: certificatePropertyDefinition?.customer_id || '',
    display_name: certificatePropertyDefinition?.display_name || '',
    code_name: certificatePropertyDefinition?.code_name || '',
    description: certificatePropertyDefinition?.description || '',
    property_type: certificatePropertyDefinition?.property_type || CertificatePropertyType.STRING,
  };
};


export const displayValuesToRequestData = (values: Partial<ReturnType<typeof dataToDisplayValues>>) => {
  // TODO: stricter typing?
  const data: Record<string, unknown> = {};

  if (isDefined(values.id)) {
    data.id = values.id;
  }

  if (isDefined(values.customer_id)) {
    data.customer_id = values.customer_id;
  }

  if (isDefined(values.display_name)) {
    data.display_name = values.display_name;
  }

  if (isDefined(values.description)) {
    data.description = values.description;
  }

  if (isDefined(values.code_name)) {
    data.code_name = values.code_name;
  }

  if (isDefined(values.property_type)) {
    data.property_type = values.property_type;
  }

  return data;
};


export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
    id: 'id',
    customer_id: 'customer_id',
    display_name: 'display_name',
    code_name: 'code_name',
    description: 'description',
    property_type: 'property_type',
  };

  Object.entries(errs).forEach(([field, errors]) => {
    if (errors.length && fieldMap[field]) {
      displayErrors[fieldMap[field]] = errors[0];
    }
  });

  return displayErrors;
};