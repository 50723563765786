import { Divider, Group, Loader, Stack, Text, Title } from '@mantine/core';

import BaseChart from 'shared/components/Chart/baseChart';

const CfeComparisonBarChart = ({ programCfe, srpCfe }: { programCfe: number | null, srpCfe: number | null }) => {
  if (programCfe === null || srpCfe === null) {
    return <Loader />
  }

  const barPercentChartOptionsSRP: Highcharts.Options = {
    chart: {
      type: 'bar',
      backgroundColor: '#e5eceb',
      height: '60',
      margin: 0,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 100,
      borderWidth: 0,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y:,.1f}%',
          style: {
            fontSize: '15',
            color: '#4d7396',
          },
        },
        borderRadius: 50,
      }
    },
    series: [{
      type: 'bar',
      color: '#719695',
      groupPadding: 0,
      pointPadding: 0,
      data: [srpCfe * 100],
    }],
  };

  const barPercentChartOptionsCFE: Highcharts.Options = {
    chart: {
      type: 'bar',
      backgroundColor: '#ecf8f5',
      height: '60',
      margin: 0,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 100,
      borderWidth: 0,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y:,.1f}%',
          style: {
            fontSize: '15',
            color: '#4d7396',
          },
        },
        borderRadius: 50,
      }
    },
    series: [{
      type: 'bar',
      color: '#96d4c6',
      groupPadding: 0,
      pointPadding: 0,
      data: [programCfe * 100],
    }],
  };

  return (
    <Stack>
      <Group>
        <Divider orientation="vertical" color="#62c1ab" size="xl" />
        <Stack maw={250}>
          <Text lineClamp={2} c="#2a384d">CFE Matched if subscribed to programs</Text>
          <Title c="#2a384e">{(programCfe * 100).toFixed(1)}%</Title>
        </Stack>
        <Group w="400">
          <BaseChart overrideOptions={barPercentChartOptionsCFE} />
        </Group>
      </Group>
      <Group>
        <Divider orientation="vertical" color="#245d5b" size="xl" />
        <Stack maw={250}>
          <Text lineClamp={2} c="#2a384d">CFE Matched as a standard customer</Text>
          <Title c="#919296">{(srpCfe * 100).toFixed(1)}%</Title>
        </Stack>
        <Group w="400">
          <BaseChart overrideOptions={barPercentChartOptionsSRP} />
        </Group>
      </Group>
    </Stack>
  );
};

export default CfeComparisonBarChart;