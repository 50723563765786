import Highcharts from 'highcharts';
import { groupBy, mean, sum } from 'ramda';


export const makeChartOptions = ({series, title, yAxis}: Partial<Highcharts.Options>) => ({
  title: title || {
    text: 'TODO: fill in chart title',
    align: 'center',
  },
  chart: {
    type: 'column',
    zooming: {
      type: 'x',
    },
  },
  yAxis: yAxis || {
    title: {
      text: 'TODO: fill in yAxis title',
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      style: {
        fontSize: '14px',
      },
      // TODO date range picker and dynamic formatting
      format: '{value:%Y-%b-%e}',
    },
  },
  credits: { enabled: false },
  legend: { enabled: false },
  series,
});

type Grouping = 'hour' | 'day' | 'week' | 'month';

const groupingToChangeDate: Record<Grouping, (epoch: number) => string> = {
  hour: (d) => {
    const date = new Date(d);
    date.setUTCMinutes(0, 0, 0);
    return date.toISOString();
  },
  day: (d) => {
    const date = new Date(d);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  },
  week: (d) => {
    const date = new Date(d);
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(date.getDate() - date.getDay());
    return date.toISOString();
  },
  month: (d) => {
    const date = new Date(d);
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(1);
    return date.toISOString();
  }
};

export const groupData = (data: {epoch: number, value: number}[], by: Grouping, method: 'sum' | 'mean' = 'sum') => {
  const changeDateFn = groupingToChangeDate[by];
  const grouped = groupBy((datum) => changeDateFn(datum.epoch), data);

  const groupingFunc = method === 'sum' ? sum : mean;

  return Object.entries(grouped).map(([period, values]) => ([
    new Date(period).valueOf(),
    groupingFunc(values?.map(d => d.value) || []),
  ])).sort(([x1], [x2]) => x1 - x2);
};
