import { Accordion, AppShell, Avatar, Box, Group, Menu, Stack, Text, rem } from '@mantine/core';
import { IconBolt, IconChartLine, IconCheckupList, IconChevronRight, IconRefresh, IconRipple, IconSitemap, IconUsers } from '@tabler/icons-react';
import { useMemo } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

import { usePrefetch as useGenPrefetch } from 'amp/api/generators';
import { usePrefetch as useProgPrefetch } from 'amp/api/programs';
import { getLatestLoadAndGenDataDates, getViewingOpCoId } from 'amp/store/ui/selectors';
import { setViewingOpco } from 'amp/store/ui/slice';
import AppPicker from 'shared/components/AppPicker';
import CustomerTypeRequired from 'shared/components/CustomerTypeRequired/customerTypeRequired';
import FeatureGate from 'shared/components/FeatureGate/featureGate';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { getCurrentCustomer, getCurrentUser, getOpcos } from 'shared/store/user/selectors';
import { CustomerType, getCustomerLogoPath, getCustomerOpcoLogoPath, isUtilityCustomer, isUtilityParentCustomer } from 'shared/types/customer';
import { UserRole } from 'shared/types/user';
import { timestampToNumericDate } from 'shared/utils/dates';
import { useAppDispatch, useAppSelector } from 'store';
import './style.css';

interface NavbarLinkProps {
  icon: typeof IconUsers
  label: string
  href: string
  roleRequired: UserRole
  customerTypesRequired: CustomerType[]
  featureGate?: string
  end?: boolean
  onMouseEnter?: () => void
}

const NavbarLink = ({ icon: Icon, label, href, onMouseEnter, roleRequired, customerTypesRequired, featureGate }: NavbarLinkProps) => {
  if (featureGate) {
    return (
      <FeatureGate propertyName={featureGate} key={label}>
        <CustomerTypeRequired allowedTypes={customerTypesRequired}>
          <RoleRequired role={roleRequired}>
            <NavLink to={href} className="main-navigation--navigation-link" onMouseEnter={onMouseEnter}>
              <Group justify="flex-start" align="center" w="100%" p={4}>
                <Icon color="white" style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                <Text c="white" fz={12}>{label}</Text>
              </Group>
            </NavLink>
          </RoleRequired>
        </CustomerTypeRequired>
      </FeatureGate>
    );
  } else {
    return (
      <CustomerTypeRequired allowedTypes={customerTypesRequired}>
        <RoleRequired role={roleRequired}>
          <NavLink to={href} className="main-navigation--navigation-link" onMouseEnter={onMouseEnter}>
            <Group justify="flex-start" align="center" w="100%" p={4}>
              <Icon color="white" style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
              <Text c="white" fz={12}>{label}</Text>
            </Group>
          </NavLink>
        </RoleRequired>
      </CustomerTypeRequired>
    );
  }
};


const Navigation = () => {
  const [, setParams] = useSearchParams();
  const generatorsPrefetch = useGenPrefetch('paginateGenerators');
  const programsPrefetch = useProgPrefetch('paginatePrograms');
  const user = useAppSelector(getCurrentUser);
  const customer = useAppSelector(getCurrentCustomer);
  const opcos = useAppSelector(getOpcos);
  const selectedOpcoId = useAppSelector(getViewingOpCoId);
  const dispatch = useAppDispatch();

  const selectedOpco = opcos.find(c => c.id === selectedOpcoId);
  const { latestGen, latestLoad } = useAppSelector(getLatestLoadAndGenDataDates);

  const linkData = useMemo(() => ({
    inventory: [
      {
        label: 'Generation',
        icon: IconBolt,
        href: `/dashboard/inventory?oci=${selectedOpcoId}`,
        roleRequired: UserRole.USER,
        customerTypesRequired: [CustomerType.UTILITY, CustomerType.UTILITY_OPCO],
      },
      {
        label: 'Load',
        icon: IconUsers,
        href: `/dashboard/customers?oci=${selectedOpcoId}`,
        roleRequired: UserRole.USER,
        // TODO: can utility customers see their own load?
        customerTypesRequired: [CustomerType.UTILITY, CustomerType.UTILITY_OPCO],
      },
      {
        label: 'Projections',
        icon: IconChartLine,
        href: `/dashboard/projections?oci=${selectedOpcoId}`,
        featureGate: 'amp_projections_page_demo',
        roleRequired: UserRole.USER,
        customerTypesRequired: [CustomerType.UTILITY, CustomerType.UTILITY_OPCO],
      },
    ],
    management: [
      {
        label: 'Programs',
        icon: IconSitemap,
        href: `/dashboard/programs?oci=${selectedOpcoId}`,
        roleRequired: UserRole.USER,
        customerTypesRequired: [CustomerType.UTILITY, CustomerType.UTILITY_OPCO],
      },
      {
        label: 'Allocation',
        icon: IconCheckupList,
        href: `/dashboard/allocation?oci=${selectedOpcoId}`,
        roleRequired: UserRole.USER,
        customerTypesRequired: [CustomerType.UTILITY, CustomerType.UTILITY_OPCO],
      },
      // {
      //   label: 'Attestation',
      //   icon: IconCertificate,
      //   href: 'TODO',
      //   roleRequired: UserRole.USER, // TODO: only admins?
      //   customerTypesRequired: [CustomerType.UTILITY, CustomerType.UTILITY_OPCO],
      // },
    ],
    outputs: [
      {
        label: 'Customer Reports',
        icon: IconCheckupList,
        href: `/dashboard/reports?oci=${selectedOpcoId}`,
        roleRequired: UserRole.REPORTER,
        customerTypesRequired: [
          CustomerType.UTILITY,
          CustomerType.UTILITY_OPCO,
          CustomerType.UTILITY_PARENT_CUSTOMER,
          CustomerType.UTILITY_CUSTOMER
        ],
      },
      {
        label: 'Emissions Reports',
        icon: IconRipple,
        href: `/dashboard/emissions?oci=${selectedOpcoId}`,
        roleRequired: UserRole.USER,
        customerTypesRequired: [
          CustomerType.UTILITY,
          CustomerType.UTILITY_OPCO,
        ]
      },
      // {
      //   label: 'Certificates',
      //   icon: IconRosette,
      //   href: 'TODO',
      //   roleRequired: UserRole.USER,
      // },
      // {
      //   label: 'Billing',
      //   icon: IconReceipt,
      //   href: 'TODO',
      //   roleRequired: UserRole.USER,
      // },
    ]
  }), [selectedOpcoId]);

  const labelToPrefetch: Record<string, () => void> = useMemo(() => ({
    Programs: programsPrefetch.bind(undefined, { page: 1, perPage: 20, customerId: selectedOpcoId }),
    Inventory: generatorsPrefetch.bind(undefined, { page: 1, perPage: 10, fuelCategory: null, usState: null, customerId: selectedOpcoId }),
  }), [selectedOpcoId, generatorsPrefetch, programsPrefetch]);

  const onSelectOpco = (newOpCo: string | null) => {
    setParams(newParams => {
      if (!newOpCo) {
        newParams.delete('oci');
      } else {
        newParams.set('oci', newOpCo);
      }
      return newParams;
    });
    dispatch(setViewingOpco(newOpCo || null));
  };

  const getLogoPath = () => {
    // utility customers have a property for both their own logo as well as the opco logo. use opco here.
    if (customer && (isUtilityCustomer(customer) || isUtilityParentCustomer(customer))) {
      return getCustomerOpcoLogoPath(customer);
    } else {
      const currentCustomerLogoPath = customer && getCustomerLogoPath(customer);
      return selectedOpco ? getCustomerLogoPath(selectedOpco) : currentCustomerLogoPath;
    }
  }

  const latestGenDate = latestGen ? timestampToNumericDate(latestGen) : 'unknown';
  const latestLoadDate = latestLoad ? timestampToNumericDate(latestLoad) : 'unknown';
  const logoPath = getLogoPath();
  return (
    <AppShell.Navbar>
      <nav className="main-navigation--container">
        {/* TODO: logic for navbar collapse, need designs */}
        <Group justify="space-between" p="20px 16px" w="100%" wrap="nowrap" align="center">
          <CustomerTypeRequired allowedTypes={[CustomerType.UTILITY, CustomerType.UTILITY_OPCO]}>
            <img src="/images/singularity.svg" width={61} height={16} alt="The Singularity Energy logo" />
          </CustomerTypeRequired>
          <AppPicker />
        </Group>

        {(selectedOpco || customer) && <img className="main-navigation--user-logo" src={logoPath || '/images/acme.svg'} alt="Your company logo" />}

        {opcos.length > 0 &&
          <Menu position="bottom">
            <Menu.Target>
              <Box className="main-navigation--change-opco-button" fz={14}>
                Change
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => onSelectOpco(null)}>
                Full Inventory
              </Menu.Item>
              {opcos.map(opco => <Menu.Item onClick={() => onSelectOpco(opco.id)} key={opco.id}>
                {opco.name}
              </Menu.Item>)}
            </Menu.Dropdown>
          </Menu>
        }

        <div className="main-navigation--navigation-links">
          <Accordion multiple defaultValue={['inventory', 'management', 'outputs']}>
            {/* Each top level header in the accordion should respect the permissions of its tabs */}
            <RoleRequired role={UserRole.USER}>
              <CustomerTypeRequired allowedTypes={[CustomerType.UTILITY, CustomerType.UTILITY_OPCO]}>
                <Accordion.Item className="main-navigation--accordion-item" key="inventory" value="inventory">
                  <Accordion.Control className="main-navigation--accordion-header">INVENTORY</Accordion.Control>
                  <Accordion.Panel className="main-navigation--accordion-panel">
                    <Stack gap={4}>
                      {linkData.inventory.map(navLink => (
                        <NavbarLink
                          {...navLink}
                          key={navLink.label}
                          onMouseEnter={() => labelToPrefetch[navLink.label] && labelToPrefetch[navLink.label]()}
                        />
                      ))}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              </CustomerTypeRequired>
            </RoleRequired>
            <RoleRequired role={UserRole.USER}>
              <CustomerTypeRequired allowedTypes={[CustomerType.UTILITY, CustomerType.UTILITY_OPCO]}>
                <Accordion.Item className="main-navigation--accordion-item" key="management" value="management">
                  <Accordion.Control className="main-navigation--accordion-header">MANAGEMENT</Accordion.Control>
                  <Accordion.Panel className="main-navigation--accordion-panel">
                    <Stack gap={4}>
                      {linkData.management.map(navLink => (
                        <NavbarLink
                          {...navLink}
                          key={navLink.label}
                          onMouseEnter={() => labelToPrefetch[navLink.label] && labelToPrefetch[navLink.label]()}
                        />
                      ))}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              </CustomerTypeRequired>
            </RoleRequired>
            <RoleRequired role={UserRole.REPORTER}>
              {/* No customer type check here, always visible to all customer types */}
              <Accordion.Item className="main-navigation--accordion-item" key="outputs" value="outputs">
                <Accordion.Control className="main-navigation--accordion-header">OUTPUTS</Accordion.Control>
                <Accordion.Panel className="main-navigation--accordion-panel">
                  <Stack gap={4}>
                    {linkData.outputs.map(navLink => (
                      <NavbarLink
                        {...navLink}
                        key={navLink.label}
                        onMouseEnter={() => labelToPrefetch[navLink.label] && labelToPrefetch[navLink.label]()}
                      />
                    ))}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            </RoleRequired>
          </Accordion>
        </div>

        <CustomerTypeRequired allowedTypes={[CustomerType.UTILITY_OPCO, CustomerType.UTILITY]}>
          <div className="main-navigation--data-updated-dates">
            <Group mb={12} gap={4}>
              <IconRefresh size={12} color="var(--color-green-0)" />
              <Text fz={10} fw={600} c="var(--color-green-0)">DATA UPDATES</Text>
            </Group>
            <Stack gap={8}>
              <Group justify="space-between" align="flex-start">
                <Text fz={10} fw={600} c="var(--color-green-0)">Consumption</Text>
                <Stack gap={0}>
                  <Text fz={10} fw={600} c="var(--color-green-0)">{latestLoadDate}</Text>
                </Stack>
              </Group>
              <Group justify="space-between" align="flex-start">
                <Text fz={10} fw={600} c="var(--color-green-0)">Generation</Text>
                <Stack gap={0}>
                  <Text fz={10} fw={600} c="var(--color-green-0)">{latestGenDate}</Text>
                </Stack>
              </Group>
            </Stack>
          </div>
        </CustomerTypeRequired>

        <NavLink to={`/dashboard/settings/preferences?oci=${selectedOpcoId}`} className="main-navigation--account-container">
          {({ isActive }) => <>
            <Group justify="flex-start" align="center">
              <Avatar color="white" bg="var(--color-green-2)" size={28}>{user?.name.slice(0, 1).toLocaleUpperCase()}</Avatar>
              <Text c="white">Account</Text>
            </Group>
            {isActive && <IconChevronRight color="white" />}
          </>
          }
        </NavLink>
      </nav>
    </AppShell.Navbar>
  );
};

export default Navigation;
