import { Button, Title } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { Outlet, useParams } from 'react-router-dom';

import { AmpLink } from 'amp/components/Link';
import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import Tabs from 'amp/components/Tabs';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { useCustomer } from 'shared/store/customers/hooks';
import { isUtilityCustomer, isUtilityParentCustomer } from 'shared/types/customer';
import { UserRole } from 'shared/types/user';
import './style.css';


export default function CustomerOutlet() {
  const { customerId = '', accountId = '' } = useParams<{ customerId: string, accountId: string }>();
  const res = useCustomer(accountId || customerId);

  const tabs = [
    { title: 'Overview', path: `/dashboard/customers/${customerId}` },
    { title: 'Sub-accounts', path: `/dashboard/customers/${customerId}/accounts` },
  ];

  return (
    <div className="customer-outlet--container">
      <div className="customer-outlet--pinned-area">
        <PageBreadcrumbs />
        <div className="customer-outlet--title-container">
          <Title size="24px">{res.data?.name}</Title>
          <RoleRequired role={UserRole.ADMIN}>
            <AmpLink to={`/dashboard/customers/${accountId || customerId}/edit`}>
              <Button fw={400} size="sm" className="customer-outlet--button" leftSection={<IconPencil size={20} />}>
                Edit Customer
              </Button>
            </AmpLink>
          </RoleRequired>
        </div>
        {(isUtilityCustomer(res.data) || isUtilityParentCustomer(res.data)) && <Tabs tabs={tabs} />}
      </div>
      <Outlet />
    </div>
  );
}