import { userAuthQueries } from "shared/api/queries";
import { kickoffBootstrap } from "shared/store/user/slice";
import { IUserAuthPaginationResponse } from "shared/types/api";
import { IUser, UserRole, UserStatus } from "shared/types/user";


interface IListUsersArgs {
  page: number
  perPage: number
  customerId?: string
}


interface ICreateUserArgs {
  name: string
  email: string
  role: UserRole
  status: UserStatus
  customer_id: string
  password?: string
}

interface IUpsertUserPropertyArgs {
  user_id: string
  property_name: string
  property_type: string
  property_value: string
  property_namespace: string
}


const adminUsersQueries = userAuthQueries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateUsers: build.query<IUserAuthPaginationResponse<IUser>, IListUsersArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        const {
          page,
          perPage,
          customerId,
        } = body;
        const limit = perPage;
        const offset = (page - 1) * perPage;
        qs.set('offset', offset.toString());
        qs.set('limit', limit.toString());

        if (customerId) {
          qs.set('customer_id', customerId);
        }

        return {
          url: `/admin/users/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'User', id: 'PAGE'}],
    }),

    adminFetchUser: build.query<{user: IUser}, string>({
      query: (id) => `/admin/users/${id}`,

      providesTags: res => res ? [{type: 'User', id: 'PAGE'}, {type: 'User', id: res.user.id}] : [],
    }),

    adminFetchUserByEmail: build.query<{user: IUser}, string>({
      query: (email) => `/admin/users/by-email/${email}`,

      providesTags: res => res ? [{type: 'User', id: 'PAGE'}, {type: 'User', id: res.user.id}] : [],
    }),

    adminCreateUser: build.mutation<{user: IUser}, ICreateUserArgs>({
      query: (body) => ({
        url: '/admin/users/',
        method: 'POST',
        body,
      }),

      invalidatesTags: [{type: 'User', id: 'PAGE'}],
    }),

    adminChangeCustomer: build.mutation<{user: IUser}, {customerId: string}>({
      query: ({customerId}) => ({
        url: `/admin/users/me/change-customer?customer_id=${customerId}`,
        method: 'POST',
      }),

      async onQueryStarted(_arg, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
          dispatch(kickoffBootstrap());
        } catch (err) {
          console.warn("Failed to update admin customer ID")
          console.warn(err);
        }
      },
      invalidatesTags: [{type: 'User', id: 'PAGE'}],
    }),

    adminPatchUser: build.mutation<{user: IUser}, {userId: string, role?: UserRole, status?: UserStatus, customer_id?: string}>({
      query: ({userId, role, status, customer_id}) => ({
        url: `/admin/users/${userId}`,
        method: 'PATCH',
        body: {role, status, customer_id}
      }),

      invalidatesTags: res => res ? [{type: 'User', id: 'PAGE'}, {type: 'User', id: res.user.id}] : [],
    }),

    adminSendAmpWelcomeEmail: build.mutation<{status: 'OK'}, {userId: string, invited_by: string}>({
      query: ({userId, ...body}) => ({
        url: `/admin/users/${userId}/send-amp-welcome-email`,
        method: 'POST',
        body,
      }),
    }),

    adminUpsertUserProperty: build.mutation<{user: IUser}, IUpsertUserPropertyArgs>({
      query: (body) => ({
        url: '/admin/users/properties',
        method: 'POST',
        body,
      }),

      invalidatesTags: res => res ? [{type: 'User', id: 'PAGE'}, {type: 'User', id: res.user.id}] : [],
    })
  }),
  overrideExisting: false,
});


export const {
  useAdminFetchUserQuery,
  useAdminPaginateUsersQuery,
  useAdminCreateUserMutation,
  useAdminChangeCustomerMutation,
  useAdminPatchUserMutation,
  useAdminSendAmpWelcomeEmailMutation,
  useAdminUpsertUserPropertyMutation,
  useLazyAdminFetchUserByEmailQuery,
  useLazyAdminFetchUserQuery
} = adminUsersQueries;