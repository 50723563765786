import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, TextInput } from "@mantine/core";
import { useAdminUpsertCustomerPropertyMutation } from "admin/api/customers";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { ICustomer, getCustomerLogoPath } from "shared/types/customer";


const EditCustomerLogoModal = ({
  onClose,
  isOpen,
  customer,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customer: ICustomer}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [upsert] = useAdminUpsertCustomerPropertyMutation();
  const defaultPath = useMemo(() => getCustomerLogoPath(customer) || '', [customer]);
  const [logoPath, setLogoPath] = useState(defaultPath);

  useEffect(() => {
    setLogoPath(defaultPath);
  }, [defaultPath]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customer) {
      return
    }
    try {
      await upsert({
        customerId: customer.id,
        property_name: 'logo_path',
        property_namespace: 'SETTINGS',
        property_type: 'STRING',
        property_value: logoPath,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setLogoPath('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this customer's logo path"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The path of the logo</InputLabel>
          <InputDescription>This should be a string that exists in the static files bucket. It will be requested when trying to render the customer's logo</InputDescription>
          <TextInput value={logoPath} onChange={(e) => setLogoPath(e.target.value)}/>
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditCustomerLogoModal;