import { Button, Group, InputLabel, InputWrapper, Modal, NumberInput, Select, Switch, Textarea, TextInput } from '@mantine/core';
import { FormEvent, useEffect, useState } from 'react';

import { useAdminDeleteCustomerPropertyMutation, useAdminUpsertCustomerPropertyMutation } from 'admin/api/customers';
import { CustomerPropertyNamespace, CustomerPropertyTypes, IParsedCustomerProperty } from 'shared/types/customer';
import './style.css';

const propValueToString = (val?: IParsedCustomerProperty['value']) => {
  if (typeof val === 'object' && val !== null) {
    return JSON.stringify(val);
  } else if (val === undefined) {
    return '';
  } else {
    return val.toString();
  }
}

const UpsertCustomerPropertyModal = ({
  onClose,
  isOpen,
  customerId,
  customerProperty,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customerId: string, customerProperty?: IParsedCustomerProperty}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post] = useAdminUpsertCustomerPropertyMutation();
  const [deleteProperty] = useAdminDeleteCustomerPropertyMutation();
  const [name, setName] = useState(customerProperty?.name || '');
  const [type, setType] = useState<CustomerPropertyTypes>(customerProperty?.type || CustomerPropertyTypes.STRING);
  const [namespace, setNamespace] = useState<CustomerPropertyNamespace>(customerProperty?.namespace || CustomerPropertyNamespace.SETTINGS);
  const [value, setValue] = useState(propValueToString(customerProperty?.value.toString()));

  useEffect(() => {
    setName(customerProperty?.name || '');
    setType(customerProperty?.type || CustomerPropertyTypes.STRING);
    setNamespace(customerProperty?.namespace || CustomerPropertyNamespace.SETTINGS);
    setValue(propValueToString(customerProperty?.value.toString()));
  }, [customerProperty]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customerId) {
      return;
    }
    try {
      await post({
        customerId,
        property_name: name,
        property_namespace: namespace,
        property_type: type,
        property_value: value,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteClick = async () => {
    if (!customerId || !customerProperty) {
      return;
    }

    setIsLoading(true);
    try {
      await deleteProperty({ customerId, propertyName: customerProperty.name }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  }

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  const onChangeNamespace = (newNs: CustomerPropertyNamespace) => {
    setNamespace(newNs);
    if (newNs === CustomerPropertyNamespace.GATES) {
      setType(CustomerPropertyTypes.BOOLEAN);
      setValue('true');
    }
  }

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title={!customerProperty ? "Create a new property for this customer" : "Edit this customer property"}
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The ID of the customer</InputLabel>
          <TextInput value={customerId} disabled />
        </InputWrapper>

        <InputWrapper mt={16}>
          <InputLabel>Property name</InputLabel>
          <TextInput value={name} onChange={(e) => setName(e.target.value)} />
        </InputWrapper>

        <Select
          data={[
            {value: CustomerPropertyNamespace.SETTINGS, label: 'Settings'},
            {value: CustomerPropertyNamespace.GATES, label: 'Feature gates'},
            {value: CustomerPropertyNamespace.PRIVATE, label: 'Internal system configuration'},
            {value: CustomerPropertyNamespace.NONE, label: 'Other'},
          ]}
          value={namespace}
          label="Namespace"
          onChange={(newNamespace) => newNamespace && onChangeNamespace(newNamespace as CustomerPropertyNamespace)}
          allowDeselect={false}
        />

        <Select
          data={[
            {value: CustomerPropertyTypes.STRING, label: 'Text'},
            {value: CustomerPropertyTypes.NUMBER, label: 'Number'},
            {value: CustomerPropertyTypes.BOOLEAN, label: 'On/Off'},
            {value: CustomerPropertyTypes.JSON, label: 'JSON'},
          ]}
          value={type}
          label="Type"
          onChange={(newType) => newType && setType(newType as CustomerPropertyTypes)}
          allowDeselect={false}
          disabled={namespace === CustomerPropertyNamespace.GATES}
        />


        <InputWrapper mt={16}>
          <InputLabel>Value</InputLabel>
          {type === CustomerPropertyTypes.STRING &&
            <Textarea value={value} onChange={(e) => setValue(e.target.value)} />
          }
          {type === CustomerPropertyTypes.NUMBER &&
            <NumberInput value={isNaN(parseInt(value)) ? '0' : value} onChange={(e) => setValue(e.toString())} />
          }
          {type === CustomerPropertyTypes.BOOLEAN &&
            <Switch checked={value === 'true'} onChange={() => value === 'true' ? setValue('false') : setValue('true')}/>
          }
          {type === CustomerPropertyTypes.JSON &&
            <Textarea value={value} onChange={(e) => setValue(e.target.value)} />
          }
        </InputWrapper>

        <Group justify="space-between" mt="md">
          {customerProperty ? <Button className="admin-customer--delete-button" onClick={onDeleteClick}>Delete</Button> : <div></div>}
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default UpsertCustomerPropertyModal;