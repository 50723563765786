import { Group, Title } from '@mantine/core';
import { useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import Tabs from 'amp/components/Tabs';
import { useAllocationRunProgramOutputData } from 'amp/store/allocationRuns/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { getCurrentUser } from 'shared/store/user/selectors';
import { IAllocationRunInputData, IAllocationSummaryResult } from 'shared/types/allocation';
import { useAppSelector } from 'store';
import AllocationResultsOverviewCommitVsMatchChart from './commitVsMatchedChart';
import AllocationResultsOverviewGenMatchChart from './generationMatchedChart';
import AllocationResultsOverviewProgramMatchingChart from './hourlyMatchingChart';
import AllocationResultsOverviewProgramDetailsOverview from './overview';


const ProgramDetails = ({summary, inputs}: {summary: IAllocationSummaryResult['summary_results'], inputs: IAllocationRunInputData}) => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params] = useSearchParams();
  const location = useLocation();
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const oci = useAppSelector(getViewingOpCoId);
  const firstProgramId = Object.keys(summary.hourly_gen_and_load_by_program)[0]
  const selectedProgramId = params.get('pr') || firstProgramId;
  const programs = useMemo(() => inputs.programs, [inputs.programs]);

  const programAllocationRes = useAllocationRunProgramOutputData(runId, oci || currentCustomerId, selectedProgramId);
  const programResults = programAllocationRes.data;

  const customersRes = useUtilityCustomers();
  const program = programs.find(p => p.id === selectedProgramId);

  const tabs = useMemo(() => Object.keys(summary.hourly_gen_and_load_by_program).map((programId, idx) => {
    const qs = new URLSearchParams(location.search);
    qs.set('pr', programId);
    const name = programs.find(p => p.id === programId)?.name;
    return {
      title: name || 'Unknown program',
      path: `${location.pathname}?${qs}`,
      disabled: false,
      selected: selectedProgramId ? programId === selectedProgramId : idx === 0,
    }
  }), [summary.hourly_gen_and_load_by_program, location.search, location.pathname, selectedProgramId, programs]);

  return <div>
    <Title fz="20px" fw="700" c="var(--color-blue-2)" mb="24px">Program Details</Title>
    <Tabs tabs={tabs}/>
    {program &&
      <>
        <AllocationResultsOverviewProgramDetailsOverview program={program} summary={summary} inputs={inputs}/>
        <Group mt="24px" mb="24px">
          <BasePaper titleContent="Generation Matching" className="allocation-results-overview-pie--container">
            <AllocationResultsOverviewGenMatchChart customers={customersRes.data} programResults={programResults} />
          </BasePaper>
          <BasePaper titleContent="Generation Commitment vs Generation Matched"  className="allocation-results-overview-generation--container">
            <AllocationResultsOverviewCommitVsMatchChart customers={customersRes.data} programResults={programResults} inputs={inputs} />
          </BasePaper>
        </Group>
        <AllocationResultsOverviewProgramMatchingChart summary={summary} customers={customersRes.data} program={program} programResults={programResults} />
      </>
    }
  </div>
};

export default ProgramDetails;