import { InputDescription, InputLabel, InputWrapper, Loader, Select, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useLazyAdminFetchCustomerQuery } from "admin/api/customers";
import { useEffect, useState } from "react";
import { AggregationTypes } from "shared/types/aggregatedEvents";
import { AssetEventResolution } from "shared/types/assetEvents";

const aggTypeOptions = [
  {
    label: 'Total generation',
    value: AggregationTypes.TOTAL_GENERATION,
  },
  {
    label: 'Total metered consumption',
    value: AggregationTypes.TOTAL_LOAD,
  },
  {
    label: 'Total emissions',
    value: AggregationTypes.EMISSIONS_FROM_GENERATION,
  },
  {
    label: 'Generation by fuel category',
    value: AggregationTypes.GENERATION_BY_FUEL,
  },
];

const resolutionOptions = [
  {
    label: 'Hourly',
    value: AssetEventResolution.HOUR,
  },
  {
    label: 'Daily',
    value: AssetEventResolution.DAY,
  },
  {
    label: 'Monthly',
    value: AssetEventResolution.MONTH,
  },
  {
    label: 'Annually',
    value: AssetEventResolution.YEAR,
  },
];


const CreateAggregateAssetEventJob = ({
  onStatusChange
}: {onStatusChange: (status: Record<string, unknown>, isStatusValid: boolean) => void}) => {
  const [selectedAggType, setSelectedAggType] = useState(aggTypeOptions[0].value);
  const [selectedResolution, setSelectedResolution] = useState(resolutionOptions[0].value);
  const [customerId, setCustomerId] = useState('');
  const [doesCustomerExist, setDoesCustomerExist] = useState(false);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [fetchCustomer, fetchCustomerRes] = useLazyAdminFetchCustomerQuery();

  const checkDoesCustomerExist = async () => {
    if (!customerId) {
      if (doesCustomerExist) {
        setDoesCustomerExist(false)
      }
      return
    }

    try {
      await fetchCustomer(customerId).unwrap();
      setDoesCustomerExist(true);
    } catch (err) {
      setDoesCustomerExist(false);
    }
  }

  useEffect(() => {
    const isValid = !!customerId && doesCustomerExist && start.valueOf() <= end.valueOf();

    start.setUTCMinutes(0, 0, 0);
    end.setUTCMinutes(0, 0, 0);

    onStatusChange({
      customer_id: customerId,
      event_aggregation_type: selectedAggType,
      current_date: null,
      resolution: selectedResolution,
      start_date: start.toISOString(),
      end_date: end.toISOString(),
    }, isValid);
  }, [customerId, selectedAggType, doesCustomerExist, onStatusChange, start, end, selectedResolution]);

  return <div>
    <InputWrapper mt={8}>
      <InputLabel>Customer ID</InputLabel>
      <TextInput value={customerId} onChange={(e) => setCustomerId(e.target.value)} onBlur={checkDoesCustomerExist} />
      {fetchCustomerRes.isLoading && <InputDescription><Loader size={8}/> Checking that customer exists</InputDescription>}
      {!fetchCustomerRes.isLoading && doesCustomerExist && <InputDescription><IconCheck color="var(--color-teal-6)" size={8} /> Customer exists</InputDescription>}
      {!fetchCustomerRes.isLoading && !doesCustomerExist && !fetchCustomerRes.isUninitialized && <InputDescription><IconX color="var(--color-se-red-1)" size={8} /> Customer does not exist</InputDescription>}
    </InputWrapper>

    <Select data={aggTypeOptions} mt={8} value={selectedAggType} onChange={(e) => e && setSelectedAggType(e as AggregationTypes)} label="Aggregation type" />

    <Select data={resolutionOptions} mt={8} value={selectedResolution} onChange={(e) => e && setSelectedResolution(e as AssetEventResolution)} label="Resolution" />

    <DateTimePicker
      mt={8}
      label="Start"
      value={start}
      onChange={d => d && setStart(d)}
      w="180px"
    />

    <DateTimePicker
      mt={8}
      label="End"
      value={end}
      onChange={d => d && setEnd(d)}
      w="180px"
    />
  </div>
}

export default CreateAggregateAssetEventJob;