import { queries } from 'shared/api/queries';
import { IListAuditLogsArgs, IListAuditLogsResponse } from 'shared/types/auditLog';


export const auditLogsPage: {type: 'AuditLog', id: string} = {type: 'AuditLog', id: 'PAGE'};

const auditLogsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    fetchAuditLogsPage: build.query<IListAuditLogsResponse, IListAuditLogsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        if (body.action) {
          qs.set('action', body.action);
        }

        if (body.subjectIds) {
          body.subjectIds.forEach(subjectId => qs.append('subject_id', subjectId));
        }

        if (body.subjectType) {
          qs.set('subject_type', body.subjectType)
        }

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/audit_logs/?${qs}`,
          method: 'GET',
        }
      },
      providesTags: [auditLogsPage]
    }),
  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  useFetchAuditLogsPageQuery,
} = auditLogsQueries;