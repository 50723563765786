const MapIconSmall = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00008 5.99999C6.89551 5.99999 6.00008 6.89542 6.00008 7.99999C6.00008 9.10456 6.89551 9.99999 8.00008 9.99999C9.10465 9.99999 10.0001 9.10456 10.0001 7.99999C10.0001 6.89542 9.10465 5.99999 8.00008 5.99999ZM4.66675 7.99999C4.66675 6.15904 6.15913 4.66666 8.00008 4.66666C9.84103 4.66666 11.3334 6.15904 11.3334 7.99999C11.3334 9.84094 9.84103 11.3333 8.00008 11.3333C6.15913 11.3333 4.66675 9.84094 4.66675 7.99999Z" fill="#54637A"/>
  </svg>
);

export const getAsDOMElement = () => {
  const container = document.createElement('div');
  container.innerHTML = `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00008 5.99999C6.89551 5.99999 6.00008 6.89542 6.00008 7.99999C6.00008 9.10456 6.89551 9.99999 8.00008 9.99999C9.10465 9.99999 10.0001 9.10456 10.0001 7.99999C10.0001 6.89542 9.10465 5.99999 8.00008 5.99999ZM4.66675 7.99999C4.66675 6.15904 6.15913 4.66666 8.00008 4.66666C9.84103 4.66666 11.3334 6.15904 11.3334 7.99999C11.3334 9.84094 9.84103 11.3333 8.00008 11.3333C6.15913 11.3333 4.66675 9.84094 4.66675 7.99999Z" fill="#54637A"/>
    </svg>
  `;
  return container;
}

export default MapIconSmall;