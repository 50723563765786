import { useAdminBulkFetchCustomersQuery } from "admin/api/customers";
import AdminCustomersTable from "admin/components/CustomersTable";
import AdminViewContainer from "admin/components/ViewContainer";
import { getFavoriteCustomerIds } from "shared/store/user/selectors";
import { useAppSelector } from "store";
import './style.css';


const CustomersView = () => {
  const customerIds = useAppSelector(getFavoriteCustomerIds);

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminBulkFetchCustomersQuery({
    customerIds
  });

  const customers = data?.customers || [];

  return <AdminViewContainer title="All customers">
    <AdminCustomersTable
      customers={customers}
      isLoading={isLoading || isFetching}
      totalItems={customerIds.length}
    />
  </AdminViewContainer>
};

export default CustomersView;