import { Box, Group, Text } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';

import BasePaper from 'shared/components/Paper/basePaper';
import './style.css';

const WorkInProgressPage = () => {
  return (
    <Box p={60}>
      <BasePaper>
        <Group p={30}>
          <IconSettings className="work-in-progress--cog-icon" />
          <Text>This page is under construction and will be available soon!</Text>
          <Text fz={32}>👷👷‍♂️</Text>
        </Group>
      </BasePaper>
    </Box>
  )
}

export default WorkInProgressPage;