import { NavLink, Outlet } from "react-router-dom";


const DataAuthOutlet = () => {

  return (
    <div className="admin-data-ccd-outlet--container">
      <div className="admin-data-ccd-links--container">
        <NavLink to="/admin/data/user-auth/tables" className="admin-data-ccd-nav--link">SQL Tables</NavLink>
        <NavLink to="/admin/data/user-auth/query" className="admin-data-ccd-nav--link">SQL Query</NavLink>
      </div>
      <Outlet />
    </div>
  )
};

export default DataAuthOutlet;