import { Fragment, ReactNode } from 'react';

import { getHasRolePermission } from 'shared/store/user/selectors';
import { UserRole } from 'shared/types/user';
import { useAppSelector } from 'store';

const RoleRequired = ({ role, children }: { role: UserRole, children: ReactNode }) => {
  const hasPermission = useAppSelector(s => getHasRolePermission(s, role));

  if (hasPermission) {
    return <>{children}</>;
  }

  return <Fragment />;
}

export default RoleRequired;