import Tabs from "amp/components/Tabs";
import { Outlet } from "react-router-dom";

import AdminViewContainer from "admin/components/ViewContainer";
import './style.css';


const DataOutlet = () => {
  return <AdminViewContainer title="Data & schema" isRootView>
    <Tabs tabs={[
      { title: 'Corp Carbon', path: `/admin/data/ccd` },
      { title: 'User Auth', path: `/admin/data/user-auth` },
    ]} />
    <Outlet />
  </AdminViewContainer>
};

export default DataOutlet;
