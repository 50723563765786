import { useAdminFetchTableInfoQuery } from "admin/api/database";
import { Link, useParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const DataCCDTable = () => {
  const { table='' } = useParams<{table: string}>();
  const {data} = useAdminFetchTableInfoQuery(table);

  return <div>
    <div>
      <Link to="/admin/data/ccd/tables">&lt; Back</Link>
      <h2><pre>{table}</pre></h2>
    </div>
    <div>
      <h3>Columns</h3>
      <BaseTable
        columnNames={[{displayValue: 'Column name', key: 'column'}, {displayValue: 'Column type', key: 'type'}]}
        rows={(data?.data.columns || []).map((col) => ({id: col.column, ...col}))}
        totalPages={0}
        currentPage={1}
        onPageChange={() => undefined}
      />
    </div>
    <div>
      <h3>Indices</h3>
      <BaseTable
        columnNames={[{displayValue: 'Index name', key: 'index'}, {displayValue: 'Definition', key: 'definition'}]}
        rows={(data?.data.indices|| []).map((col) => ({id: col.index, ...col}))}
        totalPages={0}
        currentPage={1}
        onPageChange={() => undefined}
      />
    </div>

  </div>
};

export default DataCCDTable;