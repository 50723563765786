import { Select } from "@mantine/core";
import { useAdminPaginateScheduledTasksQuery } from "admin/api/scheduledTasks";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";
import { timestampToLongDate } from "shared/utils/dates";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'scheduledFor',
    displayValue: 'Scheduled for',
  },
  {
    key: 'updated',
    displayValue: 'Updated',
  },
]

const ScheduledTasksView = () => {
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const status = params.get('s') || 'not_started';

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateScheduledTasksQuery({
    page,
    perPage,
    status,
  });

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onStatusChange = (newStatus: string | null) => {
    if (!newStatus) return;

    setSearchParams(newParams => {
      newParams.set('s', newStatus);
      newParams.set('p', '1');
      return newParams;
    });
  }

  const onClickRow = (taskId: string) => {
    nav(`/admin/async-work/scheduled-tasks/${taskId}`);
  };

  const pagination = data?.meta.pagination;
  const tasks = data?.data || [];

  return <AdminViewContainer title="Tasks">
    <div>
      <Select value={status} data={[
        {label: 'Scheduled', value: 'not_started'},
        {label: 'In Progress', value: 'in_progress'},
        {label: 'Error', value: 'error'},
        {label: 'Done', value: 'done'},
      ]} onChange={onStatusChange} />
    </div>
    <BaseTable
      rows={tasks.map(task => ({
        id: task.id,
        name: task.name,
        scheduledFor: timestampToLongDate(task.scheduled_for),
        updated: timestampToLongDate(task.updated_at),
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default ScheduledTasksView;