import cx from 'classnames';
import { ReactNode } from "react";

import AdminActions from "../Actions";
import AdminTitle from "../Title";
import './style.css';


const AdminViewContainer = ({children, title, actions, isRootView=false}: {children?: ReactNode, title?: ReactNode, actions?: ReactNode, isRootView?: boolean}) => {
  return <div className={cx("admin-view--container", {root: isRootView})}>
    <AdminTitle>{title}</AdminTitle>
    <AdminActions>{actions}</AdminActions>
    {children}
  </div>
};

export default AdminViewContainer;