import { Box, Button, Group, Notification, Select, TextInput, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconX } from '@tabler/icons-react';
import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchCertPropDefQuery, useReplaceCertPropDefMutation } from 'amp/api/certPropDefs';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { CertificatePropertyType, dataToDisplayValues, displayValuesToRequestData, errorResponseToDisplayErrors } from 'shared/types/certificateProperties';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { useAppSelector } from 'store';
import './style.css';

const propTypeOptions = Object.values(CertificatePropertyType);

export default function SettingsCertPropDefEditView() {
  const { certPropDefId='' } = useParams<{certPropDefId: string}>();
  const oci = useAppSelector(getViewingOpCoId);
  const res = useFetchCertPropDefQuery({id: certPropDefId, customerId: oci});
  const propDef = res.data?.prop_def;
  const [isLoading, setIsLoading] = useState(false);
  const [submittedState, setSubmittedState] = useState<null | 'success' | 'fail'>(null);
  const [edit] = useReplaceCertPropDefMutation();
  const form = useForm({
    initialValues: dataToDisplayValues(propDef),
  });

  useEffect(() => {
    if (propDef && !form.isTouched()) {
      form.setValues(dataToDisplayValues(propDef))
    }
  }, [propDef, form]);


  const onSubmit = async (values: typeof form.values, e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      await edit({id: certPropDefId, customerId: oci, body: displayValuesToRequestData(values)}).unwrap()
      setSubmittedState('success');
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        form.setErrors(errorResponseToDisplayErrors(errMsgs));
      }
      setSubmittedState('fail');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSubmittedState(null);
      }, 1200);
    }
  };

  return <div>
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box maw="50vw" ml="lg">
        <div className="cert-prop-def-edit-form-field-section--container">
          <div className="cert-prop-def-edit-form-field-section--label">Fields</div>
          <TextInput
            withAsterisk
            placeholder="Name"
            label="Display Name"
            mb="lg"
            {...form.getInputProps('display_name')}
          />
          <TextInput
            placeholder="Property description..."
            label="Description"
            mb="lg"
            {...form.getInputProps('description')}
          />
          <Select
            label="Property type"
            data={propTypeOptions}
            className="audit-logs--dropdown"
            clearable
            {...form.getInputProps('property_type')}
          />
        </div>
        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </Box>
    </form>
    <Transition mounted={submittedState === 'success'}>
      {(transitionStyle) => (
        <Notification className="dashboard-global--notification-container" onClose={() => setSubmittedState(null)} title="Success" icon={<IconCheck style={{ width: '20px', height: '20px' }} />} color="teal" style={transitionStyle}>
         Your changes have been saved
        </Notification>
      )}
    </Transition>
    <Transition mounted={submittedState === 'fail'}>
      {(transitionStyle) => (
        <Notification className="dashboard-global--notification-container" onClose={() => setSubmittedState(null)} title="Error" icon={<IconX style={{ width: '20px', height: '20px' }} />} color="red" style={transitionStyle}>
          We failed to save your changes, please try again
        </Notification>
      )}
    </Transition>
  </div>;
}