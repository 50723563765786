import { Accordion, Button, Group, Loader, Tooltip } from "@mantine/core";
import { useAdminFetchCustomerQuery } from "admin/api/customers";
import Tabs from "amp/components/Tabs";
import { Outlet, useParams } from "react-router-dom";
import { ICustomer, getCustomerType } from "shared/types/customer";
import { timestampToShortDate } from "shared/utils/dates";

import { IconStar, IconStarFilled } from "@tabler/icons-react";
import { useAdminUpsertUserPropertyMutation } from "admin/api/users";
import EditCustomerParentModal from "admin/components/EditCustParentModal";
import EditCustomerLogoModal from "admin/components/EditCustomerLogoModal";
import EditCustomerTypeModal from "admin/components/EditCustomerTypeModal";
import RenameCustomerModal from "admin/components/RenameCusotmerModal";
import SwitchCustomerModal from "admin/components/SwitchCustomerModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { AmpLink } from "amp/components/Link";
import { useEffect, useState } from "react";
import { getCurrentUser, getFavoriteCustomerIds } from "shared/store/user/selectors";
import { receiveUser } from "shared/store/user/slice";
import { UserProperty } from "shared/types/user";
import { useAppDispatch, useAppSelector } from "store";
import './style.css';


const CustomerMetadata = ({customer}: {customer: ICustomer}) => {
  return <div>
    <Group>
      <div>ID</div>
      <div>{customer.id}</div>
    </Group>
    <Group>
      <div>Name</div>
      <div>{customer.name}</div>
    </Group>
    <Group>
      <div>Type</div>
      <div>{getCustomerType(customer) || <em>Not specified</em>}</div>
    </Group>
    <Group>
      <div>Status</div>
      <div>{customer.status}</div>
    </Group>
    <Group>
      <div>Created</div>
      <div>{timestampToShortDate(customer.created_at)}</div>
    </Group>
    <Group>
      <div>Updated</div>
      <div>{timestampToShortDate(customer.updated_at)}</div>
    </Group>
    <Group>
      <div>Has API Key</div>
      <div>{(customer.api_key_value || customer.api_key_value) ? 'Yes' : 'No'}</div>
    </Group>
    <Group>
      <div>Parent ID</div>
      {customer.parent_id && <AmpLink to={`/admin/customers/${customer.parent_id}/`}>{customer.parent_id}</AmpLink>}
      {!customer.parent_id && <em>no parent customer</em>}
    </Group>
  </div>
}


const CustomerOutlet = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchCustomerQuery(customerId, {skip: !customerId});
  const [editLogoModalOpen, setEditLogoModalOpen] = useState(false);
  const [editParentModalOpen, setEditParentModalOpen] = useState(false);
  const [switchCustomerModalOpen, setSwitchCustomerModalOpen] = useState(false);
  const [renameCustomerModalOpen, setRenameCustomerModalOpen] = useState(false);
  const [editTypeModalOpen, setEditTypeModalOpen] = useState(false);
  const currentUser = useAppSelector(getCurrentUser);
  const starredCustomerIds = useAppSelector(getFavoriteCustomerIds);
  const [patch, patchRes] = useAdminUpsertUserPropertyMutation();
  const dispatch = useAppDispatch();

  const addFavoriteCustomer = async () => {
    if (starredCustomerIds.includes(customerId)) {
      return;
    }

    try {
      await patch({
        user_id: currentUser.id,
        property_name: UserProperty.FAVE_CUST_IDS,
        property_namespace: 'SETTINGS',
        property_type: 'JSON',
        property_value: JSON.stringify([customerId, ...starredCustomerIds]),
      }).unwrap();
    } catch (err) {
      // TODO: better alerting
      console.warn("Failed to update favorite customers");
    }
  };

  const removeFavoriteCustomer = async () => {
    if (!starredCustomerIds.includes(customerId)) {
      return;
    }

    try {
      await patch({
        user_id: currentUser.id,
        property_name: UserProperty.FAVE_CUST_IDS,
        property_namespace: 'SETTINGS',
        property_type: 'JSON',
        property_value: JSON.stringify(starredCustomerIds.filter(cid => cid !== customerId)),
      }).unwrap();
    } catch (err) {
      // TODO: better alerting
      console.warn("Failed to update favorite customers");
    }
  };

  useEffect(() => {
    if (patchRes.isSuccess) {
      dispatch(receiveUser(patchRes.data));
    }
  }, [patchRes, dispatch]);

  if (isError) {
    return <div>Failed to load customer</div>;
  }

  const customer = data?.customer;

  const changeCustBtn = <Button size="xs" ml="8px" onClick={() => setSwitchCustomerModalOpen(true)} disabled={currentUser.customer_id === customerId}>Switch to this customer</Button>;

  return <>
    {customer && <EditCustomerLogoModal customer={customer} isOpen={editLogoModalOpen} onClose={() => setEditLogoModalOpen(false)}/>}
    {customer && <EditCustomerParentModal customer={customer} isOpen={editParentModalOpen} onClose={() => setEditParentModalOpen(false)}/>}
    {customer && <SwitchCustomerModal customer={customer} isOpen={switchCustomerModalOpen} onClose={() => setSwitchCustomerModalOpen(false)}/>}
    {customer && <RenameCustomerModal customer={customer} isOpen={renameCustomerModalOpen} onClose={() => setRenameCustomerModalOpen(false)}/>}
    {customer && <EditCustomerTypeModal customer={customer} isOpen={editTypeModalOpen} onClose={() => setEditTypeModalOpen(false)}/>}
    <AdminViewContainer
      title={
        <Group justify="space-between">
          <div>{customer?.name || customerId}</div>
          <div>
            <Tooltip label={starredCustomerIds.includes(customerId) ? 'Remove from favorites' : 'Add to favorites'}>
              {starredCustomerIds.includes(customerId) ? <IconStarFilled className="admin-customer-row--star" onClick={removeFavoriteCustomer} /> : <IconStar className="admin-customer-row--star" onClick={addFavoriteCustomer}/>}
            </Tooltip>
          </div>
        </Group>
        }
      isRootView
      actions={
        <div>
          <Button size="xs" onClick={() => setEditLogoModalOpen(true)}>Edit logo</Button>
          <Button size="xs" ml="8px" onClick={() => setEditParentModalOpen(true)}>Edit Parent ID</Button>
          {currentUser.customer_id === customerId ? <Tooltip label='You already belong to this customer'>{changeCustBtn}</Tooltip> : changeCustBtn}
          <Button size="xs" ml="8px" onClick={() => setRenameCustomerModalOpen(true)}>Rename Customer</Button>
          <Button size="xs" ml="8px" onClick={() => setEditTypeModalOpen(true)}>Edit Customer Type</Button>
        </div>
      }
    >
      {(isLoading || !customer) && <Loader />}
      {customer && <div>
        <Accordion>
          <Accordion.Item key="metadata" value="metadata">
            <Accordion.Control fz="12px">View metadata</Accordion.Control>
            <Accordion.Panel><CustomerMetadata customer={customer}/></Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Tabs tabs={[
          { title: 'Users', path: `/admin/customers/${customerId}/users` },
          { title: 'Sub-customers', path: `/admin/customers/${customerId}/sub-customers` },
          { title: 'Assets', path: `/admin/customers/${customerId}/assets` },
          { title: 'Allocation Runs', path: `/admin/customers/${customerId}/allocation-runs` },
          { title: 'Connectors', path: `/admin/customers/${customerId}/connectors` },
          { title: 'External IDs', path: `/admin/customers/${customerId}/external-ids` },
          { title: 'SSO', path: `/admin/customers/${customerId}/sso` },
          { title: 'Settings', path: `/admin/customers/${customerId}/settings` },
        ]} />
        <Outlet />
      </div>}
    </AdminViewContainer>
  </>
};

export default CustomerOutlet;
