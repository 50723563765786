import { Group, Select, Stack, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import Highcharts from 'highcharts';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchAggregatedEventsQuery } from 'amp/api/assetEvents';
import { useState } from 'react';
import BaseChart from 'shared/components/Chart/baseChart';
import { AggregationTypes, IAggregatedAssetEvent, ITotalLoadEventData, dataToDisplayValues } from 'shared/types/aggregatedEvents';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { getThisYearEnd, getThisYearStart } from 'shared/utils/dates';
import { numberToString } from 'shared/utils/strings';
import './style.css';

const resolutionOptions = [
  { label: 'Daily', value: AssetEventResolution.DAY },
  { label: 'Monthly', value: AssetEventResolution.MONTH },
  // { label: 'Yearly', value: AssetEventResolution.YEAR },
];


export default function CustomerLoadView() {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setParams] = useSearchParams();
  const startDate = params.get('s') || getThisYearStart().toISOString();
  const endDate = params.get('e') || getThisYearEnd().toISOString();
  const resolution = params.get('r') || '1d';
  const [start, setStart] = useState<null | Date>(new Date(startDate));
  const [end, setEnd] = useState<null | Date>(new Date(endDate));

  const res = useFetchAggregatedEventsQuery({
    startDate,
    endDate,
    resolution,
    aggregationType: AggregationTypes.TOTAL_LOAD,
    customerId,
  });

  let totalLoadKWh = 0;
  const clientAggregatedEvents: ReturnType<typeof dataToDisplayValues>[] = [];
  res.data?.data.forEach((aggregatedEvent: IAggregatedAssetEvent) => {
    const clientEvent = dataToDisplayValues(aggregatedEvent);
    const loadData = clientEvent.data as ITotalLoadEventData;
    totalLoadKWh += loadData.sum_consumed_kwh;
    clientAggregatedEvents.push(clientEvent);
  });

  const totalLoadMWh = totalLoadKWh / 1_000.0;

  const onDateRangeChange = (newDateRange: [Date | null, Date | null]) => {
    setStart(newDateRange[0]);
    setEnd(newDateRange[1]);
    if (newDateRange[0] !== null && newDateRange[1] !== null) {
      setParams(newParams => ({
        ...newParams,
        s: (newDateRange[0] as Date).toISOString(),
        e: (newDateRange[1] as Date).toISOString(),
      }))
    }
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    setParams(newParams => ({
      ...newParams,
      r: newResolution,
    }));
  };

  const chartOverrideOptions: Highcharts.Options = {
    series: [
      {
        type: 'column',
        name: 'Electricity usage (MWh)',
        data: res.data?.data.map(aggregatedEvent => {
          const genData = aggregatedEvent.data as ITotalLoadEventData;
          return [
            new Date(aggregatedEvent.start_date).getTime(),
            // convert kWh -> MWh and round to the tenths place
            Math.round(genData.sum_consumed_kwh / 1_000 * 10) / 10,
          ];
        }),
      }
    ]
  };

  return (
    <Stack>
      <Title size="h3">Customer Overview</Title>
      <Group>
        <Title size="h4">Total Load Over Selected Range:</Title>
        <Title size="h4">{numberToString(Math.round(totalLoadMWh))} MWh</Title>
      </Group>
      <Group>
        <Title size="h6">Select Range:</Title>
        <DatePickerInput
          type="range"
          value={[start, end]}
          onChange={onDateRangeChange}
          numberOfColumns={2}
          w="30%"
        />
      </Group>
      <Group>
        <Title size="h6">Select Resolution</Title>
        <Select
          data={resolutionOptions}
          onChange={onResolutionChange}
          value={resolution}
          clearable
          className="audit-logs--dropdown"
        />
      </Group>
      <BaseChart chartTitle="Load Over Time" yAxisTitle="MWh Consumed" overrideOptions={chartOverrideOptions} />
    </Stack>
  );
}