import { getViewingOpCoId } from "amp/store/ui/selectors";
import { Link, LinkProps } from "react-router-dom";
import { useAppSelector } from "store";


export const AmpLink = (props: LinkProps) => {
  const oci = useAppSelector(getViewingOpCoId);
  if (typeof props.to === 'string') {
    const path = props.to.split('?')[0] || '';
    const qs = props.to.split('?')[1] || '';
    const query = new URLSearchParams(qs);
    if (!query.has('oci') && oci) {
      query.set('oci', oci);
    }
    const newTo = `${path}?${query}`;
    return <Link {...props} to={newTo} />
  } else {
    const query = new URLSearchParams(props.to.search || '');
    if (!query.has('oci') && oci) {
      query.set('oci', oci);
    }
    const newTo = {
      ...props.to,
      search: `${query}`,
    };
    return <Link {...props} to={newTo} />
  }
};
