import { createSelector } from 'reselect';
import { nMinutesAgo } from 'shared/utils/dates';

import { RootState } from 'store';

const root = (state: RootState) => state.amp__program;

const byId = createSelector(
  root,
  (state) => state.byId,
);

export const getProgramById = createSelector(
  byId,
  (_: RootState, programId: string) => programId,
  (progsById, programId) => progsById[programId]
);

export const getProgramsById = createSelector(
  byId,
  (_: RootState, programIds: string[]) => programIds,
  (progsById, programIds) => programIds.map(programId => progsById[programId]).filter(x => !!x)
);


const subscriptionsById = createSelector(
  root,
  (state) => state.subscriptionsById,
);

export const getSubscriptionsById = createSelector(
  subscriptionsById,
  (_: RootState, subscriptionIds: string[]) => subscriptionIds,
  (subsById, subIds) => subIds.map(subId => subsById[subId]).filter(x => !!x)
)

export const getSubscriptionById = createSelector(
  subscriptionsById,
  (_: RootState, subscriptionId: string) => subscriptionId,
  (subsById, subId) => subsById[subId],
)


const assignmentsById = createSelector(
  root,
  (state) => state.assignmentsById,
);

export const getAssignmentsById = createSelector(
  assignmentsById,
  (_: RootState, assignmentIds: string[]) => assignmentIds,
  (assignmentsById, assignmentIds) => assignmentIds.map(assignmentId => assignmentsById[assignmentId]).filter(x => !!x)
);


const programPageResponseByOci = createSelector(
  root,
  (state) => state.programsPageResponseByOCI
);

const getProgramPageResponseByOci = createSelector(
  programPageResponseByOci,
  (_: RootState, oci: string) => oci,
  (pageResponseByOci, oci) => {
    const ociRes = pageResponseByOci[oci];
    if (!ociRes) {
      return {
        data: null,
        fetchFailed: false,
        isFetching: false,
        lastReceived: null,
      }
    } else {
      return ociRes;
    }
  }
);

export const getUtilityProgramPageLoading = createSelector(
  getProgramPageResponseByOci,
  ociRes => ociRes.isFetching,
);

export const getUtilityPrograms = createSelector(
  byId,
  getProgramPageResponseByOci,
  (progsById, ociRes) => ociRes.data?.map(pid => progsById[pid]) || []
);

export const getUtilityProgramPageError = createSelector(
  getProgramPageResponseByOci,
  ociRes => ociRes.fetchFailed,
);

export const shouldFetchUtilityProgramPage = createSelector(
  getProgramPageResponseByOci,
  (ociRes) => {
    if (!ociRes) {
      return true;
    }
    const isExpired = ociRes.lastReceived === null || ociRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !ociRes.isFetching && !ociRes.fetchFailed) {
      return true
    }
    return false;
  }
)