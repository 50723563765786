import { Title } from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';

import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import Tabs from 'amp/components/Tabs';
import { useAllocationRun } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { getHasRolePermission } from 'shared/store/user/selectors';
import { UserRole } from 'shared/types/user';
import { toNumericDateString } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';


export default function AllocationRunOutlet() {
  const { runId = '' } = useParams<{ runId: string }>();
  const oci = useAppSelector(getViewingOpCoId);
  const isAdmin = useAppSelector(s => getHasRolePermission(s, UserRole.ADMIN));
  useAllocationRun(runId, oci);
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));

  const tabs = [
    { title: 'Overview', path: `/dashboard/allocation/${runId}` },
    { title: 'Customers', path: `/dashboard/allocation/${runId}/customers` },
  ];

  if (isAdmin) {
    tabs.push({ title: 'Diagnostic', path: `/dashboard/allocation/${runId}/diagnostic` })
  }

  let title = "Allocation";
  if (startAndEnd?.end) {
    title = `Allocation ${toNumericDateString(startAndEnd.start)} - ${toNumericDateString(startAndEnd.end)}`;
  }

  return (
    <div>
      <div className="allocation-run-outlet-header--container">
        <PageBreadcrumbs />
        <Title size="24px" mt="16px" mb="16px">{title}</Title>
        <Tabs tabs={tabs} />
      </div>
      <Outlet />
    </div>
  );
}