import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useState } from 'react';

import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { usePatchAllocationRunMutation } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { AllocationRunStatus } from 'shared/types/allocation';
import { useAppSelector } from 'store';

const DeleteAllocationModal = ({
  onClose,
  isOpen,
  runId,
}: { onClose: (didSucceed: boolean) => void, isOpen: boolean, runId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [patch] = usePatchAllocationRunMutation();
  const oci = useAppSelector(getViewingOpCoId);

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  const onDeleteClicked = () => {
    setLoading(true);
    patch({ runId, customerId: oci, body: { status: AllocationRunStatus.ARCHIVED } })
      .then(() => notifications.show({
        title: 'Success',
        message: 'Successfully archived the allocation run',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      }))
      .catch(() => notifications.show({
        title: 'Error',
        message: 'Failed to archive the allocation',
        icon: <IconX style={{ width: '20px', height: '20px' }} />,
        color: "red",
      }))
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Archive Allocation"
    >
      <Stack>
        <Text fz={16} c="var(--color-neutral-12)">
          Are you sure you want to delete this allocation run? It will no longer appear in the list of allocations and the results will no longer be accessible.
        </Text>
        <TextInput label="Type ARCHIVE below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button onClick={onDeleteClicked} disabled={confirmText !== 'ARCHIVE'} maw={200} loading={loading}>
          Archive
        </Button>
      </Stack>
    </Modal>
  );
}

export default DeleteAllocationModal;