import { Group, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchConnectorQuery } from "admin/api/connectors";
import AdminViewContainer from "admin/components/ViewContainer";
import './style.css';


const CustomerConnectorView = () => {
  const { connectorId='' } = useParams<{connectorId: string}>();
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchConnectorQuery(connectorId, {skip: !connectorId});

  if (isError) {
    return <div>Failed to load connector</div>;
  }

  const connector = data?.data;

  return <AdminViewContainer title={connector?.provider || connectorId}>
    {(isLoading || !connector) && <Loader />}
    {connector && <div>
      <div>
        actions to do things here
      </div>
      <div>
        <Group>
          <div>ID</div>
          <div>{connector.id}</div>
        </Group>
        <Group>
          <div>Provider</div>
          <div>{connector.provider}</div>
        </Group>
        <Group>
          <div>External account Id</div>
          <div>{connector.external_account_id || <em>No external account ID</em>}</div>
        </Group>
        <Group>
          <div>Status</div>
          <div>{connector.status}</div>
        </Group>
        <Group>
          <div>Metadata</div>
          <div>{JSON.stringify(connector.meta)}</div>
        </Group>
      </div>
    </div>}
  </AdminViewContainer>
};

export default CustomerConnectorView;
