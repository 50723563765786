import { Button } from '@mantine/core';
import { captureException } from '@sentry/react';
import { IconMoodSadDizzy } from '@tabler/icons-react';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, useNavigate, useParams, useRouteError, useSearchParams } from 'react-router-dom';

import WorkInProgressPage from 'shared/components/WorkInProgressPage/workInProgress';
import { useAppDispatch, useAppSelector } from 'store';
import PageBreadcrumbs from './components/PageBreadcrumbs/pageBreadcrumbs';
import Shell from './components/Shell/shell';
import AllocationRunOutlet from './outlets/AllocationRun';
import AllocationRunsOutlet from './outlets/AllocationRuns';
import CustomerOutlet from './outlets/Customer';
import InventoryOutlet from './outlets/Inventory';
import ProgramOutlet from './outlets/Program';
import ProjectionsOutlet from './outlets/Projections';
import SettingsOutlet from './outlets/Settings';
import { viewingOpCoId } from './store/ui/selectors';
import { setViewingOpco } from './store/ui/slice';
import AllocationCreateView from './views/allocationCreate';
import AllocationResultsCustomer from './views/allocationResultsCustomer';
import AllocationResultsDiagnostic from './views/allocationResultsDiagnostic';
import AllocationResultsOverview from './views/allocationResultsOverview';
import AllocationsView from './views/allocations';
import CustomerView from './views/customer';
import CustomerAccountView from './views/customerAccount';
import CustomerAccountsView from './views/customerAccounts';
import EditCustomerView from './views/customerEdit';
import CustomerLoadView from './views/customerLoad';
import CustomersListView from './views/customers';
import GeneratorView from './views/generator';
import GeneratorsView from './views/generators';
import InventorySummaryView from './views/inventorySummary';
import ProgramView from './views/program';
import ProgramAssignments from './views/programAssignments';
import CreateProgramView from './views/programCreate';
import EditProgramView from './views/programEdit';
import ProgramHistoryView from './views/programHistory';
import ProgramSubscriptionView from './views/programSubscription';
import ProgramsView from './views/programs';
import ProjectionsView from './views/projectionsView';
import ReportsView from './views/reports';
import ReportsCustomerDashboard from './views/reportsCustomerDashboard';
import SettingsAuditLogsView from './views/settingsAuditLogs';
import SettingsCertificatePropertyDefinitionView from './views/settingsCertProp';
import SettingsCertPropDefEditView from './views/settingsCertPropEdit';
import SettingsCertificatePropDefsView from './views/settingsCertProps';
import SettingsCertificatePropertyDefinitionCreateView from './views/settingsCertPropsCreate';
import SettingsPreferencesView from './views/settingsPreferences';
import SettingsUsersView from './views/settingsUsers';
import CreateSubscriptionView from './views/subscriptionCreate';
import EditSubscriptionView from './views/subscriptionEdit';


export const AmpOutlet = () => {
  const [params] = useSearchParams();
  const ociInParams = params.get('oci');
  const dispatch = useAppDispatch();
  const ociInState = useAppSelector(viewingOpCoId);

  useEffect(() => {
    if (ociInParams !== ociInState) {
      dispatch(setViewingOpco(ociInParams));
    }
  }, [ociInParams, ociInState, dispatch]);

  return (
    <Shell>
      <Outlet />
    </Shell>
  )
}

export const AmpErrorBoundary = () => {
  const nav = useNavigate();
  const error = useRouteError();

  if (error) {
    captureException(error);
  }

  return (
    <Shell>
      <div className="error-boundary--breadcrumbs">
        <PageBreadcrumbs />
      </div>
      <div className="error-boundary--container">
        <div className="error-boundary--icon">
          <IconMoodSadDizzy color="var(--color-se-red-1)" height={32} width={32} />
        </div>
        <div className="error-boundary-text--container">
          <h2 className="error-boundary-text--title">Whoops!</h2>
          <p className="error-boundary-text--description">Something went wrong, sorry about that. The engineering team has been alerted. You can reload to try again, or try another time.</p>
          <Button className="error-boundary-text--button" onClick={() => nav(0)}>Reload</Button>
        </div>
      </div>
    </Shell>
  )
};

const ampRoutes = (
  <>
    <Route path="*" element={<Navigate to="/dashboard/inventory" replace />} index/>

    <Route path="programs">
      <Route element={<ProgramsView />} index />
      <Route path="create" element={<CreateProgramView />} index />
      <Route path=":programId" element={<ProgramOutlet />}>
        <Route element={<ProgramView />} index />
        <Route path="assignments" element={<ProgramAssignments />} index/>
        <Route path="history" element={<ProgramHistoryView />} />
        <Route path="edit" element={<EditProgramView />} index />
        <Route path="subscribe" element={<CreateSubscriptionView />} index />
        <Route path=":subscriptionId">
          <Route element={<ProgramSubscriptionView />} index />
          <Route path="edit" element={<EditSubscriptionView />} index />
        </Route>
      </Route>
    </Route>

    <Route path="inventory" element={<InventoryOutlet />}>
      <Route element={<InventorySummaryView />} index />
      <Route path="sources">
        <Route element={<GeneratorsView />} index />
        <Route path=":generatorId" element={<GeneratorView />} />
      </Route>
      {/* this path should be a redirect because otherwise breadcrumbs will not be shown properly */}
      <Route path=":generatorId" Component={() => {
        const { generatorId } = useParams<{ generatorId: string }>();
        return <Navigate to={`/dashboard/inventory/sources/${generatorId}`} replace/>
      }}>
      </Route>
    </Route>

    <Route path="customers">
      <Route element={<CustomersListView />} index />
      <Route path=":customerId" element={<CustomerOutlet />}>
        <Route element={<CustomerView />} index />
        <Route element={<EditCustomerView />} path="edit" />
        <Route element={<CustomerLoadView />} path="load" />
        <Route path="accounts">
          <Route element={<CustomerAccountsView />} index />
          <Route element={<CustomerAccountView />} path=":accountId"/>
        </Route>
      </Route>
    </Route>

    <Route path="projections" element={<ProjectionsOutlet />}>
      <Route element={<ProjectionsView />} index />
    </Route>

    <Route path="allocation" element={<AllocationRunsOutlet />}>
      <Route index element={<AllocationsView />} />
      <Route path="create" element={<AllocationCreateView />} />
      <Route path=":runId" element={<AllocationRunOutlet />}>
        <Route index element={<AllocationResultsOverview />} />
        <Route path="customers" element={<AllocationResultsCustomer />} />
        <Route path="diagnostic" element={<AllocationResultsDiagnostic />} />
      </Route>
      <Route path="history">
        <Route index element={<AllocationsView />} />
        {/* <Route path=":runId" element={<AllocationRunOutlet />}>
          <Route index element={<AllocationView />} />
          <Route path="inputs" element={<AllocationRunInputsView />} />
          <Route path="results" element={<AllocationRunResultsView />} />
        </Route> */}
      </Route>
      <Route path="console" element={<AllocationCreateView />} />
    </Route>

    <Route path="reports" element={<AllocationRunsOutlet />}>
      <Route index element={<ReportsView />} />
      <Route path=":runId" element={<ReportsCustomerDashboard />} />
    </Route>

    <Route path="emissions" element={<WorkInProgressPage />}>
    </Route>

    <Route path="settings" element={<SettingsOutlet />}>
      <Route path="users" element={<SettingsUsersView />} />
      <Route path="audit-logs" element={<SettingsAuditLogsView />} />
      <Route path="preferences" element={<SettingsPreferencesView />} />
      <Route path="certificate-props">
        <Route element={<SettingsCertificatePropDefsView />} index />
        <Route element={<SettingsCertificatePropertyDefinitionCreateView />} path="create" />
        <Route path=":certPropDefId">
          <Route element={<SettingsCertificatePropertyDefinitionView />} index />
          <Route element={<SettingsCertPropDefEditView />} path="edit" />
        </Route>
      </Route>
      <Route index element={<Navigate to="/dashboard/settings/preferences"/>} />
    </Route>

  </>
)

export default ampRoutes;