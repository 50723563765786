import { Box, Button, Checkbox, Group, Notification, Paper, Text, TextInput, Title, Transition } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { FormEvent, useEffect, useState } from 'react';

import { useCreateRunMutation } from 'amp/api/allocationRuns';
import { useAmpNav } from 'amp/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useSearchParams } from 'react-router-dom';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import { useCustomer } from 'shared/store/customers/hooks';
import { getMaxAllocationEndDateForCustomer } from 'shared/store/customers/selectors';
import { getLastYearEnd, getLastYearStart } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';


export default function AllocationCreateView() {
  const [params] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);

  const [isLoading, setIsLoading] = useState(false);
  const [submittedState, setSubmittedState] = useState<null | 'success' | 'fail'>(null);

  useCustomer(oci || '');
  const maxForCustomer = useAppSelector(s => getMaxAllocationEndDateForCustomer(s, oci || ''));
  const [maxEndDate, setMaxEndDate] = useState<Date | undefined>(undefined);
  const startDateStr = params.get('s') || getLastYearStart().toISOString();
  const endDateStr = params.get('e') || maxEndDate || getLastYearEnd().toISOString();
  const start = new Date(startDateStr);
  const end = new Date(endDateStr)

  const [description, setDescription] = useState<string | null>(null);
  const [startImmediately, setStartImmediately] = useState(true);
  const [createRun] = useCreateRunMutation();
  const nav = useAmpNav();

  useEffect(() => {
    setMaxEndDate(maxForCustomer);
  }, [maxForCustomer]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      createRun({
        data: {
          start_immediately: true,
          algorithm_config_version: 'v3',
          config: { start: start.toISOString(), end: end.toISOString() },
          description,
        },
        customerId: oci,
      });
      setSubmittedState('success');
      nav('/dashboard/allocation')
    } catch (err) {
      setSubmittedState('fail');
    } finally {
      setIsLoading(false);
      setTimeout(() => setSubmittedState(null), 2000);
    }
  };

  return (
    <Paper shadow="md" p="xl">
      <Title order={3} mb={8}>Create an allocation run</Title>
      <Text>Create a new run with current program configurations, customer subscriptions, and generator assigments. Optionally start the allocation run immediately.</Text>
      <div>
        <form onSubmit={onSubmit}>
          <Box maw="50vw" ml="lg" mt={40}>
            <div className="certificate-prop-def-create-form--field-section-container">
              <Checkbox
                label="Start allocation run"
                mb="lg"
                checked={startImmediately}
                onChange={() => setStartImmediately(!startImmediately)}
              />
              <UtcDatePicker
                value={start}
                isStartDate={true}
                maxDate={end}
                label="Start"
              />
              <UtcDatePicker
                value={end}
                maxDate={maxEndDate}
                label="End"
              />
              <TextInput
                label="Note"
                mb="lg"
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <Group justify="flex-end" mt="md">
              <Button loading={isLoading} type="submit">Submit</Button>
            </Group>
          </Box>
        </form>
        <Transition mounted={submittedState === 'success'}>
          {(transitionStyle) => (
            <Notification
              onClose={() => setSubmittedState(null)}
              title="Success"
              icon={<IconCheck style={{ width: '20px', height: '20px' }} />}
              color="teal"
              className="dashboard-global--notification-container"
              style={transitionStyle}
            >
              Your changes have been saved
            </Notification>
          )}
        </Transition>
        <Transition mounted={submittedState === 'fail'}>
          {(transitionStyle) => (
            <Notification
              onClose={() => setSubmittedState(null)}
              title="Error"
              icon={<IconX style={{ width: '20px', height: '20px' }} />}
              color="red"
              className="dashboard-global--notification-container"
              style={transitionStyle}
            >
              We failed to save your changes, please try again
            </Notification>
          )}
        </Transition>
      </div>
    </Paper>
  );
}