import { Socket } from "phoenix";
import { WS_URL } from "shared/constants/resources";
import { receiveMyCustomerProperties, receiveMyUserProperties, receiveUser } from "shared/store/user/slice";
import { CustomerPropertyNamespace, CustomerPropertyTypes, IParsedCustomerProperty } from "shared/types/customer";
import { IUser, IUserAttribute, UserPropertyNamespace, UserPropertyTypes } from "shared/types/user";
import { looksLikeUUID } from "shared/utils/strings";
import { store } from "store";


let socket: Socket;

interface IConnectParams {
  wsToken: string,
  channels: string[],
}


enum WSEventNames {
  USER_UPDATE = 'user_update',
  USER_PROPERTIES = 'user_properties',
  JOB_STATUS_UPDATE = 'job_status_update',
  CUSTOMER_PROPERTIES = 'customer_properties',

  JOB_UPDATE = 'JOB_UPDATE',
}


interface ICustomerPropertiesPayload {
  properties: IParsedCustomerProperty[];
}

interface IUserUpdatePayload {
  user: IUser;
}

interface IUserPropertiesPayload {
  properties: IUserAttribute;
}

const isParsedCustomerProperty = (maybeProp: any): maybeProp is IParsedCustomerProperty => {
  if (typeof maybeProp !== 'object') return false;
  if (maybeProp === null) return false;
  if ('type' in maybeProp && 'name' in maybeProp && 'value' in maybeProp && 'namespace' in maybeProp) {
    return Object.values(CustomerPropertyTypes).includes(maybeProp.type) && Object.values(CustomerPropertyNamespace).includes(maybeProp.namespace) && typeof maybeProp.name === 'string';
  } else {
    return false;
  }
}

const isParsedUserProperty = (maybeProp: any): maybeProp is IParsedCustomerProperty => {
  if (typeof maybeProp !== 'object') return false;
  if (maybeProp === null) return false;
  if ('type' in maybeProp && 'name' in maybeProp && 'value' in maybeProp && 'namespace' in maybeProp) {
    return Object.values(UserPropertyTypes).includes(maybeProp.type) && Object.values(UserPropertyNamespace).includes(maybeProp.namespace) && typeof maybeProp.name === 'string';
  } else {
    return false;
  }
}


const isCustomerPropertiesPayload = (payload: any): payload is ICustomerPropertiesPayload => {
  if (typeof payload !== 'object') return false;
  if (payload === null) return false;
  if ('properties' in payload) {
    if (Array.isArray(payload.properties)) {
      return payload.properties.every(isParsedCustomerProperty);
    } else {
      return false;
    }
  }
  return false;
}

const isUserPropertiesPayload = (payload: any): payload is IUserPropertiesPayload => {
  if (typeof payload !== 'object') return false;
  if (payload === null) return false;
  if ('properties' in payload) {
    if (Array.isArray(payload.properties)) {
      return payload.properties.every(isParsedCustomerProperty);
    } else {
      return false
    }
  }
  return false;
}

const isUserUpdatePayload = (payload: any): payload is IUserUpdatePayload => {
  if (typeof payload !== 'object') return false;
  if (payload === null) return false;
  if ('user' in payload) {
    if (typeof payload.user !== 'object') return false;
    if (payload.user === null) return false;

    const user = payload.user;

    if (!('id' in user) || typeof user.id !== 'string' || !looksLikeUUID(user.id)) {
      return false;
    }
    if (!('customer_id' in user) || typeof user.customer_id !== 'string') {
      return false;
    }
    if (!('email' in user) || typeof user.email !== 'string') {
      return false;
    }
    if (!('name' in user) || typeof user.name !== 'string') {
      return false;
    }
    // TODO: check against UserRole
    if (!('role' in user) || typeof user.role !== 'string') {
      return false;
    }
    // TODO: check against UserStatus
    if (!('status' in user) || typeof user.status !== 'string') {
      return false;
    }

    if ('attributes' in user) {
      if (Array.isArray(user.attributes)) {
        if (!user.attributes.every(isParsedUserProperty)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }
  return false;
}

type CallbackActionCreator = typeof receiveMyCustomerProperties | typeof receiveUser | typeof receiveMyUserProperties
type ActionCreatorParams = Parameters<typeof receiveMyCustomerProperties>[0] & Parameters<typeof receiveUser>[0] & Parameters<typeof receiveMyUserProperties>[0]

const eventsToCallback: Record<WSEventNames, {actionCreator: CallbackActionCreator, payloadValidator: (payload: any) => boolean} | undefined> = {
  [WSEventNames.CUSTOMER_PROPERTIES]: {
    actionCreator: receiveMyCustomerProperties,
    payloadValidator: isCustomerPropertiesPayload,
  },
  [WSEventNames.JOB_STATUS_UPDATE]: undefined,
  [WSEventNames.JOB_UPDATE]: undefined,
  [WSEventNames.USER_PROPERTIES]: {
    actionCreator: receiveMyUserProperties,
    payloadValidator: isUserPropertiesPayload,
  },
  [WSEventNames.USER_UPDATE]: {
    actionCreator: receiveUser,
    payloadValidator: isUserUpdatePayload,
  },
}


export function connect({wsToken, channels}: IConnectParams) {
  if (!socket) {
    socket = new Socket(`${WS_URL}/socket`, { params: { token: wsToken } });
  } else {
    return;
  }

  socket.connect();
  socket.onError(() => socket.disconnect());
  channels.forEach(channelName => {
    const channel = socket.channel(channelName);
    channel.join()
      // TODO: better logging
      .receive('ok', () => console.log('joined topic', channelName))
      .receive('error', () => {
        console.log('Failed to join topic', channelName)
        channel.leave();
      });

    channel.onMessage = (event: any, payload: unknown) => {
      if (typeof event === 'string' && event.includes(':')) {
        const [singularity, eventName] = event.split(':');
        const isAcceptableEvent = singularity === 'singularity';
        const isKnownEventName = Object.values(WSEventNames).includes(eventName as WSEventNames);
        const callbacks = eventsToCallback[eventName as WSEventNames];
        if (isAcceptableEvent && isKnownEventName && !!callbacks) {
          const {
            payloadValidator,
            actionCreator,
          } = callbacks;

          if (payloadValidator(payload)) {
            const action = actionCreator(payload as ActionCreatorParams);
            store.dispatch(action);
          }
        } else {
          console.warn('Invalid WS channel message', event, payload);
        }
      }
      return payload;
    };
  });
}