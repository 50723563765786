import { Loader } from '@mantine/core';

import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';

const AllocationResultsCfePieChart = (
  { programCfe, gridCfe, gridNonCfe, customerHasSubscription }:
    { programCfe: number | null, gridCfe: number | null, gridNonCfe: number | null, customerHasSubscription: boolean }
) => {
  if (programCfe === null || gridCfe === null || gridNonCfe === null) {
    return <Loader />
  }

  const total = programCfe + gridCfe + gridNonCfe;
  const nonProgramSeries = [{
    name: 'Grid CFE',
    color: '#3f9493',
    y: gridCfe / 1_000_000,
    z: 60,
  }, {
    name: 'Grid non-CFE',
    color: '#ced4da',
    y: gridNonCfe / 1_000_000,
    z: 10,
  }];
  const programSeries = {
    name: 'Program generation',
    color: '#62c1ab',
    y: programCfe / 1_000_000,
    z: 90,
  };
  const series = customerHasSubscription ? [programSeries, ...nonProgramSeries] : nonProgramSeries;
  const cfePieChartOptions: Highcharts.Options = {
    chart: {
      type: 'variablepie',
      height: '350px',
    },
    legend: {
      enabled: true,
      layout: 'vertical',
    },
    title: {
      text: total === 0 ? 'None' : `${(((programCfe + gridCfe) / total) * 100).toFixed(1)} %`,
      align: 'center',
      verticalAlign: 'middle',
      y: -16,
      style: {
        color: '#245d5b',
        fontSize: '25px',
      },
    },
    tooltip: {
      headerFormat: '<span style="color:{point.color}">\u25CF</span> {point.key}: ',
      pointFormat: '<b>{point.y} MWh</b>',
      valueDecimals: 0,
    },
    plotOptions: {
      variablepie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [{
      type: 'variablepie',
      innerSize: '50%',
      zMin: 0,
      data: series,
    }],
  };

  return (
    <BasePaper className="allocation-results-customer--pie-container" titleContent="Total Carbon-Free Energy Matched (CFE)">
      <BaseChart overrideOptions={cfePieChartOptions} />
    </BasePaper>
  );
};

export default AllocationResultsCfePieChart;