import { createSelector } from "reselect";
import { customersById } from "shared/store/customers/selectors";
import { getCurrentCustomer, getOpcos } from "shared/store/user/selectors";
import { getCustomerType, ICustomer, isOpcoCustomer } from "shared/types/customer";

import { RootState } from "store";

const root = (state: RootState) => state.amp__ui;

export const viewingOpCoId = createSelector(
  root,
  (ui) => ui.viewingOpCoId || '',
);

export const getViewingOpCoId = createSelector(
  viewingOpCoId,
  getCurrentCustomer,
  (oci, currentCustomer) => {
    if (currentCustomer && isOpcoCustomer(currentCustomer)) {
      return currentCustomer.id;
    } else {
      return oci;
    }
  }
);

const getDataDates = (customer?: ICustomer) => {
  const latestGen = customer?.attributes.find(a => a.name === 'latest_amp_generation_date')?.value as string | undefined;
  const latestLoad = customer?.attributes.find(a => a.name === 'latest_amp_consumption_date')?.value as string | undefined;

  return {
    latestGen,
    latestLoad,
  };
}

export const getLatestLoadAndGenDataDates = createSelector(
  getViewingOpCoId,
  getOpcos,
  getCurrentCustomer,
  (viewingOpcoId, opcos, currentCustomer) => {
    const opcoIds = opcos.map(({id}) => id);
    if (currentCustomer && isOpcoCustomer(currentCustomer)) {
      return getDataDates(currentCustomer);
    } else if (!viewingOpcoId) {
      return getDataDates(currentCustomer || undefined);
    } else if (!opcoIds.includes(viewingOpcoId)) {
      return getDataDates(currentCustomer || undefined);
    } else {
      return getDataDates(opcos.find(({id}) => id === viewingOpcoId));
    }
  }
)

export const getViewingCustomerType = createSelector(
  getViewingOpCoId,
  customersById,
  getCurrentCustomer,
  (viewingOpcoId, customersById, currentCustomer) => {
    if (viewingOpcoId) {
      const opco: ICustomer | undefined = customersById[viewingOpcoId];
      if (!opco && currentCustomer) {
        return getCustomerType(currentCustomer);
      } else if (opco) {
        return getCustomerType(opco);
      }
    } else if (currentCustomer) {
      return getCustomerType(currentCustomer);
    }
  }
)