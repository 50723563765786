import { Button, Group } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import CreateCustomerModal from "admin/components/CreateCustomerModal";
import AdminViewContainer from "admin/components/ViewContainer";
import Tabs from "amp/components/Tabs";

import FindUserModal from "admin/components/FindUserModal";
import './style.css';


const CustomersOutlet = () => {
  const [newCustomerModalOpen, setNewCustomerModalOpen] = useState(false);
  const [findUserModalOpen, setFindUserModalOpen] = useState(false);

  const title = (
    <Group justify="space-between">
      <div>Customers</div>
      <Button className="admin-customers-outlet--btn" onClick={() => setNewCustomerModalOpen(true)}>
        <IconPlus className="admin-customer-create-btn--icon" /> New Customer
      </Button>
    </Group>
  );

  return <AdminViewContainer title={title} isRootView actions={<Group mb={16}>
    <Button onClick={() => setFindUserModalOpen(true)} size="xs" variant="light">Find user</Button>
  </Group>}>
    <Tabs tabs={[
      { title: 'All customers', path: `/admin/customers` },
      { title: 'Favorites', path: `/admin/customers/starred` },
    ]} />
    <Outlet />
    <CreateCustomerModal isOpen={newCustomerModalOpen} onClose={() => setNewCustomerModalOpen(false)}/>
    <FindUserModal isOpen={findUserModalOpen} onClose={() => setFindUserModalOpen(false)}/>
  </AdminViewContainer>
};

export default CustomersOutlet;
