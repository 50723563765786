import { queries, userAuthQueries } from "shared/api/queries";

interface ITableInfo {
  columns: {
    column: string,
    type: string,
  }[],
  indices: {
    definition: string,
    index: string,
  }[]
}

const adminCcdDbQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminListTables: build.query<{data: {size: number, table: string}[]}, void>({
      query: () => '/admin/db/tables',

      providesTags: [{type: 'DbTables', id: 'PAGE'}],
    }),

    adminFetchTableInfo: build.query<{data: ITableInfo}, string>({
      query: (table) => `/admin/db/tables/${table}`,

      providesTags: (res, _, args) => res ? [{type: 'DbTables', id: 'PAGE'}, {type: 'DbTables', id: args}] : [{type: 'DbTables', id: 'PAGE'}],
    }),

    adminRunMigration: build.mutation<{status: string, stdout: string, stderr: string}, void>({
      query: () => ({
        url: '/admin/db/migrate',
        method: 'POST',
      }),

      invalidatesTags: [{type: 'DbTables'}],
    }),

  }),
  overrideExisting: false,
});



const adminUsersDbQueries = userAuthQueries.injectEndpoints({
  endpoints: (build) => ({
    adminListUATables: build.query<{tables: {size: number, table: string}[]}, void>({
      query: () => '/admin/db/tables',

      providesTags: [{type: 'DbTables', id: 'PAGE'}],
    }),

    adminFetchUATableInfo: build.query<{data: ITableInfo}, string>({
      query: (table) => `/admin/db/tables/${table}`,

      providesTags: (res, _, args) => res ? [{type: 'DbTables', id: 'PAGE'}, {type: 'DbTables', id: args}] : [{type: 'DbTables', id: 'PAGE'}],
    }),

    adminRunUAMigration: build.mutation<{status: string, stdout: string, stderr: string}, void>({
      query: () => ({
        url: '/admin/db/run-migrations-as-user',
        method: 'GET',
      }),

      invalidatesTags: [{type: 'DbTables'}],
    }),

  }),
  overrideExisting: false,
});


export const {
  useAdminListTablesQuery,
  useAdminFetchTableInfoQuery,
  useAdminRunMigrationMutation,
} = adminCcdDbQueries;

export const {
  useAdminListUATablesQuery,
  useAdminFetchUATableInfoQuery,
  useAdminRunUAMigrationMutation,
} = adminUsersDbQueries;