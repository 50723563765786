import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, Select } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useAdminPatchUserMutation } from "admin/api/users";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { getCurrentUser } from "shared/store/user/selectors";
import { IUser, UserRole } from "shared/types/user";
import { useAppSelector } from "store";

import './style.css';


const roleOptions = Object.entries(UserRole).map(([label, value]) => ({
  value,
  label
}));


const EditRoleModal = ({
  onClose,
  isOpen,
  user,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, user: IUser}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [patch] = useAdminPatchUserMutation();
  const currentUser = useAppSelector(getCurrentUser);
  const defaultRole = useMemo(() => user.role || UserRole.USER, [user]);
  const [role, setRole] = useState(defaultRole);

  useEffect(() => {
    setRole(defaultRole);
  }, [defaultRole]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!user) {
      return
    }
    try {
      await patch({
        userId: user.id,
        customer_id: user.customer_id,
        role,
        status: user.status,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setRole(UserRole.USER);
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this user's role"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The role of the user</InputLabel>
          <InputDescription>Incorrectly setting this is the most common cause of security incidents in software.</InputDescription>
          <Select data={roleOptions} value={role} onChange={(e) => e && setRole(e as UserRole)} />
          {user.id === currentUser.id && <div className="admin-edit-user-role--warning"><IconAlertCircle color="var(--color-se-red-1)"/>If you lower your role, only another user can elevate you back to your previous role</div>}
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditRoleModal;