
export const normalize = (
  value: number,
  seriesMax: number,
  seriesMin: number,
  ceiling: number,
  floor: number
) => {
  const diff: number = seriesMax - seriesMin;
  const normalized = ((value * (ceiling - floor)) / diff) + floor;
  return normalized;
};

export const consistentFuzz = (
  value: number,
  fuzzSeed: number,
  minFuzzSeed: number,
  maxFuzzSeed: number,
  maxFuzzPct: number, // decimal representing the magnitude, e.g. 0.05 is 5%
  minFuzzPct: number, // decimal representing the magnitude, e.g. 0.05 is 5%
): number => {
  const fuzz = normalize(fuzzSeed, maxFuzzSeed, minFuzzSeed, maxFuzzPct, minFuzzPct);
  return value * (1 + fuzz);
};

// make a human-readable float from a precise binary representation of a float
// choosing 8 as the value because we're multiplying and dividing by no more than 1 million
// frequently in our app
export const hrFloat = (originalFloat: number) => parseFloat(originalFloat.toFixed(8));