import { API } from "shared/api";
import { queries } from "shared/api/queries";
import { AggAssetEventData, AggregationTypes, IListAggregatedAssetEventsResponse } from "shared/types/aggregatedEvents";
import { IListAssetEventsResponse } from "shared/types/assetEvents";

export interface IFetchAggregatedEventsArgs {
  startDate: string
  endDate: string
  resolution: string
  aggregationType: AggregationTypes
  customerId?: string | null
}

interface IFetchAssetEventsArgs {
  startDate: string
  endDate: string
  resolution: string
  eventType: string
  accountId?: string
  customerId?: string | null
}

export const fetchAggregatedAssetEvents = <T=AggAssetEventData>(qs: string) => API.get<IListAggregatedAssetEventsResponse<T>>(`/api/v2/aggregated_events/?${qs}`);

const eventsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    fetchAggregatedEvents: build.query<IListAggregatedAssetEventsResponse, IFetchAggregatedEventsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('start', body.startDate);
        qs.set('end', body.endDate);
        qs.set('resolution', body.resolution);
        qs.set('aggregation_type', body.aggregationType);
        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/aggregated_events/?${qs}`,
          method: 'GET',
        }
      },
    }),

    fetchAssetEvents: build.query<IListAssetEventsResponse, IFetchAssetEventsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('start', body.startDate);
        qs.set('end', body.endDate);
        qs.set('resolution', body.resolution);
        qs.set('event_type', body.eventType);
        if (body.customerId) {
          qs.set('account_id', body.customerId);
        }

        return {
          url: `/v2/asset_events/search?${qs}`,
          method: 'GET',
        }
      }
    }),
  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  useFetchAggregatedEventsQuery,
  useFetchAssetEventsQuery,
} = eventsQueries;
