import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, Select } from "@mantine/core";
import { FormEvent, useEffect, useMemo, useState } from "react";

import { useAdminPatchCustomerMutation } from "admin/api/customers";
import { CustomerType, ICustomer } from "shared/types/customer";

type Option = { value: string, label: string };

const typeOptions: Option[] = Object.entries(CustomerType).map(([label, value]) => ({
  value,
  label
}) as Option).concat([{value: '__NONE__', label: 'Unspecified'}]);


const EditCustomerTypeModal = ({
  onClose,
  isOpen,
  customer,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customer: ICustomer}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [put] = useAdminPatchCustomerMutation();
  const defaultType = useMemo(() => customer.customer_type, [customer]);
  const [ctype, setType] = useState(defaultType === null ? '__NONE__' : customer.customer_type);

  useEffect(() => {
    setType(defaultType);
  }, [defaultType]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customer) {
      return
    }
    try {
      await put({
        customerId: customer.id,
        body: {
          name: customer.name,
          status: customer.status,
          customer_type: ctype === '__NONE__' ? null : ctype as CustomerType,
        },
      }).unwrap();

      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setType(null);
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this customer's type"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The type of the customer</InputLabel>
          <InputDescription>Ask an engineer if you are unsure what the value should be.</InputDescription>
          <Select data={typeOptions} value={ctype} onChange={(e) => e && setType(e as CustomerType)} />
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditCustomerTypeModal;