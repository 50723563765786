import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAggregatedAssetEvents, IFetchAggregatedEventsArgs } from "amp/api/assetEvents";
import { fetchSourcesPage, IListSourcesArgs } from "amp/api/sources";
import { isAxiosError } from "axios";
import { getOpcos } from "shared/store/user/selectors";
import { AggregationTypes, GenerationByFuelAggEventData, IAggregatedAssetEvent, IEmissionsFromGenerationData, ITotalGenerationEventData } from "shared/types/aggregatedEvents";
import { IDashboardPagination, IDashboardPaginationResponse } from "shared/types/api";
import { IGeneratorAssignment } from "shared/types/assignment";
import { IGenerator } from "shared/types/generator";
import { IGenerationSource, isGenerator } from "shared/types/source";
import { IAsyncDataSlice } from "shared/types/store";
import { AppDispatch, RootState } from "store";
import { getViewingOpCoId } from "../ui/selectors";
import { getUtilityInventorySummaryLoading, getUtilitySourcesPageLoading } from "./selectors";

interface IGeneratorSlice {
  byId: Record<string, IGenerator>
  sourceById: Record<string, IGenerationSource>
  assignmentsById: Record<string, IGeneratorAssignment>
  assignmentIdsByGenId: Record<string, string[]>
  sourcesPageResponseByOCI: Record<string, IAsyncDataSlice<string[]>>,
  inventorySummaryResponseByOCI: Record<AggregationTypes, Record<string, IAsyncDataSlice<IAggregatedAssetEvent[]>>>,
}

const initialState: IGeneratorSlice = {
  byId: {},
  sourceById: {},
  assignmentsById: {},
  assignmentIdsByGenId: {},
  sourcesPageResponseByOCI: {},
  inventorySummaryResponseByOCI: {
    [AggregationTypes.EMISSIONS_FROM_GENERATION]: {},
    [AggregationTypes.GENERATION_BY_FUEL]: {},
    [AggregationTypes.TOTAL_GENERATION]: {},
    [AggregationTypes.TOTAL_LOAD]: {},
  },
}


const fetchWithFallback = async <T>(promise: Promise<T>) => {
  const res = await promise;
  if (isAxiosError(res)) {
    return {
      data: {
        data: [],
        meta: {
          pagination: {
            this: 0,
            next: 0,
            prev: 0,
            last: 0,
            first: 1,
            total: 0,
          }
        }
      }
    };
  }
  return res
}


export const fetchUtilitySources = createAsyncThunk<{data?: IDashboardPaginationResponse<IGenerationSource>, oci: string, status: 'SUCCESS' | 'FAILED', serializedArgs: string} | undefined, IListSourcesArgs, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'amp__generators/fetchUtilitySources',
  async ({perPage, page, usState, nameSearch, fuelCategory, assetTypes}, {getState, dispatch}) => {
    const state = getState();
    const opcoId = getViewingOpCoId(state);
    const opcos = getOpcos(state);
    const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
    const oci = customerIds.sort().join(',');
    const sortedAssetTypes = assetTypes?.sort() || [];
    const serializedArgs = JSON.stringify({page, perPage, usState, nameSearch, fuelCategory, assetTypes: sortedAssetTypes});
    dispatch(generatorsSlice.actions.initializeSourcePageForOCI({oci, serializedArgs}));

    if (getUtilitySourcesPageLoading(getState(), oci)) {
      // short circuit
      return undefined;
    }
    dispatch(generatorsSlice.actions.setSourcesPageLoading({oci, isFetching: true}));

    const qs = new URLSearchParams();
    qs.set('page', page.toString());
    qs.set('per_page', perPage.toString());
    if (usState) {
      qs.set('us_state', usState);
    }

    if (fuelCategory) {
      qs.set('fuel_category', fuelCategory);
    }

    if (nameSearch) {
      qs.set('name', nameSearch);
    }

    if (assetTypes) {
      assetTypes.forEach(at => {
        qs.append('asset_type', at);
      });
    }

    try {
      const sourceById: Record<string, IGenerationSource> = {};
      const allPaginations: IDashboardPagination[] = [];
      const sourcesRes = await Promise.all(customerIds.map(cid => fetchWithFallback(fetchSourcesPage(qs.toString() + `&customer_id=${cid}`))));
      sourcesRes.forEach(res => {
        allPaginations.push(res.data.meta.pagination);
        res.data.data.forEach(source => {
          sourceById[source.id] = source;
        });
      });
      const pagination: IDashboardPagination = {
        this: allPaginations[0]?.this || 0,
        next: Math.max(...allPaginations.map(p => p.next || 0)),
        prev: Math.max(...allPaginations.map(p => p.prev || 0)),
        total: Math.max(...allPaginations.map(p => p.total)),
        first: 1,
        last: Math.max(...allPaginations.map(p => p.last)),
      }
      return {data: {data: Object.values(sourceById), meta: {pagination}}, status: 'SUCCESS', oci, serializedArgs};
    } catch (err) {
      return {status: 'FAILED', oci, serializedArgs};
    }
  },
);


type FetchUtilityInventoryResponse = {
  data?: {data: IAggregatedAssetEvent[], pagination: IDashboardPagination},
  aggType: AggregationTypes,
  oci: string,
  status: 'SUCCESS' | 'FAILED',
  serializedArgs: string
};

interface IFetchUtilityInventoryOptions {
  skipSourcesCount: boolean
}

const getAggFn = (aggType: AggregationTypes) => {
  if (aggType === AggregationTypes.GENERATION_BY_FUEL) {
    return (events: IAggregatedAssetEvent[]) => {
      const data: Record<string, ITotalGenerationEventData> = {};
      events.forEach(event => {
        Object.entries(event.data).forEach(([key, value]) => {
          if (key in data) {
            data[key].max_generated_w += value.max_generated_w;
            data[key].min_generated_w += value.min_generated_w;
            data[key].mean_generated_w += value.mean_generated_w;
            data[key].sum_generated_wh += value.sum_generated_wh;
          } else {
            data[key] = {...value};
          }
        })
      })
      return {
        ...events[0],
        data: data as GenerationByFuelAggEventData,
      }
    }
  } else {
    // TODO: this else case is currently only for aggregated emissions data, it should be its own "else if" case
    return (events: IAggregatedAssetEvent[]) => {
      const data: Record<string, number> = {};
      events.forEach(event => {
        Object.entries(event.data).forEach(([key, value]) => {
          if (key in data) {
            data[key] += value;
          } else {
            data[key] = value;
          }
        })
      });
      const asEmissionsData = data as unknown as IEmissionsFromGenerationData;
      const totalMWh = (asEmissionsData.sum_generated_wh || 0) / 1_000_000;
      asEmissionsData.mean_co2_rate_lb_per_mwh = asEmissionsData.sum_co2_mass_lb / (totalMWh || 1);
      asEmissionsData.mean_co2e_rate_lb_per_mwh = asEmissionsData.sum_co2e_mass_lb / (totalMWh || 1);
      asEmissionsData.mean_ch4_rate_lb_per_mwh = asEmissionsData.sum_ch4_mass_lb / (totalMWh || 1);
      asEmissionsData.mean_n2o_rate_lb_per_mwh = asEmissionsData.sum_n2o_mass_lb / (totalMWh || 1);
      return {
        ...events[0],
        data: asEmissionsData,
      }
    }
  }
}

export const fetchUtilityInventorySummary = createAsyncThunk<FetchUtilityInventoryResponse | undefined, IFetchAggregatedEventsArgs & IFetchUtilityInventoryOptions, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'amp__generators/fetchUtilityInventorySummary',
  async ({startDate, endDate, resolution, aggregationType, skipSourcesCount}, {getState, dispatch}) => {
    const state = getState();
    const opcoId = getViewingOpCoId(state);
    const opcos = getOpcos(state);
    const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
    const oci = customerIds.sort().join(',');
    const serializedArgs = JSON.stringify({startDate, endDate, aggregationType, resolution});
    dispatch(generatorsSlice.actions.initializeGenerationSummaryForOCI({oci, aggregationType, serializedArgs}));

    if (getUtilityInventorySummaryLoading(getState(), oci, aggregationType)) {
      // short circuit
      return undefined;
    }
    dispatch(generatorsSlice.actions.setGenerationSummaryLoading({aggregationType, oci, isFetching: true}));

    const qs = new URLSearchParams();
    qs.set('start', startDate);
    qs.set('end', endDate);
    qs.set('resolution', resolution);
    qs.set('aggregation_type', aggregationType);

    const sourcesQs = new URLSearchParams();
    sourcesQs.set('page', '1');
    sourcesQs.set('per_page', '1');

    try {
      const eventsByStartDate: Record<string, IAggregatedAssetEvent[]> = {};
      const invRes = await Promise.all(customerIds.map(cid => fetchAggregatedAssetEvents(qs.toString() + `&customer_id=${cid}`)));
      invRes.forEach(res => {
        res.data.data.forEach(event => {
          if (event.start_date in eventsByStartDate) {
            eventsByStartDate[event.start_date].push(event);
          } else {
            eventsByStartDate[event.start_date] = [event];
          }
        });
      });
      const aggregatedEventsByStartDate: IAggregatedAssetEvent[] = Object
        .values(eventsByStartDate)
        .map(events => getAggFn(aggregationType)(events))
        .sort((x1, x2) => Date.parse(x1.start_date) - Date.parse(x2.start_date));
      const allPaginations: IDashboardPagination[] = [];
      if (!skipSourcesCount) {
        const pageRes = await Promise.all(customerIds.map(cid => fetchSourcesPage(sourcesQs.toString() + `&customer_id=${cid}`)));
        pageRes.forEach(res => allPaginations.push(res.data.meta.pagination));
      }
      const pagination: IDashboardPagination = {
        this: allPaginations[0]?.this || 0,
        next: Math.max(...allPaginations.map(p => p.next || 0)),
        prev: Math.max(...allPaginations.map(p => p.prev || 0)),
        total: Math.max(...allPaginations.map(p => p.total)),
        first: 1,
        last: Math.max(...allPaginations.map(p => p.last)),
      };
      return {data: {data: aggregatedEventsByStartDate, pagination}, status: 'SUCCESS', oci, serializedArgs, aggType: aggregationType};
    } catch (err) {
      return {status: 'FAILED', oci, serializedArgs, aggType: aggregationType};
    }
  },
);


const generatorsSlice = createSlice({
  name: 'amp__generators',
  initialState,
  reducers: {
    receiveGenerators: (state, action: PayloadAction<IGenerator[]>) => {
      action.payload.forEach(gen => {
        state.byId[gen.id] = gen;
      })
    },

    receiveGeneratorAssignments: (state, action: PayloadAction<{assignments: IGeneratorAssignment[], generatorId: string}>) => {
      action.payload.assignments.forEach(assignment => {
        state.assignmentsById[assignment.id] = assignment
      });

      state.assignmentIdsByGenId[action.payload.generatorId] = action.payload.assignments.map(d => d.id);
    },

    setSourcesPageLoading: (state, action: PayloadAction<{oci: string, isFetching: boolean}>) => {
      state.sourcesPageResponseByOCI[action.payload.oci].isFetching = action.payload.isFetching;
    },

    setGenerationSummaryLoading: (state, action: PayloadAction<{aggregationType: AggregationTypes, oci: string, isFetching: boolean}>) => {
      state.inventorySummaryResponseByOCI[action.payload.aggregationType][action.payload.oci].isFetching = action.payload.isFetching;
    },

    initializeSourcePageForOCI: (state, action: PayloadAction<{oci: string, serializedArgs: string}>) => {
      const {
        oci, serializedArgs,
      } = action.payload;
      if (!state.sourcesPageResponseByOCI[oci]) {
        state.sourcesPageResponseByOCI[oci] = {
          data: null,
          lastReceived: null,
          pagination: null,
          isFetching: false,
          fetchFailed: false,
          serializedArgs,
        }
      } else if (state.sourcesPageResponseByOCI[oci].serializedArgs !== serializedArgs) {
        state.sourcesPageResponseByOCI[oci].isFetching = false;
        state.sourcesPageResponseByOCI[oci].serializedArgs = serializedArgs;
      }
    },

    initializeGenerationSummaryForOCI: (state, action: PayloadAction<{aggregationType: AggregationTypes, oci: string, serializedArgs: string}>) => {
      const {
        oci, serializedArgs, aggregationType,
      } = action.payload;
      if (!state.inventorySummaryResponseByOCI[aggregationType][oci]) {
        state.inventorySummaryResponseByOCI[aggregationType][oci] = {
          data: null,
          lastReceived: null,
          pagination: null,
          isFetching: false,
          fetchFailed: false,
          serializedArgs,
        }
      } else if (state.inventorySummaryResponseByOCI[aggregationType][oci].serializedArgs !== serializedArgs) {
        state.inventorySummaryResponseByOCI[aggregationType][oci].isFetching = false;
        state.inventorySummaryResponseByOCI[aggregationType][oci].serializedArgs = serializedArgs;
      }
    },

  },

  extraReducers: (builder) => {
    builder.addCase(fetchUtilitySources.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      const ociState = state.sourcesPageResponseByOCI[action.payload.oci];

      // short circuited because a new request was dispatched
      if (ociState.serializedArgs !== action.payload.serializedArgs) return;

      ociState.isFetching = false;

      if (action.payload.data) {
        const sources = action.payload.data.data;
        ociState.data = sources.map(c => c.id);
        sources.forEach(source => {
          state.sourceById[source.id] = source;
          if (isGenerator(source)) {
            state.byId[source.id] = source;
          }
        });
        ociState.fetchFailed = false;
        ociState.lastReceived = new Date();
        ociState.pagination = action.payload.data.meta.pagination;
      } else {
        ociState.fetchFailed = true;
      }
    });

    builder.addCase(fetchUtilityInventorySummary.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      const ociState = state.inventorySummaryResponseByOCI[action.payload.aggType][action.payload.oci];

      // short circuited because a new request was dispatched
      if (ociState.serializedArgs !== action.payload.serializedArgs) return;

      ociState.isFetching = false;

      if (action.payload.data) {
        ociState.data = action.payload.data.data;
        ociState.pagination = action.payload.data.pagination;
        ociState.fetchFailed = false;
        ociState.lastReceived = new Date();
      } else {
        ociState.fetchFailed = true;
      }
    });
  }
});


export const { receiveGenerators, receiveGeneratorAssignments } = generatorsSlice.actions;
export default generatorsSlice.reducer;