import { IEmissionsFromGenerationData } from "./aggregatedEvents"

export enum AssetEventResolution {
  MINUTES_5 = '5m',
  MINUTES_15 = '15m',
  HOUR = '1h',
  DAY = '1d',
  MONTH = '1M',
  YEAR = '1Y',
}

export interface IUtilityGenerationData {
  sum_generated_wh: number
  min_generated_w: number
  max_generated_w: number
  mean_generated_w: number
}

export interface IEnergyConsumptionData {
  sum_consumed_kwh: number
  min_consumed_kw: number
  max_consumed_kw: number
  mean_consumed_kw: number
}

export interface IAssetEvent {
  dedup_key: string
  event_type: string
  resolution: string
  customer_id: string
  asset_id: string
  start_date: string
  data: IUtilityGenerationData | IEnergyConsumptionData | IEmissionsFromGenerationData
  meta: Record<string, unknown>
}


export interface IListAssetEventsResponse {
  data: IAssetEvent[];
}

export interface IFetchAssetEventResponse {
  asset_event: IAssetEvent;
}
