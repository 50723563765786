import { AppShell, Group, Stack, Text, rem } from '@mantine/core';
import {
  IconChartArea,
  IconChevronRight,
  IconDatabase,
  IconLogout,
  IconRipple,
  IconUsers
} from '@tabler/icons-react';

import { usePrefetch as useGenPrefetch } from 'amp/api/generators';
import { usePrefetch as useProgPrefetch } from 'amp/api/programs';
import { NavLink } from 'react-router-dom';
import { LOGIN_URL } from 'shared/constants/resources';

import { getViewingOpCoId } from 'amp/store/ui/selectors';
import AppPicker from 'shared/components/AppPicker';
import { getCurrentUserRole } from 'shared/store/user/selectors';
import { UserRole } from 'shared/types/user';
import { useAppSelector } from 'store';
import './style.css';

interface NavbarLinkProps {
  icon: typeof IconUsers
  label: string
  href: string
  end?: boolean
  onMouseEnter?: () => void
}

const NavbarLink = ({ icon: Icon, label, href, onMouseEnter }: NavbarLinkProps) => {
  return (
    <NavLink to={href} className="admin-navigation--navigation-link" onMouseEnter={onMouseEnter}>
      <Group>
        <Icon color="white" style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
        <Text c="white">{label}</Text>
      </Group>
      <IconChevronRight color="white" />
    </NavLink>
  );
};

const linkData = [
  {
    label: 'Customers',
    icon: IconUsers,
    href: '/admin/customers',
    uadminRequired: false,
  },
  {
    label: 'Data',
    icon: IconDatabase,
    href: '/admin/data',
    uadminRequired: true,
  },
  {
    label: 'Jobs & tasks',
    icon: IconRipple,
    href: '/admin/async-work',
    uadminRequired: true,
  },
  {
    label: 'Data Viz',
    icon: IconChartArea,
    href: '/admin/viz',
    uadminRequired: false,
  },
];

const Navigation = () => {
  const generatorsPrefetch = useGenPrefetch('paginateGenerators');
  const programsPrefetch = useProgPrefetch('paginatePrograms');
  const oci = useAppSelector(getViewingOpCoId);
  const userRole = useAppSelector(getCurrentUserRole);

  const labelToPrefetch: Record<string, () => void> = {
    Programs: programsPrefetch.bind(undefined, {page: 1, perPage: 20, customerId: oci}),
    Inventory: generatorsPrefetch.bind(undefined, {page: 1, perPage: 10, fuelCategory: null, usState: null, customerId: oci}),
  }

  const links = linkData.filter(({uadminRequired}) => !uadminRequired || userRole === UserRole.UADMIN).map(link => (
    <NavbarLink
      {...link}
      key={link.label}
      onMouseEnter={() => labelToPrefetch[link.label] && labelToPrefetch[link.label]()}
    />
  ));

  return (
    <AppShell.Navbar>
      <nav className="admin-navigation--container">
        <Group justify="space-between" p="xs" w="100%">
          <img src="/images/singularity.svg" width={80} height={40} alt="The Singularity Energy logo" />
          {/* <IconChevronsLeft color='rgb(var(--singularity-light-green-rgb))'/> */}
        </Group>

        <AppPicker />

        <div className="admin-navigation--navigation-links">
          <Stack justify="center" gap="xs">
            {links}
          </Stack>
        </div>

        <Stack justify="center" gap={0} w="100%" p="xs">
          <NavbarLink icon={IconLogout} label="Logout" href={`${LOGIN_URL}/logout`} />
        </Stack>
      </nav>
    </AppShell.Navbar>
  );
};

export default Navigation;
