import { Box, Group, Menu, Pagination, Select, Table, Text, Tooltip } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { IconArchive, IconChevronDown, IconDots } from '@tabler/icons-react';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { usePaginateCustomerReportsQuery } from 'amp/api/reports';
import { useAmpNav } from 'amp/hooks';
import { getViewingCustomerType, getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { CustomPaginationItem } from 'shared/components/Table/baseTable';
import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { getCurrentCustomer, getCurrentCustomerProperty } from 'shared/store/user/selectors';
import { CustomerPropertyTypes, CustomerType } from 'shared/types/customer';
import { CustomerReportStatus, ICustomerReport } from 'shared/types/report';
import { UserRole } from 'shared/types/user';
import { timestampToNumericDate } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import ArchiveReportModal from './archiveReportModal';
import './style.css';

const ReportsTableRow = ({ customerReport }: { customerReport: ICustomerReport }) => {
  const [params] = useSearchParams();
  const customerId = params.get('c');
  const nav = useAmpNav();
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);

  const viewingCustomerType = useAppSelector(getViewingCustomerType);

  const isUtilityUser = useMemo(() => {
    const asType = viewingCustomerType as CustomerType;
    return asType === CustomerType.UTILITY || asType === CustomerType.UTILITY_OPCO;
  }, [viewingCustomerType]);

  // TODO: it would be better for utility/opco users to pull reports for their child customers
  const onTableRowClick = () => {
    if (isUtilityUser) {
      nav({
        pathname: `/dashboard/allocation/${customerReport.config?.allocation_run_id}/customers`,
        search: createSearchParams({ c: customerId || '' }).toString()
      });
    } else {
      nav({
        pathname: `/dashboard/reports/${customerReport.id}`,
        search: createSearchParams({ c: customerId || '' }).toString()
      });
    }
  };

  const onContextMenuClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setContextMenuOpen(!contextMenuOpen);
  }

  const onArchiveClicked = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setArchiveModalOpen(true);
  }

  return (
    <>
      <Table.Tr className="subscription-table--row" onClick={onTableRowClick} key={customerReport.id}>
        <Table.Td>
          <div className="allocation-row--date-range">
            {customerReport.name}
          </div>
        </Table.Td>
        <Table.Td>
          <Tooltip label={customerReport.description || 'no note'}>
            <Text fz={10} c="var(--color-blue-3)" className="allocations-page--note-container">
              {customerReport.description || <em>no note</em>}
            </Text>
          </Tooltip>
        </Table.Td>
        <Table.Td className="subscription-table-cfe--td">
          <Text fz={10} c="var(--color-blue-3)" ta="center">{timestampToNumericDate(customerReport.created_at)}</Text>
        </Table.Td>
        <RoleRequired role={UserRole.ADMIN}>
          <Tooltip label="Actions">
            <Table.Td onClick={onContextMenuClick} p={0}>
              <Menu opened={contextMenuOpen} position="bottom-end" onClose={() => setContextMenuOpen(false)} shadow='sm'>
                <Menu.Target>
                  <Box h="100%" w="100%">
                    <IconDots color="var(--color-blue-3)" size={16} />
                  </Box>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={onArchiveClicked} leftSection={<IconArchive size={16} color="var(--color-blue-3)" />}>
                    Archive
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Table.Td>
          </Tooltip>
        </RoleRequired>
      </Table.Tr>
      <ArchiveReportModal reportId={customerReport.id} isOpen={archiveModalOpen} onClose={() => setArchiveModalOpen(false)} />
    </>
  );
}

// TODO: this page doesn't work as utility user, need a way to fetch cookies for op-cos
const ReportsView = () => {
  const [params, setParams] = useSearchParams();
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const customerId = params.get('c');
  const oci = useAppSelector(getViewingOpCoId);

  const betaMessageProp = useAppSelector(s => getCurrentCustomerProperty(s, 'amp_customer_beta_message'));
  const betaMessage = betaMessageProp?.type === CustomerPropertyTypes.STRING ? betaMessageProp.value as string : null;

  const customer = useAppSelector(getCurrentCustomer);
  const viewingCustomerType = useAppSelector(getViewingCustomerType);
  const customersRes = useUtilityCustomers();
  const customers = customersRes.data || [];
  const sortedCustomers = customers.sort((a, b) => a.name.localeCompare(b.name));

  const reportsRes = usePaginateCustomerReportsQuery(
    { page, perPage: 10, customerId, statuses: [CustomerReportStatus.COMPLETE] },
    { skip: !customerId }
  );
  const reports = reportsRes.data?.data || [];
  const totalItems = reportsRes.data?.meta.pagination.total_items || 0;
  const totalPages = reportsRes.data?.meta.pagination.last || 0;
  const pagination = usePagination({ total: totalPages, page });

  const isUtilityUser = useMemo(() => {
    const asType = viewingCustomerType as CustomerType;
    return asType === CustomerType.UTILITY || asType === CustomerType.UTILITY_OPCO;
  }, [viewingCustomerType]);

  useEffect(() => {
    // if the current user doesn't belong to a utility or OPCO, select the customer for them.
    if (!isUtilityUser) {
      customer && setParams(newParams => {
        newParams.set('c', customer.id);
        return newParams;
      });
    }
  }, [isUtilityUser, customer, setParams]);

  const onSelectCustomer = (custId: string | null) => {
    if (custId) {
      onParamChange('c', custId);
    }
  }

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setParams(newParams => {
      // reset the page when customer changes
      if (paramName !== 'p') {
        newParams.delete('p');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }
      return newParams;
    });
  };

  const onPageChange = (newPage: number) => {
    onParamChange('p', newPage.toString());
  };

  const mustSelectOpCo = viewingCustomerType === CustomerType.UTILITY && !oci;
  return (
    <div className="allocation-reports--page-container">
      <div className="allocation-reports--beta-message">
        {betaMessage || "Please be aware that there may be data issues/bugs in this beta version, and your feedback will be crucial in helping us identify and resolve them before the official release."}
      </div>
      {mustSelectOpCo &&
        <BasePaper className="allocation-reports--reports-table">
          Please select an operating company to begin
        </BasePaper>
      }
      {!mustSelectOpCo && <>
        {isUtilityUser && <div className="allocation-reports--header-controls-container">
          <Select
            value={customerId}
            data={sortedCustomers.map(c => ({ value: c.id, label: c.name }))}
            onChange={onSelectCustomer}
            rightSection={<IconChevronDown size={20} />}
            miw="200px"
            placeholder="Select a customer"
            className="customer-view-consumption-resolution--select"
          />
        </div>}
        {!customerId &&
          <BasePaper className="allocation-reports--reports-table">
            Select a customer to see their reports
          </BasePaper>
        }
        {customerId &&
          <BasePaper titleContent="Reports" className="allocation-reports--reports-table">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <Text size="10px" c="var(--color-blue-2)" fw="600">Name</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Note</Text>
                  </Table.Th>
                  <Table.Th className="subscription-table-cfe--td">
                    <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Created at</Text>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {reports.map(row => <ReportsTableRow key={row.id} customerReport={row} />)}
              </Table.Tbody>
            </Table>
            <div className="base-table--footer-container">
              <Text pos="absolute" left={0} c="var(--color-grey-4)" fz="12px">{totalItems.toLocaleString()} Total rows</Text>
              <Pagination.Root
                className="base-table--pagination-container"
                color="rgb(var(--singularity-green-rgb))"
                total={totalPages}
                value={page}
                onChange={onPageChange}
                ta="center"
              >
                <Group gap={5}>
                  {totalPages !== 0 && <Pagination.Previous />}
                  {pagination.range.map((page, index) => <CustomPaginationItem key={`${page}${index}`} onClick={onPageChange} active={pagination.active === page} page={page} index={index} onMouseEnter={() => { }} />)}
                  {totalPages !== 0 && <Pagination.Next />}
                </Group>
              </Pagination.Root>
            </div>
          </BasePaper>
        }
      </>}
    </div>
  );
}

export default ReportsView;