import { queries } from 'shared/api/queries';
import { IDashboardPaginationResponseV2 } from 'shared/types/api';
import { CustomerReportStatus, ICustomerReport } from 'shared/types/report';


export interface IListCustomerReportsArgs {
  page: number
  perPage: number
  customerId?: string | null
  statuses?: CustomerReportStatus[]
}

export interface IEditCustomerReportPayload {
  reportId: string
  body: {
    status?: CustomerReportStatus
    description?: string
    name?: string
  }
}

const customerReportsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    paginateCustomerReports: build.query<IDashboardPaginationResponseV2<ICustomerReport>, IListCustomerReportsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        if (body.statuses) {
          body.statuses.forEach(status => {
            qs.append('statuses', status);
          })
        }

        return {
          url: `/v2/customer_reports/?${qs}`,
          method: 'GET',
        }
      },
      providesTags: [{ type: 'CustomerReport', id: 'PAGE' }],
    }),

    fetchCustomerReport: build.query<{ customer_report: ICustomerReport }, { id: string, customerId?: string | null }>({
      query: ({ id, customerId }) => `/v2/customer_reports/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
    }),

    patchCustomerReport: build.mutation<{ customer_report: ICustomerReport }, IEditCustomerReportPayload>({
      query: ({ reportId, body }) => ({
        url: `/v2/customer_reports/${reportId}/`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (res) => res ? [{ type: 'CustomerReport', id: 'PAGE' }] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  usePrefetch,
  usePaginateCustomerReportsQuery,
  useFetchCustomerReportQuery,
  usePatchCustomerReportMutation,
} = customerReportsQueries;