import { Button, Checkbox, createTheme, Group, MantineProvider, Modal, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { useReleaseAllocationToCustomersMutation } from 'amp/api/allocationRuns';
import { useUtilityCustomers } from 'shared/store/customers/hooks';

// This is the only way mantine recommends to do this
const checkboxTheme = createTheme({ cursorType: 'pointer' });

const ReleaseCustomerReportModal = ({
  onClose,
  isOpen,
  runId,
}: { onClose: (didSucceed: boolean) => void, isOpen: boolean, runId: string }) => {
  const [allCustomersChecked, setAllCustomersChecked] = useState<boolean>(false);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const [reportDescription, setReportDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [releaseToCustomers] = useReleaseAllocationToCustomersMutation();

  const availableCustomersRes = useUtilityCustomers();
  const availableCustomers = availableCustomersRes?.data || [];

  const onSubmit = () => {
    try {
      setLoading(true);
      releaseToCustomers({
        runId,
        data: {
          release_to_all_customers: allCustomersChecked,
          customer_ids: allCustomersChecked ? undefined : selectedCustomers,
          customer_report_description: reportDescription,
          customer_report_name: reportName,
        },
      }).then(() => {
        notifications.show({
          title: 'Success',
          message: 'Successfully kicked off report release',
          color: "teal",
          icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
        });
        onClose(true);
      });
    } catch (e) {
      setLoading(false);
      notifications.show({
        title: 'Error',
        message: 'Failed your settings preferences',
        icon: <IconX style={{ width: '20px', height: '20px' }} />,
        color: "red",
      });
    }
  }

  const onCloseHandler = (didSucceed = false) => {
    setReportName('');
    setReportDescription('');
    setLoading(false);
    onClose(didSucceed);
  };

  const onCustomerChecked = (customerId: string, checked: boolean) => {
    if (checked) {
      const newList = [...selectedCustomers];
      const idInList = selectedCustomers.find(c => c === customerId);
      if (idInList === undefined) {
        newList.push(customerId);
      }
      setSelectedCustomers(newList);
    } else {
      const updatedCustomers = selectedCustomers.filter(cid => cid !== customerId);
      setSelectedCustomers(updatedCustomers);
    }
  }

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Release Report to Customers"
    >
        <Stack>
          <TextInput label="Report Name" value={reportName} onChange={e => setReportName(e.target.value)}/>
          <TextInput label="Report Description" value={reportDescription} onChange={e => setReportDescription(e.target.value)}/>
          <MantineProvider theme={checkboxTheme}>
            <Checkbox mt={20} label="Release to all customers" checked={allCustomersChecked} onChange={e => setAllCustomersChecked(e.target.checked)} />
          </MantineProvider>
          <Text mt={20} fw={600} fz={18}>Select Individual Customers for Release</Text>
          <div className="allocations-page--release-report-modal-box">
            <Stack>
              {availableCustomers.map(c => <Group justify="space-between" key={c.id}>
                <Text>{c.name}</Text>
                <MantineProvider theme={checkboxTheme}>
                  <Checkbox onChange={e => onCustomerChecked(c.id, e.target.checked)} />
                </MantineProvider>
              </Group>)}
            </Stack>
          </div>
          <Button onClick={onSubmit} disabled={!allCustomersChecked && selectedCustomers.length === 0} loading={loading}>
            RELEASE
          </Button>
        </Stack>
    </Modal>
  );
}

export default ReleaseCustomerReportModal;