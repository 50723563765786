import { Button, Group, Skeleton, Title } from '@mantine/core';
import { IconPencil, IconPlus } from '@tabler/icons-react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { AmpLink } from 'amp/components/Link';
import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import Tabs from 'amp/components/Tabs';
import { useProgram } from 'amp/store/programs/hooks';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { useCustomer } from 'shared/store/customers/hooks';
import { UserRole } from 'shared/types/user';
import { timestampToNumericDate } from 'shared/utils/dates';
import './style.css';


export default function ProgramOutlet() {
  const location = useLocation();
  const { programId = '', subscriptionId = '' } = useParams<{ programId: string, subscriptionId: string }>();

  const progRes = useProgram(programId);
  const program = progRes.data.program;
  const subscription = progRes.data.subscriptions?.find(s => s.id === subscriptionId);

  const subscribedCustomerRes = useCustomer(subscription?.customer_id || '',);
  const subscribedCustomer = subscribedCustomerRes.data;

  const tabs = [
    { title: 'Subscriptions', path: `/dashboard/programs/${programId}` },
    { title: 'Assignments', path: `/dashboard/programs/${programId}/assignments` },
    { title: 'History', path: `/dashboard/programs/${programId}/history` },
  ];

  const isEditPage = location.pathname.endsWith('edit');
  const isCreatePage = location.pathname.endsWith('subscribe');
  const formattedStartDate = timestampToNumericDate(subscription?.data.configuration.subscription_start || '');
  const formattedEndDate = timestampToNumericDate(subscription?.data.configuration.subscription_end || '');
  return (
    <div className="program-outlet--container">
      <div className="program-outlet--pinned-area">
        <PageBreadcrumbs />
        {!isEditPage && !isCreatePage && <>
          <div className="program-outlet--title-container">
            <Skeleton visible={progRes.isLoading} maw={subscriptionId ? 700 : 350}>
              {!subscriptionId && <Title size="24px">
                {program?.name || 'Unknown Program'}
              </Title>}
              {subscriptionId && <Title size="24px">
                {subscribedCustomer?.name || 'Unknown Customer'} {formattedStartDate} - {formattedEndDate}
              </Title>}
            </Skeleton>

            {!subscriptionId && <RoleRequired role={UserRole.ADMIN}>
              <Group>
                <AmpLink to={`/dashboard/programs/${programId}/edit`}>
                  <Button fw={400} size="sm" className="program-outlet--button" leftSection={<IconPencil size={20} />}>
                    Edit Program
                  </Button>
                </AmpLink>
                <AmpLink to={`/dashboard/programs/${programId}/subscribe`}>
                  <Button fw={400} size="sm" className="program-outlet--button" leftSection={<IconPlus size={20} />}>
                    New Subscription
                  </Button>
                </AmpLink>
              </Group>
            </RoleRequired>}
            {subscriptionId && <RoleRequired role={UserRole.ADMIN}>
              <AmpLink to={`/dashboard/programs/${programId}/${subscriptionId}/edit`}>
                <Button fw={400} size="sm" className="program-outlet--button" leftSection={<IconPencil size={20} />}>
                  Edit Subscription
                </Button>
              </AmpLink>
            </RoleRequired>}
          </div>
          {!subscriptionId && <Tabs tabs={tabs} />}
        </>}
      </div>
      <Outlet />
    </div>
  );
}