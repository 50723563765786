import { Button, Group, Modal, Select } from "@mantine/core";
import { useAdminCreateWorkerJobMutation } from "admin/api/workerJobs";
import { FormEvent, useState } from "react";
import CreateAggregateAssetEventJob from "./aggregateAssetEvents";
import CreateCalcGenEmissionsJob from "./calcGenEmissions";


const jobOptions = [
  {
    value: 'run_aggregate_asset_events',
    label: 'Aggregate asset events',
  },
  {
    value: 'calculate_generator_emissions_batch',
    label: 'Calculate generation emissions',
  }
]


const jobOptionToComponent: Record<string, typeof CreateAggregateAssetEventJob> = {
  'run_aggregate_asset_events': CreateAggregateAssetEventJob,
  'calculate_generator_emissions_batch': CreateCalcGenEmissionsJob,
}

const CreateWorkerJobModal = ({
  onClose,
  isOpen,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [create] = useAdminCreateWorkerJobMutation();
  const [selectedJob, setSelectedJob] = useState(jobOptions[0].value);
  const [isStatusValid, setIsStatusValid] = useState(false);
  const [jobStatus, setJobStatus] = useState({});

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    if (!isStatusValid) return;
    setIsLoading(true);
    try {
      await create({
        job_name: selectedJob,
        job_status: jobStatus,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  const Form = jobOptionToComponent[selectedJob];

  const onStatusChange = (newStatus: Record<string, unknown>, isValid: boolean) => {
    setIsStatusValid(isValid);
    setJobStatus(newStatus);
  }

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Start a new worker job"
    >
      <form onSubmit={onSubmit}>
        <Select data={jobOptions} label="Job type" value={selectedJob} onChange={(e) => e && setSelectedJob(e)} />
        <Form onStatusChange={onStatusChange}/>
        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit" disabled={!isStatusValid}>Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default CreateWorkerJobModal;