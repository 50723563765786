import { Tooltip } from "@mantine/core";
import { IconStar, IconStarFilled } from "@tabler/icons-react";
import { MouseEvent, useEffect } from "react";

import { useAdminUpsertUserPropertyMutation } from "admin/api/users";
import { useAmpNav } from "amp/hooks";
import BaseTable from "shared/components/Table/baseTable";
import { getCurrentUser, getFavoriteCustomerIds } from "shared/store/user/selectors";
import { receiveUser } from "shared/store/user/slice";
import { getCustomerType, ICustomer } from "shared/types/customer";
import { UserProperty } from "shared/types/user";
import { useAppDispatch, useAppSelector } from "store";

import './style.css';


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'parentId',
    displayValue: 'Parent ID',
  },
  {
    key: 'customerType',
    displayValue: 'Customer Type',
  },
  {
    key: 'starred',
    displayValue: '',
  }
]

const AdminCustomersTable = ({
  customers,
  isLoading,
  totalPages=0,
  totalItems=0,
  currentPage=0,
  onPageChange=() => undefined
}: {
  customers: ICustomer[],
  isLoading: boolean,
  totalPages?: number,
  totalItems?: number,
  currentPage?: number,
  onPageChange?: (newPageNum: number) => void,
}) => {
  const nav = useAmpNav();
  const customerIds = useAppSelector(getFavoriteCustomerIds);
  const starredCustomerIds = useAppSelector(getFavoriteCustomerIds);
  const user = useAppSelector(getCurrentUser);
  const [patch, patchRes] = useAdminUpsertUserPropertyMutation();
  const dispatch = useAppDispatch();

  const onClickRow = (customerId: string) => {
    nav(`/admin/customers/${customerId}`);
  };

  const addFavoriteCustomer = async (customerId: string) => {
    if (starredCustomerIds.includes(customerId)) {
      return;
    }

    try {
      await patch({
        user_id: user.id,
        property_name: UserProperty.FAVE_CUST_IDS,
        property_namespace: 'SETTINGS',
        property_type: 'JSON',
        property_value: JSON.stringify([customerId, ...starredCustomerIds]),
      }).unwrap();
    } catch (err) {
      // TODO: better alerting
      console.warn("Failed to update favorite customers");
    }
  };

  const removeFavoriteCustomer = async (customerId: string) => {
    if (!starredCustomerIds.includes(customerId)) {
      return;
    }

    try {
      await patch({
        user_id: user.id,
        property_name: UserProperty.FAVE_CUST_IDS,
        property_namespace: 'SETTINGS',
        property_type: 'JSON',
        property_value: JSON.stringify(starredCustomerIds.filter(cid => cid !== customerId)),
      }).unwrap();
    } catch (err) {
      // TODO: better alerting
      console.warn("Failed to update favorite customers");
    }
  };

  useEffect(() => {
    if (patchRes.isSuccess) {
      dispatch(receiveUser(patchRes.data));
    }
  }, [patchRes, dispatch]);


  const getIsCustomerStarred = (customerId: string) => customerIds.includes(customerId);

  return (
    <BaseTable
      rows={customers.map(customer => {
        const onUnfaveClick = (e: MouseEvent, customerId: string) => {
          e.stopPropagation();
          removeFavoriteCustomer(customerId)
        };
        const onFaveClick = (e: MouseEvent, customerId: string) => {
          e.stopPropagation();
          addFavoriteCustomer(customerId);
        };
        const isStarred = getIsCustomerStarred(customer.id);
        return {
          id: customer.id,
          name: customer.name,
          parentId: customer.parent_id || <em>None</em>,
          customerType: getCustomerType(customer) || <em>Unspecified</em>,
          starred: <Tooltip label={isStarred ? 'Remove from favorites' : 'Add to favorites'}>
              {isStarred ? <IconStarFilled size={16} onClick={(e) => onUnfaveClick(e, customer.id)} className="admin-customer-row--star" /> : <IconStar size={16} className="admin-customer-row--star" onClick={(e) => onFaveClick(e, customer.id)} />}
            </Tooltip>,
        }
      })}
      onTableRowClicked={onClickRow}
      isLoading={isLoading}
      columnNames={tableColumns}
      totalPages={totalPages}
      totalItems={totalItems}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />)
};

export default AdminCustomersTable;