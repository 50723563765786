import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IUISlice {
  viewingOpCoId: string | null
}


const initialState: IUISlice = {
  viewingOpCoId: (new URLSearchParams(window.location.search)).get('oci'),
}


const uiSlice = createSlice({
  name: 'amp__ui',
  initialState,
  reducers: {
    setViewingOpco: (state, action: PayloadAction<string | null>) => {
      state.viewingOpCoId = action.payload;
    },
  },
});


export const { setViewingOpco } = uiSlice.actions;
export default uiSlice.reducer;