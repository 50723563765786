import { AppShell } from '@mantine/core';
import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import { ReactNode, useEffect } from 'react';


import Navigation from '../Sidebar';
import './shell.css';


const Shell = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    // inside use effect to prevent errors before module is initialized
    NoData(Highcharts);
  }, []);

  return (
    <>
      <AppShell
        navbar={{ width: 220, breakpoint: 'sm' }}
        padding={0}
      >
        <Navigation />
        <AppShell.Main className="singularity--app-main">
          <div>
            {children}
          </div>
        </AppShell.Main>
      </AppShell>
    </>
  );
};

export default Shell;
