import { Paper, Text } from '@mantine/core';

import { getCurrentCustomerProperty } from 'shared/store/user/selectors';
import { CustomerPropertyTypes } from 'shared/types/customer';
import { useAppSelector } from 'store';

const baseDefaultText = 'Legal disclaimer to be displayed here pending final review';

const CustomerAllocationLegalDisclaimer = ({ defaultText=baseDefaultText } : { defaultText?: string}) => {
  const legalTextProp = useAppSelector(s => getCurrentCustomerProperty(s, 'amp_customer_allocation_legal_disclaimer'));
  const legalText = legalTextProp?.type === CustomerPropertyTypes.STRING ? legalTextProp.value as string : defaultText;

  return (
    <Paper bg="var(--color-grey-0)" p={32} radius={4}>
      <Text fs="italic" style={{ whiteSpace: 'pre-wrap' }} fz={12}>{legalText}</Text>
    </Paper>
  );
}

export default CustomerAllocationLegalDisclaimer;