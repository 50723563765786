import { InputDescription, InputLabel, InputWrapper, Loader, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useLazyAdminFetchCustomerQuery } from "admin/api/customers";
import { useEffect, useState } from "react";


const CreateCalcGenEmissionsJob = ({
  onStatusChange
}: {onStatusChange: (status: Record<string, unknown>, isStatusValid: boolean) => void}) => {
  const [customerId, setCustomerId] = useState('');
  const [doesCustomerExist, setDoesCustomerExist] = useState(false);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [fetchCustomer, fetchCustomerRes] = useLazyAdminFetchCustomerQuery();

  const checkDoesCustomerExist = async () => {
    if (!customerId) {
      if (doesCustomerExist) {
        setDoesCustomerExist(false)
      }
      return
    }

    try {
      await fetchCustomer(customerId).unwrap();
      setDoesCustomerExist(true);
    } catch (err) {
      setDoesCustomerExist(false);
    }
  }

  useEffect(() => {
    const isValid = !!customerId && doesCustomerExist && start.valueOf() <= end.valueOf();

    start.setUTCMinutes(0, 0, 0);
    end.setUTCMinutes(0, 0, 0);

    onStatusChange({
      customer_id: customerId,
      current_dt: null,
      start_dt: start.toISOString(),
      end_dt: end.toISOString(),
    }, isValid);
  }, [customerId, doesCustomerExist, onStatusChange, start, end]);

  return <div>
    <InputWrapper mt={8}>
      <InputLabel>Customer ID</InputLabel>
      <TextInput value={customerId} onChange={(e) => setCustomerId(e.target.value)} onBlur={checkDoesCustomerExist} />
      {fetchCustomerRes.isLoading && <InputDescription><Loader size={8}/> Checking that customer exists</InputDescription>}
      {!fetchCustomerRes.isLoading && doesCustomerExist && <InputDescription><IconCheck color="var(--color-teal-6)" size={8} /> Customer exists</InputDescription>}
      {!fetchCustomerRes.isLoading && !doesCustomerExist && !fetchCustomerRes.isUninitialized && <InputDescription><IconX color="var(--color-se-red-1)" size={8} /> Customer does not exist</InputDescription>}
    </InputWrapper>

    <DateTimePicker
      mt={8}
      label="Start"
      value={start}
      onChange={d => d && setStart(d)}
      w="180px"
    />

    <DateTimePicker
      mt={8}
      label="End"
      value={end}
      onChange={d => d && setEnd(d)}
      w="180px"
    />
  </div>
}

export default CreateCalcGenEmissionsJob;