import { Box, Group, LoadingOverlay, Pill, Select, Skeleton, Stack } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { IconCalendarEvent, IconChevronDown, IconKeyframeAlignHorizontal, IconMapPin } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useBulkFetchGeneratorsQuery } from 'amp/api/generators';
import CustomerSubscriptions from 'amp/components/SubscriptionsTable';
import { useProgram } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';
import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { daysInMonth, daysInYear, getThisYearEnd, getThisYearStart, isDateWithinDateRange, numberOfDaysBetween } from 'shared/utils/dates';
import { snakeToTitle } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';


const resolutionOptions = [
  { label: 'Monthly', value: AssetEventResolution.MONTH },
  { label: 'Yearly', value: AssetEventResolution.YEAR },
];

const SubscriptionPipelineChart = ({ programId }: { programId: string }) => {
  const [params, setParams] = useSearchParams();
  const defaultStart = getThisYearStart();
  defaultStart.setFullYear(defaultStart.getFullYear() - 1);
  const defaultEnd = getThisYearEnd();
  defaultEnd.setFullYear(defaultEnd.getFullYear() + 5);
  const startDateStr = params.get('s') || defaultStart.toISOString();
  const endDateStr = params.get('e') || defaultEnd.toISOString();
  const resolution = params.get('r') || '1Y';
  // TODO: why does this chart keep re-rendering?
  const programRes = useProgram(programId);
  const { subscriptions } = useMemo(() => programRes.data, [programRes]);

  const customersRes = useUtilityCustomers();

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onDateChange = (newDate: Date | null, isStart: boolean) => {
    if (newDate !== null) {
      if (isStart) {
        onParamsChange([{ name: 's', value: newDate.toISOString() }]);
      } else {
        onParamsChange([{ name: 'e', value: newDate.toISOString() }]);
      }
    }
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  // TODO: this function is huge... but the logic is nuanced so I'm leaving it for now
  const pipelineData = useMemo(() => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const subscriptionPipelineData: Record<string, Record<number, number>> = {};
    subscriptions.forEach(sub => {
      if (!sub.data.configuration.subscription_start || !sub.data.configuration.subscription_end) {
        return false; // TODO: need real logic for how to handle null subscription dates
      }
      const subStart = new Date(sub.data.configuration.subscription_start);
      const subEnd = new Date(sub.data.configuration.subscription_end);
      const dedicatedGenTenThousands = sub.data.configuration.percent_generation_dedicated_ten_thousandths;

      if (!subscriptionPipelineData[sub.customer_id]) {
        subscriptionPipelineData[sub.customer_id] = {};
      }

      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      for (let year = startYear; year <= endYear; year++) {
        const yearStart = new Date(year, 0);
        const yearEnd = new Date(year, 11, 31);

        // bail out of years after subscription ends
        if (yearStart > subEnd) {
          return;
        }

        // don't process years for subscriptions in the future
        if (yearEnd < subStart) {
          continue;
        }

        if (resolution === '1M') {
          for (let month = 0; month < 12; month++) {
            const monthStart = new Date(year, month, 1);
            const monthEnd = new Date(year, month + 1, 0, 23, 59, 59); // see: https://stackoverflow.com/questions/222309/calculate-last-day-of-month

            // bail out of months after subscription ends or date range ends
            if (monthStart > subEnd || monthStart > endDate) {
              return;
            }

            // don't process months for subscriptions in the future
            if (monthEnd < subStart) {
              continue;
            }

            if (!subscriptionPipelineData[sub.customer_id][monthStart.getTime()]) {
              subscriptionPipelineData[sub.customer_id][monthStart.getTime()] = 0;
            }

            const isStartInMonth = isDateWithinDateRange(subStart, monthStart, monthEnd);
            const isEndInMonth = isDateWithinDateRange(subEnd, monthStart, monthEnd);
            let percentForMonthTenThousands;
            if (!isStartInMonth && !isEndInMonth) {
              // subscription lasts for the entire month
              percentForMonthTenThousands = dedicatedGenTenThousands;
            } else if (!isEndInMonth) {
              // start is in month but not end
              percentForMonthTenThousands = (numberOfDaysBetween(subStart, monthEnd) / daysInMonth(year, month + 1)) * dedicatedGenTenThousands;
            } else if (!isStartInMonth) {
              // end is in month but not start
              percentForMonthTenThousands = (numberOfDaysBetween(monthStart, subEnd) / daysInMonth(year, month + 1)) * dedicatedGenTenThousands;
            } else {
              // start and end in month
              percentForMonthTenThousands = (numberOfDaysBetween(subStart, subEnd) / daysInMonth(year, month + 1)) * dedicatedGenTenThousands;
            }
            subscriptionPipelineData[sub.customer_id][monthStart.getTime()] += percentForMonthTenThousands / 10_000;
          }
        } else {
          if (!subscriptionPipelineData[sub.customer_id][yearStart.getTime()]) {
            subscriptionPipelineData[sub.customer_id][yearStart.getTime()] = 0;
          }

          const isStartInYear = isDateWithinDateRange(subStart, yearStart, yearEnd);
          const isEndInYear = isDateWithinDateRange(subEnd, yearStart, yearEnd);
          let percentForYear;
          if (!isStartInYear && !isEndInYear) {
            // subscription lasts for the entire year
            percentForYear = dedicatedGenTenThousands;
          } else if (!isEndInYear) {
            // start is in year but not end
            percentForYear = (numberOfDaysBetween(subStart, yearEnd) / daysInYear(year)) * dedicatedGenTenThousands;
          } else if (!isStartInYear) {
            // end is in year but not start
            percentForYear = (numberOfDaysBetween(yearStart, subEnd) / daysInYear(year)) * dedicatedGenTenThousands;
          } else {
            // start and end in year
            percentForYear = (numberOfDaysBetween(subStart, subEnd) / daysInYear(year)) * dedicatedGenTenThousands;
          }
          subscriptionPipelineData[sub.customer_id][yearStart.getTime()] += percentForYear / 10_000;
        }
      }
    });
    return subscriptionPipelineData;
  }, [subscriptions, startDateStr, endDateStr, resolution]);


  const customerNamesById = useMemo(() => {
    const namesById: Record<string, string> = {};
    customersRes.data.forEach(c => namesById[c.id] = c.name);

    return namesById;
  }, [customersRes.data]);


  // TODO: why does this constantly render??
  const subscriptionPipelineOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 180,
      events: {
        load() {
          const max = Math.max(...this.series.map(s => s.dataMax || 0));
          this.yAxis[0].update({ max: Math.max(100, max) });
        }
      }
    },
    lang: {
      noData: 'No subscriptions found, create a subscription to begin',
    },
    // TODO: need like 4 more colors here to be safe
    colors: ['#b1ecdf', '#a6cbea', '#9ba9e7', '#4d7396'],
    yAxis: {
      allowDecimals: false,
      tickInterval: 25,
      labels: {
        format: '{text}%'
      }
    },
    xAxis: {
      dateTimeLabelFormats: {
        millisecond: '%b \'%y',
        second: '%b \'%y',
        minute: '%b \'%y',
        hour: '%b \'%y',
        day: '%b \'%y',
      }
    },
    legend: {
      enabled: true,
      padding: 0,
      maxHeight: 20,
      itemStyle: {
        fontSize: '10px',
      }
    },
    tooltip: {
      valueSuffix: '%',
      valueDecimals: 1,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        minPointLength: 4,
        borderWidth: 0.5,
        pointPadding: 0.3,
        groupPadding: 0,
      },
    },
    series: Object.entries(pipelineData).map(([customerId, percentsByYear]) => {
      return {
        type: 'column',
        name: customerNamesById[customerId] || 'Unknown Customer',
        data: Object.entries(percentsByYear).map(([timestamp, percent]) => {
          const asDate = new Date(parseInt(timestamp));
          return [asDate.getTime(), percent];
        }),
      }
    }),
  }

  return (
    <BasePaper
      className="program-page--subscription-pipeline-container"
      titleContent={<div>Subscription Pipeline</div>}
      actions={
        <Group justify="space-around">
          <Select
            size="xs"
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="120px"
          />
          <MonthPickerInput
            size="xs"
            value={new Date(startDateStr)}
            onChange={d => onDateChange(d, true)}
            rightSection={<IconCalendarEvent size={20} />}
            rightSectionPointerEvents="none"
            numberOfColumns={2}
            valueFormat={'MM/YYYY'}
          />
          <MonthPickerInput
            size="xs"
            value={new Date(endDateStr)}
            onChange={d => onDateChange(d, false)}
            rightSection={<IconCalendarEvent size={20} />}
            rightSectionPointerEvents="none"
            numberOfColumns={2}
            valueFormat={'MM/YYYY'}
          />
        </Group>
      }
    >
      <BaseChart overrideOptions={subscriptionPipelineOptions} />
    </BasePaper>
  );
}

export default function ProgramView() {
  const { programId = '' } = useParams<{ programId: string }>();
  const oci = useAppSelector(getViewingOpCoId);

  const progRes = useProgram(programId);
  const { program } = progRes.data;
  const assignments = progRes.data?.assignments || [];
  const subscriptions = progRes.data?.subscriptions || [];

  const gensRes = useBulkFetchGeneratorsQuery({ ids: assignments.map(a => a.asset_id), customerId: oci });
  const gensWithLocations = gensRes.data?.data.filter(g => !!g.location.us_state) || [];
  const usStates = gensWithLocations.map(g => g.location.us_state);
  const uniqueUsStates = new Set(usStates);

  if (progRes.isLoading || !program) {
    return <div className="program-page--scroll-container">
      <Box pos="relative" w="100%" h="100%">
        <LoadingOverlay visible={true} />
      </Box>
    </div>
  }

  return (
    <div className="program-page--scroll-container">
      <Stack gap="16px">
        <Group gap="16px">
          <BasePaper className="program-page--program-details-container" titleContent={<div>Program Details</div>}>
            <div className="program-page--info-container">
              <div className="program-page--info-text">{`ID: ${program.id}`}</div>
              <Group>
                <Pill bg='var(--color-grey-0)' c="var(--color-blue-1)" radius="sm">
                  {program.data?.program_config?.accounting_period === 'hourly' ? '24/7' : 'Annual'} Program match
                </Pill>
                <Pill bg={program.status !== 'active' ? 'var(--color-neutral-4)' : 'var(--color-teal-2)'} c="var(--color-blue-2)">
                  {snakeToTitle(program.status)}
                </Pill>
              </Group>
              <Group>
                <IconKeyframeAlignHorizontal size="16px" color="var(--color-blue-2)" />
                <div className="program-page--dark-info-text">
                  Temporal Matching Interval: {program.data?.program_config?.accounting_period === 'hourly' ? '1 hour' : 'annual'}
                </div>
              </Group>
              <Skeleton visible={gensRes.isLoading || gensRes.isFetching || !gensRes.data?.data}>
                <Group>
                  <IconMapPin size="16px" color="var(--color-blue-2)" />
                  <div className="program-page--dark-info-text">
                    Geographical Matching: {Array.from(uniqueUsStates).join(', ')}
                  </div>
                </Group>
              </Skeleton>
            </div>
          </BasePaper>
          <SubscriptionPipelineChart programId={programId} />
        </Group>

        <CustomerSubscriptions program={program} subscriptions={subscriptions} titleRow='customer' />
      </Stack>
    </div>
  );
}