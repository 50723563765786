import { Modal } from "@mantine/core";

import { useGenerator } from "amp/store/generators/hooks";
import { useState } from "react";
import BaseTable from "shared/components/Table/baseTable";
import { ICertificate } from "shared/types/certificates";
import { IGenerator, isCfeFuel } from "shared/types/generator";
import { timestampToLongDate, timestampToNumericDate, toNumericDateString } from "shared/utils/dates";
import CertificateDrawer from "../CertificateDrawer";


const makeCertificateFromGeneration = (wh: number, startDate: string, generator: IGenerator): ICertificate => {
  const endDate = new Date(startDate);
  endDate.setMinutes(endDate.getMinutes() + 60); // add 1 hour

  const emissionFactorBackstop = isCfeFuel(generator.meta.fuel_category || '') ? 0 : generator.meta["emissions_backstop_lbs_per_mwh.co2e"];

  return {
    id: `${generator.id}.1h.utility_generation.${startDate}.1`,
    customerId: generator.customer_id,
    ownedBy: null,
    status: 'active',
    assetEventId: `${generator.id}.1h.utility_generation.${startDate}`,
    energyCarrier: 'electricity',
    deviceOpStart: generator.meta.operation_start_date || 'unknown',
    start: timestampToNumericDate(startDate),
    end: toNumericDateString(endDate),
    issuedAt: timestampToNumericDate(startDate),
    issuedLocation: 'unknown',
    productionSource: generator.meta.fuel_category || 'unknown',
    productionTechnology: generator.meta.fuel_technology || 'unknown',
    wattHours: wh,
    gridInjection: generator.location.eia_balancing_authority || 'unknown',
    informsConsumerDisclosure: false,
    device: {
      id: generator.id,
      name: generator.name,
      capacity: generator.meta.capacity_mw || 0,
      capacityUnits: 'mw',
      biddingZone: generator.location.eia_balancing_authority || 'unknown',
      balancingAuthority: generator.location.eia_balancing_authority || 'unknown',
    },
    emissions: Number.isFinite(emissionFactorBackstop) ? {
      emissionFactor: emissionFactorBackstop as number,
      emissionFactorMethodology: 'Singularity Energy: GRETA 0.9.0 (CO2e)',
      emissionFactorUnits: 'lbs_per_mwh',
    } : null,
    value: {
      amount: 250,
      currency: 'usd_cents',
    },
  }
}


const CertificatesModal = ({
  isOpen,
  onClose,
  generatorId,
  certsByHour,
}: {isOpen: boolean, onClose: () => void, generatorId: string, certsByHour: Record<string, number>}) => {

  const generatorRes = useGenerator(generatorId);
  const [selectedHour, setSelectedHour] = useState<string>('');

  return <Modal title="" opened={isOpen} onClose={onClose}>
    {generatorRes.data && <CertificateDrawer isOpen={!!selectedHour} onClose={() => setSelectedHour('')} cert={makeCertificateFromGeneration(certsByHour[selectedHour] || 0, selectedHour, generatorRes.data)}/>}
    <BaseTable
      columnNames={[
        {key: 'hour', displayValue: 'Hour'},
        {key: 'numCerts', displayValue: 'Number of Certificates'},
      ]}
      rows={Object.entries(certsByHour).map(([hour, wh]) => ({
        id: hour,
        hour: timestampToLongDate(hour),
        numCerts: Math.floor(wh / 1_000_000) || '-',
      }))}
      onTableRowClicked={(hour) => setSelectedHour(hour)}
      totalPages={0}
      currentPage={0}
      onPageChange={() => null}
    />
  </Modal>
};

export default CertificatesModal;