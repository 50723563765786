import { Button, Group, Notification, NumberInput, Select, Stack, TextInput, Title, Transition } from '@mantine/core';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { sum } from 'ramda';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchGenerationQuery } from 'amp/api/generators';
import { useCreateSubscriptionMutation } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useProgram } from 'amp/store/programs/hooks';
import { useEditedSubscription, useTotalCommitByEditedSubscription } from 'amp/store/subscriptions/selectors';
import { receiveEditedSubscription } from 'amp/store/subscriptions/slice';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomersPage, useUtilityCustomers } from 'shared/store/customers/hooks';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { CustomerStatus, isUtilityParentCustomer } from 'shared/types/customer';
import { ProgramCommitmentType } from 'shared/types/program';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { getLastYearEnd, getLastYearStart, getThisYearStart, timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiFormat, snakeToTitle } from 'shared/utils/strings';
import { useAppDispatch, useAppSelector } from 'store';
import './style.css';
import SubAccountManagement from './subAccountManagement';


const CreateSubscriptionView = () => {
  const { programId = '' } = useParams<{ programId: string }>();
  const [params] = useSearchParams();
  const navigate = useAmpNav();
  const dispatch = useAppDispatch();
  const [create] = useCreateSubscriptionMutation();
  const oci = useAppSelector(getViewingOpCoId);

  const plusTwoYears = getThisYearStart();
  plusTwoYears.setFullYear(plusTwoYears.getFullYear() + 2);
  const startDateStr = params.get('s') || getThisYearStart().toISOString();
  const endDateStr = params.get('e') || plusTwoYears.toISOString();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const [customerId, setCustomerId] = useState<string | null>(null);
  const [childCustomerId, setChildCustomerId] = useState<string | null>(null);
  const [percentCommitmentTenThousandths, setPercentCommitmentTenThousandths] = useState(1_000_000); // 100% in ten thousandths
  const [submittedState, setSubmittedState] = useState<null | 'fail'>(null);
  const [errorsByField, setErrorsByField] = useState<Record<string, string[]>>({});
  const [isSaving, setIsSaving] = useState(false);

  const programRes = useProgram(programId);
  const program = programRes.data.program;

  const generatorIds = programRes.data.assignments.map(a => a.asset_id) || [];
  const customerIds = programRes.data.assignments.map(a => a.customer_id) || [];
  const genStartDate = getLastYearStart();
  const genEndDate = getLastYearEnd();
  const generationRes = useFetchGenerationQuery({ // relies on monthly events being created for generators
    startDate: genStartDate.toISOString(), endDate: genEndDate.toISOString(), resolution: '1M', generatorIds, customerIds,
  }, { skip: generatorIds.length === 0 });
  const generationData = generationRes.data?.data || [];
  const totalGenerationWh = sum(generationData.map(g => (g.data as IUtilityGenerationData).sum_generated_wh));

  const customersRes = useUtilityCustomers(); // easily covers pilot
  const customers = customersRes.data || [];
  const sortedCustomers = customers.sort((a, b) => a.name.localeCompare(b.name));

  const childCustomersRes = useCustomersPage({ page: 1, perPage: 10, parentCustomerId: customerId, statuses: [CustomerStatus.ACTIVE] });
  const childCustomers = childCustomersRes.data || [];

  // the child components change the sub-account subscription config in the store. read from it here
  const editedSubscription = useAppSelector(useEditedSubscription);
  const editedSubAccountConfig = editedSubscription?.data.configuration.sub_account_to_allocation_percent_ten_thousandths || {};

  const totalCommitFromEditedSubscription = useAppSelector(useTotalCommitByEditedSubscription);

  useEffect(() => {
    // create an "edited Subscription" in state
    // TODO: currently only the sub_account_to_allocation_percent_ten_thousandths are used
    const subscription = {
      id: '',
      retail_program_id: '',
      parent_customer_id: '',
      customer_id: '',
      created_by: '',
      created_at: '',
      meta: {},
      data: {
        configuration: {
          percent_generation_dedicated_ten_thousandths: 0,
          subscription_start: '',
          subscription_end: '',
          sub_account_to_allocation_percent_ten_thousandths: {},
        }
      }
    }
    dispatch(receiveEditedSubscription(subscription));
  }, [program, customerId, dispatch]);

  const onCustomerSelected = (customerId: string | null) => {
    setCustomerId(customerId);
    setChildCustomerId(null);
  };

  const onSubmit = async () => {
    setErrorsByField({});
    setIsSaving(true);
    try {
      await create({
        customerId: oci,
        programId,
        body: {
          // If a child customer has been set use that id instead of the parent
          customer_id: childCustomerId || customerId || '',
          subscription_start: startDate.toISOString(),
          subscription_end: endDate.toISOString(),
          // TODO: don't always save as percent gen once other options fully implemented
          percent_generation_dedicated_ten_thousandths: percentCommitmentTenThousandths,
          sub_account_to_allocation_percent_ten_thousandths: editedSubAccountConfig,
        },
      }).unwrap();
      setIsSaving(false);
      dispatch(receiveEditedSubscription(null));
      navigate(`/dashboard/programs/${programId}`);
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        setErrorsByField(errMsgs);
      }
      setIsSaving(false);
      setSubmittedState('fail');
    } finally {
      setTimeout(() => {
        setSubmittedState(null);
      }, 1800);
    }
  };

  const selectedCustomer = sortedCustomers.find(c => c.id === customerId);
  const selectedCustomerIsParent = isUtilityParentCustomer(selectedCustomer);
  const programCommitType = program?.data.program_config?.commitment_type || ProgramCommitmentType.PERCENT_GENERATION;
  const isTotalPercentExactlyOneHundred = Math.abs(totalCommitFromEditedSubscription - (10_000 * 100)) < 0.0001;
  const isSaveEnabled = isTotalPercentExactlyOneHundred && !!customerId;
  const committedGen = numberToSiFormat(totalGenerationWh * (percentCommitmentTenThousandths / 10_000) / 100);
  const totalGen = numberToSiFormat(totalGenerationWh);
  return (
    <>
      <div className="subscription-create--title-container">
        <Title size="24px">New Subscription</Title>
        <Group>
          <AmpLink to={`/dashboard/programs/${programId}`}>
            <Button className="subscription-create--button" onClick={() => dispatch(receiveEditedSubscription(null))}>
              Cancel
            </Button>
          </AmpLink>
          <Button
            className={`subscription-create--button ${isSaveEnabled ? '' : 'is-disabled'}`}
            onClick={onSubmit}
            disabled={!isSaveEnabled}
            loading={isSaving}
          >
            Save Changes
          </Button>
        </Group>
      </div>
      <div className="subscription-create--scroll-container">
        <Stack gap="16px">
          <BasePaper titleContent="Subscription Details">
            <Group gap={60} align="flex-start">
              <Stack w="calc(50% - 30px)">
                <TextInput
                  disabled
                  label="Subscribed Program"
                  value={program?.name || 'unknown'}
                  rightSection={<IconChevronDown size={16} />}
                />
                <TextInput
                  disabled
                  label="Program Commitment Type"
                  value={snakeToTitle(programCommitType)}
                  rightSection={<IconChevronDown size={16} />}
                />
                <Group align="baseline" justify="space-between">
                  {programCommitType !== ProgramCommitmentType.FIXED_COMMITMENT && <NumberInput
                    error={errorsByField.percent_generation_dedicated_ten_thousandths && errorsByField.percent_generation_dedicated_ten_thousandths[0]}
                    label="Commitment Percent"
                    suffix="%"
                    fixedDecimalScale
                    decimalScale={4}
                    max={100}
                    min={0}
                    value={percentCommitmentTenThousandths / 10_000}
                    onChange={e => setPercentCommitmentTenThousandths(+e * 10_000)}
                    w={150}
                  />}
                  {programCommitType === ProgramCommitmentType.FIXED_COMMITMENT && <NumberInput
                    // TODO errors
                    error={errorsByField.percent_generation_dedicated_ten_thousandths && errorsByField.percent_generation_dedicated_ten_thousandths[0]}
                    label="Commitment Total"
                    suffix=" MWh"
                    min={0}
                    value={percentCommitmentTenThousandths / 10_000}
                    onChange={e => setPercentCommitmentTenThousandths(+e * 10_000)}
                    w={150}
                  />}
                  <TextInput
                    disabled
                    label={`Program Generation ${timestampToNumericDate(genStartDate.toISOString())} - ${timestampToNumericDate(genEndDate.toISOString())}`}
                    value={`${committedGen.value} ${committedGen.unitPrefix}Wh (Total: ${totalGen.value} ${totalGen.unitPrefix}Wh)`}
                    w="calc(100% - 180px)"
                  />
                </Group>
                <Group align="flex-end">
                  <UtcDatePicker
                    value={startDate}
                    error={errorsByField.subscription_start && errorsByField.subscription_start[0]}
                    label="Duration of contract"
                    maxDate={endDate}
                    minDate={new Date(2021, 1, 1)}
                    isStartDate={true}
                  />
                  <UtcDatePicker
                    value={endDate}
                    error={errorsByField.subscription_end && errorsByField.subscription_end[0]}
                    minDate={startDate || new Date(2021, 1, 1)}
                  />
                </Group>
              </Stack>
              <Stack w="calc(50% - 30px)">
                <Select
                  label="Customer"
                  placeholder="Select customer"
                  data={sortedCustomers.map(c => ({ label: c.name, value: c.id }))}
                  value={customerId}
                  onChange={onCustomerSelected}
                />
                <TextInput
                  disabled
                  label="Customer ID"
                  value={customerId || ''}
                />
                {selectedCustomerIsParent && <Select
                  label="Selected customer is a parent, please choose one of its child customers"
                  placeholder="Select child customer"
                  data={childCustomers.map(c => ({ label: c.name, value: c.id }))}
                  value={childCustomerId}
                  onChange={setChildCustomerId}
                />}
              </Stack>
            </Group>
          </BasePaper>

          {customerId && (!selectedCustomerIsParent || childCustomerId) &&
            <SubAccountManagement parentCustomerId={childCustomerId || customerId} />
          }
        </Stack>
      </div>

      <Transition mounted={submittedState === 'fail'}>
        {(transitionStyle) => (
          <Notification
            onClose={() => setSubmittedState(null)}
            title="Error"
            icon={<IconX style={{ width: '20px', height: '20px' }} />}
            color="red"
            className="dashboard-global--notification-container"
            style={transitionStyle}
          >
            We failed to save your changes, please try again
          </Notification>
        )}
      </Transition>
    </>
  )
}

export default CreateSubscriptionView;