import { Button, Group, InputDescription, InputLabel, InputWrapper, Loader, Modal, TextInput } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useAdminPatchUserMutation } from "admin/api/users";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { IUser } from "shared/types/user";

import { useLazyAdminFetchCustomerQuery } from "admin/api/customers";
import './style.css';


const EditCustomerIdModal = ({
  onClose,
  isOpen,
  user,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, user: IUser}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [patch] = useAdminPatchUserMutation();
  const defaultCustomerId = useMemo(() => user.customer_id || '', [user]);
  const [customerId, setCustomerId] = useState(defaultCustomerId);
  const [doesCustomerExist, setDoesCustomerExist] = useState(false);
  const [fetchCustomer, fetchCustomerRes] = useLazyAdminFetchCustomerQuery();

  const checkDoesCustomerExist = async () => {
    if (!customerId) {
      if (doesCustomerExist) {
        setDoesCustomerExist(false)
      }
      return
    }

    try {
      await fetchCustomer(customerId).unwrap();
      setDoesCustomerExist(true);
    } catch (err) {
      setDoesCustomerExist(false);
    }
  }


  useEffect(() => {
    setCustomerId(defaultCustomerId);
  }, [defaultCustomerId]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!user || !doesCustomerExist || fetchCustomerRes.isLoading) {
      return
    }
    try {
      await patch({
        userId: user.id,
        customer_id: customerId,
        role: user.role,
        status: user.status,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setCustomerId('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this user's customer ID"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The customer ID of the user</InputLabel>
          <InputDescription>Incorrectly setting this is the most common cause of security incidents in software.</InputDescription>
          <TextInput value={customerId} onChange={(e) => setCustomerId(e.target.value)} onBlur={checkDoesCustomerExist}/>
          {fetchCustomerRes.isLoading && <InputDescription><Loader size={8}/> Checking that customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && doesCustomerExist && <InputDescription><IconCheck color="var(--color-teal-6)" size={8} /> Customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && !doesCustomerExist && !fetchCustomerRes.isUninitialized && <InputDescription><IconX color="var(--color-se-red-1)" size={8} /> Customer does not exist</InputDescription>}
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} disabled={!doesCustomerExist} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditCustomerIdModal;