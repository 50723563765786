
export interface IGeneratorAssignment {
  id: string
  retail_program_id: string
  asset_id: string
  customer_id: string
  data: {
    configuration: {
      assignment_start?: string | null,
      assignment_end?: string | null,
      percent_generation_dedicated_ten_thousandths: number,
    }
  }
  meta: Record<string, unknown>
}

export interface IFetchAssignmentResponse {
  program_generator: IGeneratorAssignment
}


export const dataToDisplayValues = (assignment: IGeneratorAssignment) => {
  return {
    generatorId: assignment.asset_id,
    start: assignment.data.configuration.assignment_start,
    end: assignment.data.configuration.assignment_end,
    percentGenerationDedicatedTenThousandths: assignment.data.configuration.percent_generation_dedicated_ten_thousandths,
  };
};


export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  return {
    assignment_start: values.start,
    assignment_end: values.end,
    percent_generation_dedicated_ten_thousandths: values.percentGenerationDedicatedTenThousandths,
    generator_id: values.generatorId,
  };
};

export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
    percent_generation_dedicated_ten_thousandths: 'percentGenerationDedicatedTenThousandths',
    assignment_end: 'end',
    assignment_start: 'start',
  };

  Object.entries(errs).forEach(([field, errors]) => {
    if (errors.length && fieldMap[field]) {
      displayErrors[fieldMap[field]] = errors[0];
    }
  });

  return displayErrors;
};

