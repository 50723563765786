import { Button, Tooltip } from "@mantine/core";
import { useAdminListUATablesQuery, useAdminRunUAMigrationMutation } from "admin/api/database";
import { useNavigate } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const DataAuthTables = () => {

  const {data} = useAdminListUATablesQuery();
  const [migrate, migrateRes] = useAdminRunUAMigrationMutation();
  const nav = useNavigate();

  return <div>
    <div>
      <Tooltip label="Run a database migration.">
        <Button onClick={() => migrate()} loading={migrateRes.isLoading} disabled={migrateRes.isLoading}>Migrate</Button>
      </Tooltip>
    </div>
    <BaseTable
      columnNames={[{displayValue: 'Table name', key: 'table'}, {displayValue: 'Row count', key: 'size'}]}
      rows={(data?.tables || []).map((table) => ({id: table.table, table: table.table, size: table.size.toLocaleString()}))}
      onTableRowClicked={(table) => nav(`/admin/data/user-auth/tables/${table}`)}
      totalPages={0}
      currentPage={1}
      onPageChange={() => undefined}
    />
  </div>
};

export default DataAuthTables;