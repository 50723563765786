import { Group, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchWorkerJobQuery } from "admin/api/workerJobs";
import AdminViewContainer from "admin/components/ViewContainer";
import { timestampToLongDate } from "shared/utils/dates";
import './style.css';


const WorkerJobView = () => {
  const { jobId='' } = useParams<{jobId: string}>();
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchWorkerJobQuery(jobId, {skip: !jobId});

  if (isError) {
    return <div>Failed to load job</div>;
  }

  const job = data?.data;

  return <AdminViewContainer title={job?.name || jobId} actions={<div>actions to do things here</div>}>
    {(isLoading || !job) && <Loader />}
    {job && <div>
      <div>
        <Group>
          <div>ID</div>
          <div>{job.id}</div>
        </Group>
        <Group>
          <div>Name</div>
          <div>{job.name}</div>
        </Group>
        <Group>
          <div>Status</div>
          <div>{job.status}</div>
        </Group>
        <Group>
          <div>Data</div>
          <pre>{JSON.stringify(job.data, undefined, 2)}</pre>
        </Group>
        <Group>
          <div>Metadata</div>
          <pre>{JSON.stringify(job.meta, undefined, 2)}</pre>
        </Group>
        <Group>
          <div>Last updated</div>
          <div>{timestampToLongDate(job.updated_at)}</div>
        </Group>
        <Group>
          <div>Creaetd</div>
          <div>{timestampToLongDate(job.created_at)}</div>
        </Group>
      </div>
    </div>}
  </AdminViewContainer>
};

export default WorkerJobView;
