import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISubscription } from 'shared/types/subscription';

// stores a single subscription being edited by the user
interface ISubscriptionSlice {
  editedSubscription: ISubscription | null,
}

const initialState: ISubscriptionSlice = {
  editedSubscription: null,
}


const subscriptionSlice = createSlice({
  name: 'amp__subscriptions',
  initialState,
  reducers: {
    //stores the single instance of a subscription being edited
    receiveEditedSubscription: (state, action: PayloadAction<ISubscription | null>) => {
      state.editedSubscription = action.payload;
    },
  },
});


export const { receiveEditedSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
