import { sum } from 'ramda';
import { useMemo } from 'react';

import BaseChart from 'shared/components/Chart/baseChart';
import { IAllocationRunInputData, IProgramAllocationSummaryResult } from 'shared/types/allocation';
import { ICustomer } from 'shared/types/customer';


const AllocationResultsOverviewCommitVsMatchChart = ({customers, programResults, inputs}: {customers: ICustomer[], programResults: IProgramAllocationSummaryResult | undefined, inputs: IAllocationRunInputData}) => {

  const [generationMatchedMWhByCustomer] = useMemo(() => {
    if (!programResults) {
      return [{}];
    }

    const mWhAllocatedByCustomerId: Record<string, number> = {};
    programResults.hourly_results.forEach(hourResult => {
      Object.values(hourResult.program_allocation_by_subscription_id).forEach(subResult => {
        if (!mWhAllocatedByCustomerId[subResult.customer_id]) {
          mWhAllocatedByCustomerId[subResult.customer_id] = 0;
        }
        mWhAllocatedByCustomerId[subResult.customer_id] += (subResult.allocated_wh / 1_000_000);
      });
    });

    return [mWhAllocatedByCustomerId];
  }, [programResults]);

  const programCommitmentByCustomerId: Record<string, number> = useMemo(() => {
    if (!inputs) {
      return {};
    }

    const allocationPercentByCustomerId: Record<string, number> = {};
    inputs.customer_subscriptions.forEach(sub => {
      if (sub.retail_program_id === programResults?.program_id) {
        if (!allocationPercentByCustomerId[sub.customer_id]) {
          allocationPercentByCustomerId[sub.customer_id] = 0;
        }
        allocationPercentByCustomerId[sub.customer_id] += sub.data.configuration.percent_generation_dedicated_ten_thousandths / 10_000;
      }

    });

    return allocationPercentByCustomerId
  }, [inputs, programResults]);

  const totalGenerationMWh = sum(programResults?.hourly_results.map(r => r.generation_mwh) || []);
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    legend: {
      enabled: false,
      alignColumns: false,
      padding: 0,
      margin: 0,
    },
    plotOptions: {
      column: {
        minPointLength: 4,
        borderWidth: 0.5,
        pointPadding: 0.1,
        groupPadding: 0.3,
      },
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: ' %',
    },
    xAxis: {
      // Highcharts column categories structure means this list must be in the same order as the data in each series
      categories: Object.keys(programCommitmentByCustomerId).map(cid => {
        const customer = customers.find(c => c.id === cid);
        return customer?.name || 'unknown customer';
      }),
    },
    series: [
      {
        name: 'Percent Committed',
        data: Object.values(programCommitmentByCustomerId).map(percentCommit => {
          return { y: percentCommit }
        }),
        type: 'column',
      },
      {
        name: 'Percent Matched',
        data: Object.values(generationMatchedMWhByCustomer).map(totalAssignedMWh => {
          const percentReceived = (totalAssignedMWh / (totalGenerationMWh || 1)) * 100;
          return { y: percentReceived }
        }),
        type: 'column',
      }
    ],
    colors: ["var(--color-green-1)", "#C3FBE9", "var(--color-green-0)", "var(--color-green-3)"]
  };

  return <BaseChart overrideOptions={chartOptions}/>
}

export default AllocationResultsOverviewCommitVsMatchChart;