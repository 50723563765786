import { Button } from "@mantine/core";
import { useAdminPaginateUsersQuery } from "admin/api/users";
import CreateUserModal from "admin/components/CreateUserModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'email',
    displayValue: 'Email',
  },
  {
    key: 'role',
    displayValue: 'Role',
  },
  {
    key: 'status',
    displayValue: 'Status',
  },
]

const CustomerUsersView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setSearchParams] = useSearchParams();
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateUsersQuery({
    page,
    perPage,
    customerId,
  }, {skip: !customerId});

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onClickRow = (userId: string) => {
    nav(`/admin/customers/${customerId}/users/${userId}`);
  };

  const pagination = data?.meta.pagination;
  const users = data?.data || [];

  return <AdminViewContainer title="Users" actions={<Button onClick={() => setCreateUserModalOpen(true)}>Create User</Button>}>
    <CreateUserModal onClose={() => setCreateUserModalOpen(false)} isOpen={createUserModalOpen} customerId={customerId} />
    <BaseTable
      rows={users.map(user => ({
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
        status: user.status,
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total_items || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default CustomerUsersView;