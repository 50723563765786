import { ReactNode } from 'react';

import { getHasGate } from 'shared/store/user/selectors';
import { useAppSelector } from 'store';

const FeatureGate = ({ propertyName, children }: { propertyName: string, children: ReactNode }) => {
  const hasPermission = useAppSelector(s => getHasGate(s, propertyName));

  if (hasPermission) {
    return (
      <>{children}</>
    );
  }

  return (
    <></>
  );
}

export default FeatureGate;