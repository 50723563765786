import { useEffect, useMemo } from "react";

import { getViewingOpCoId } from "amp/store/ui/selectors";
import { IListCustomersArgs, useFetchCustomerQuery, usePaginateCustomersQuery } from "shared/api/customers";
import { CustomerStatus, ICustomer } from "shared/types/customer";
import { useAppDispatch, useAppSelector } from "store";
import { getOpcos } from "../user/selectors";
import { getCustomerById, getCustomersById, getUtilityCustomerPageError, getUtilityCustomerPageLoading, getUtilityCustomers, shouldFetchUtilityCustomerPage } from "./selectors";
import { fetchUtilityCustomers, receiveCustomers } from "./slice";

export const useCustomer = (customerId: string) => {

  const res = useFetchCustomerQuery(customerId, {skip: !customerId});
  const dispatch = useAppDispatch();
  const customer = useAppSelector(s => getCustomerById(s, customerId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveCustomers([res.data.customer]));
    }
  }, [res.data, dispatch, customerId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: customer as ICustomer | undefined,
  }
};

export const useCustomersPage = ({page, perPage, parentCustomerId, statuses=[CustomerStatus.ACTIVE], customerType}: IListCustomersArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateCustomersQuery({page, perPage, parentCustomerId: parentCustomerId || oci, statuses, customerType});
  const dispatch = useAppDispatch();
  const customerIds = useMemo(() => res.data?.data.map(({id}) => id) || [], [res.data]);
  const customers = useAppSelector(s => getCustomersById(s, customerIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveCustomers(res.data.data));
    }
  }, [res.data, dispatch, page, perPage, parentCustomerId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: customers,
    pagination: res.data?.meta.pagination,
  }
};

export const useUtilityCustomers = () => {
  const dispatch = useAppDispatch();
  const opcoId = useAppSelector(getViewingOpCoId);
  const opcos = useAppSelector(getOpcos);

  const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
  const oci = customerIds.sort().join(',');

  const shouldFetch = useAppSelector(s => shouldFetchUtilityCustomerPage(s, oci))

  // This should cover the scope of the pilot
  if (shouldFetch) {
    dispatch(fetchUtilityCustomers({offset: 0, limit: 50, statuses: [CustomerStatus.ACTIVE]}));
  }
  const isLoading = useAppSelector(s => getUtilityCustomerPageLoading(s, oci));
  const isError = useAppSelector(s => getUtilityCustomerPageError(s, oci));
  const data = useAppSelector(s => getUtilityCustomers(s, oci));

  return {
    isLoading,
    isError,
    data,
  }
}