import { createSelector } from 'reselect';
import { nMinutesAgo } from 'shared/utils/dates';
import { RootState } from 'store';

const root = (state: RootState) => state.amp__events;

export const getProjectionsConsumptionResponse = createSelector(
  root,
  (state) => state.projectionsAggregatedLoadResp,
);

export const getProjectionsConsumptionLoading = createSelector(
  getProjectionsConsumptionResponse,
  (projectionsResp) => projectionsResp.isFetching,
);

export const getProjectionsConsumptionError = createSelector(
  getProjectionsConsumptionResponse,
  (projectionsResp) => projectionsResp.fetchFailed,
);

const getProjectionsConsumptionArgs = createSelector(
  getProjectionsConsumptionResponse,
  (projectionsResp) => projectionsResp.serializedArgs,
);

export const shouldFetchProjectionsConsumption = createSelector(
  getProjectionsConsumptionResponse,
  getProjectionsConsumptionArgs,
  (_: RootState, serializedArgs: string) => serializedArgs,
  (aggEventsRes, previousArgs, newArgs) => {
    if (!aggEventsRes) {
      return true;
    }
    const isExpired = aggEventsRes.lastReceived === null || aggEventsRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !aggEventsRes.isFetching && !aggEventsRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
)