import { useAdminPaginateCustomerExternalIdsQuery } from "admin/api/customers";
import AdminViewContainer from "admin/components/ViewContainer";
import { useParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'customer_id',
    displayValue: 'Customer ID',
  },
  {
    key: 'external_id',
    displayValue: 'Ext Acct Id',
  },
  {
    key: 'created',
    displayValue: 'Created',
  },
  {
    key: 'updated',
    displayValue: 'Updated',
  },
  {
    key: 'meta',
    displayValue: 'Metadata',
  },
]

const CustomerExternalIdsView = () => {
  const { customerId='' } = useParams<{customerId: string}>();

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateCustomerExternalIdsQuery(customerId, {skip: !customerId});

  const externalIds = data?.data || [];

  return <AdminViewContainer title="External IDs">
    <BaseTable
      rows={externalIds.map(conn => ({
        id: conn.id,
        external_id: conn.external_id,
        customer_id: conn.customer_id,
        created: conn.created_at,
        updated: conn.updated_at,
        meta: JSON.stringify(conn.meta),
      }))}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={0}
      totalItems={externalIds.length}
      currentPage={0}
      onPageChange={() => undefined}
    />
  </AdminViewContainer>
};

export default CustomerExternalIdsView;