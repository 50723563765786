import { Button, Group, Notification, NumberInput, Skeleton, Stack, TextInput, Title, Transition } from '@mantine/core';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { sum } from 'ramda';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchGenerationQuery } from 'amp/api/generators';
import { useReplaceSubscriptionMutation } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useProgram } from 'amp/store/programs/hooks';
import { useEditedSubscription, useTotalCommitByEditedSubscription } from 'amp/store/subscriptions/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomer } from 'shared/store/customers/hooks';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { ProgramCommitmentType } from 'shared/types/program';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { getLastYearEnd, getLastYearStart, timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiFormat, snakeToTitle } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import SubAccountManagement from '../subscriptionCreate/subAccountManagement';
import './style.css';


const EditSubscriptionView = () => {
  const { programId = '', subscriptionId = '' } = useParams<{ programId: string, subscriptionId: string }>();
  const [params] = useSearchParams();
  const navigate = useAmpNav();
  const oci = useAppSelector(getViewingOpCoId);
  const [edit] = useReplaceSubscriptionMutation();

  const [percentCommitmentTenThousandths, setPercentCommitmentTenThousandths] = useState<number>(0);
  const [submittedState, setSubmittedState] = useState<null | 'fail'>(null);
  const [errorsByField, setErrorsByField] = useState<Record<string, string[]>>({});
  const [isSaving, setIsSaving] = useState(false);

  const programRes = useProgram(programId);
  const program = programRes.data.program;
  const subscription = programRes.data.subscriptions.find(s => s.id === subscriptionId);

  const startDateStr = params.get('s') || subscription?.data.configuration.subscription_start;
  const endDateStr = params.get('e') || subscription?.data.configuration.subscription_end;

  const startDate = startDateStr ? new Date(startDateStr) : null;
  const endDate = endDateStr ? new Date(endDateStr) : null;

  const generatorIds = programRes.data.assignments.map(a => a.asset_id) || [];
  const customerIds = programRes.data.assignments.map(a => a.customer_id) || [];
  const genStartDate = getLastYearStart();
  const genEndDate = getLastYearEnd();
  const generationRes = useFetchGenerationQuery({ // relies on monthly events being created for generators
    startDate: genStartDate.toISOString(), endDate: genEndDate.toISOString(), resolution: '1M', generatorIds, customerIds,
  }, { skip: generatorIds.length === 0 });
  const generationData = generationRes.data?.data || [];
  const totalGenerationWh = sum(generationData.map(g => (g.data as IUtilityGenerationData).sum_generated_wh));

  const subscribedCustomerRes = useCustomer(subscription?.customer_id || '');
  const subscribedCustomer = subscribedCustomerRes.data;

  // the child components change the sub-account subscription config in the store. read from it here
  const editedSubscription = useAppSelector(useEditedSubscription);
  const editedSubAccountConfig = editedSubscription?.data.configuration.sub_account_to_allocation_percent_ten_thousandths || {};
  const totalCommitFromEditedSubscription = useAppSelector(useTotalCommitByEditedSubscription);

  useEffect(() => {
    if (subscription) {
      setPercentCommitmentTenThousandths(subscription.data.configuration.percent_generation_dedicated_ten_thousandths);
    }
  }, [subscription]);

  const onSubmit = async () => {
    setErrorsByField({});
    setIsSaving(true);
    try {
      await edit({
        customerId: oci,
        subscriptionId,
        body: {
          customer_id: subscription?.customer_id || '',
          subscription_start: startDate ? startDate?.toISOString() : undefined,
          subscription_end: endDate ? endDate?.toISOString() : undefined,
          percent_generation_dedicated_ten_thousandths: percentCommitmentTenThousandths,
          sub_account_to_allocation_percent_ten_thousandths: editedSubAccountConfig,
        },
      }).unwrap();
      setIsSaving(false);
      navigate(`/dashboard/programs/${programId}/${subscriptionId}`);
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        setErrorsByField(errMsgs);
      }
      setIsSaving(false);
      setSubmittedState('fail');
    } finally {
      setTimeout(() => {
        setSubmittedState(null);
      }, 1800);
    }
  };

  const isTotalPercentExactlyOneHundred = Math.abs(totalCommitFromEditedSubscription - (10_000 * 100)) < 0.0001;
  const isSaveEnabled = isTotalPercentExactlyOneHundred;
  const programCommitType = program?.data.program_config?.commitment_type || ProgramCommitmentType.PERCENT_GENERATION;
  const committedGen = numberToSiFormat(totalGenerationWh * (percentCommitmentTenThousandths / 10_000) / 100);
  const totalGen = numberToSiFormat(totalGenerationWh);
  return (
    <>
      <div className="subscription-edit--title-container">
        <Skeleton visible={subscribedCustomerRes.isLoading || programRes.isLoading} maw={450}>
          <Title size="24px">{subscribedCustomer?.name || 'Unknown customer'}</Title>
        </Skeleton>
        <Group>
          <AmpLink to={`/dashboard/programs/${programId}/${subscriptionId}`}>
            <Button className="subscription-edit--button">
              Cancel
            </Button>
          </AmpLink>
          <Button
            className={`subscription-edit--button ${isSaveEnabled ? '' : 'is-disabled'}`}
            onClick={onSubmit}
            disabled={!isSaveEnabled}
            loading={isSaving}
          >
            Save Changes
          </Button>
        </Group>
      </div>
      <div className="subscription-edit--scroll-container">
        <BasePaper titleContent="Subscription Details">
          <Group gap={60} align="flex-start">
            <Stack w="calc(50% - 30px)">
              <TextInput
                disabled
                label="Subscribed Program"
                value={program?.name || 'unknown'}
                rightSection={<IconChevronDown size={16} />}
              />
              <TextInput
                disabled
                label="Program Commitment Type"
                value={snakeToTitle(programCommitType)}
                rightSection={<IconChevronDown size={16} />}
              />
              <Group align="baseline" justify="space-between">
                {programCommitType !== ProgramCommitmentType.FIXED_COMMITMENT && <NumberInput
                  error={errorsByField.percent_generation_dedicated_ten_thousandths && errorsByField.percent_generation_dedicated_ten_thousandths[0]}
                  label="Commitment Percent"
                  suffix="%"
                  fixedDecimalScale
                  decimalScale={4}
                  max={100}
                  min={0}
                  value={percentCommitmentTenThousandths / 10_000}
                  onChange={e => setPercentCommitmentTenThousandths(+e * 10_000)}
                  w={150}
                />}
                {programCommitType === ProgramCommitmentType.FIXED_COMMITMENT && <NumberInput
                  // TODO errors
                  error={errorsByField.percent_generation_dedicated_ten_thousandths && errorsByField.percent_generation_dedicated_ten_thousandths[0]}
                  label="Commitment Total"
                  suffix=" MWh"
                  min={0}
                  value={percentCommitmentTenThousandths / 10_000}
                  onChange={e => setPercentCommitmentTenThousandths(+e * 10_000)}
                  w={150}
                />}
                <TextInput
                  disabled
                  label={`Program Generation ${timestampToNumericDate(genStartDate.toISOString())} - ${timestampToNumericDate(genEndDate.toISOString())}`}
                  value={`${committedGen.value} ${committedGen.unitPrefix}Wh (Total: ${totalGen.value} ${totalGen.unitPrefix}Wh)`}
                  w="calc(100% - 180px)"
                />
              </Group>
              <Group align="flex-end">
                <UtcDatePicker
                  value={startDate}
                  isStartDate={true}
                  error={errorsByField.subscription_start && errorsByField.subscription_start[0]}
                  label="Duration of contract"
                  maxDate={endDate || undefined}
                />
                <UtcDatePicker
                  value={endDate}
                  error={errorsByField.subscription_end && errorsByField.subscription_end[0]}
                  minDate={startDate || undefined}
                />
              </Group>
            </Stack>
            <Stack w="calc(50% - 30px)">
              <Skeleton visible={subscribedCustomerRes.isLoading || programRes.isLoading}>
                <TextInput
                  disabled
                  label="Customer"
                  value={subscribedCustomer?.name || 'Unknown customer'}
                  rightSection={<IconChevronDown size={16} />}
                />
              </Skeleton>
              <TextInput
                disabled
                label="Customer ID"
                value={subscription?.customer_id || 'Unknown customer ID'}
              />
            </Stack>
          </Group>
        </BasePaper>
        <SubAccountManagement parentCustomerId={null} />
      </div>

      <Transition mounted={submittedState === 'fail'}>
        {(transitionStyle) => (
          <Notification
            onClose={() => setSubmittedState(null)}
            title="Error"
            icon={<IconX style={{ width: '20px', height: '20px' }} />}
            color="red"
            className="dashboard-global--notification-container"
            style={transitionStyle}
          >
            We failed to save your changes, please try again
          </Notification>
        )}
      </Transition>
    </>
  )
}

export default EditSubscriptionView;