import { Center, Divider, Group, Pill, Skeleton, Stack, Text, Title, Tooltip } from '@mantine/core';
import { IconBolt } from '@tabler/icons-react';
import { SeriesOptionsType } from 'highcharts';
import { sum } from 'ramda';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchAggregatedEventsQuery } from 'amp/api/assetEvents';
import { useFetchForecastForCustomerQuery } from 'amp/api/assetForecasts';
import { useBulkFetchGeneratorsQuery, useFetchGenerationQuery } from 'amp/api/generators';
import { AllocationCookies } from 'amp/outlets/AllocationRuns';
import { useGeneratorsForecast } from 'amp/store/generators/hooks';
import { useProgram } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useFetchStaticDataCookiesQuery } from 'shared/api/users';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { AggregationTypes, ITotalLoadEventData } from 'shared/types/aggregatedEvents';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { getLastYearEnd, getLastYearStart, getThisYearEnd, getThisYearStart, timestampToNumericDate, toNumericDateString } from 'shared/utils/dates';
import { numberToSiFormat } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';


const ProgramSubscriptionView = () => {
  const { programId = '', subscriptionId = '' } = useParams<{ programId: string, subscriptionId: string }>();
  const oci = useAppSelector(getViewingOpCoId);
  const cookiesRes = useFetchStaticDataCookiesQuery(oci);
  const [params] = useSearchParams();

  const programRes = useProgram(programId);
  const { program, subscriptions, assignments } = programRes.data;
  const subscription = subscriptions.find(s => s.id === subscriptionId);

  const subscriptionStart = subscription?.data.configuration.subscription_start;
  const subscriptionEnd = subscription?.data.configuration.subscription_end;
  const startDateStr = params.get('s') || subscriptionStart || getLastYearStart();
  const endDateStr = params.get('e') || subscriptionEnd || getLastYearEnd();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const currentYearLoadRes = useFetchAggregatedEventsQuery({
    startDate: getThisYearStart().toISOString(), endDate: getThisYearEnd().toISOString(), resolution: '1d', aggregationType: AggregationTypes.TOTAL_LOAD, customerId: subscription?.customer_id,
  });
  const loadForecastRes = useFetchForecastForCustomerQuery({
    customerId: oci, forecastCustomerId: subscription?.customer_id || '', year: getThisYearEnd().getFullYear()
  }, { skip: !subscription?.customer_id });
  const loadRes = useFetchAggregatedEventsQuery({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), resolution: '1d', aggregationType: AggregationTypes.TOTAL_LOAD, customerId: subscription?.customer_id,
  });
  const loadData = loadRes.data?.data || [];

  const generatorIds = assignments.map(a => a.asset_id);
  const customerIds = assignments.map(a => a.customer_id);
  // TODO forecasts exist at the opco level, what to do for utility user?
  const currentYearAggGenForecastRes = useGeneratorsForecast(generatorIds, [getThisYearStart().getUTCFullYear()]);
  const currentYearGenerationRes = useFetchGenerationQuery({
    startDate: getThisYearStart().toISOString(), endDate: getThisYearEnd().toISOString(), resolution: '1d', generatorIds, customerIds,
  }, { skip: generatorIds.length === 0 });
  const generationRes = useFetchGenerationQuery({
    startDate: startDate.toISOString(), endDate: endDate.toISOString(), resolution: '1d', generatorIds, customerIds,
  }, { skip: generatorIds.length === 0 });
  const generationData = generationRes.data?.data || [];

  // TODO: this doesn't work as a top level utility user. backend work needed.
  const generatorsRes = useBulkFetchGeneratorsQuery({ ids: generatorIds, customerId: oci }, { skip: !generatorIds.length });

  // YTD data for KPI's, doesn't re-fetch on date changes
  const [loadYtdWh, forecastedLoadYtdWh, generationYtdWh, forecastedGenYtdWh, genForecastData, loadForecastData] = useMemo(() => {
    let loadActualYtdWh = 0;
    let loadForecastYtdWh = 0;
    let genActualYtdWh = 0;
    let genForecastYtdWh = 0;

    const loadData = currentYearLoadRes.data?.data || [];
    loadActualYtdWh = sum(loadData.map(d => (d.data as ITotalLoadEventData).sum_consumed_kwh * 1000));

    const yearLongLoadForecastData = loadForecastRes.data?.event_forecast_batch.data?.results || [];
    const forecastYTD = yearLongLoadForecastData.filter(f => new Date(f.start_date) <= new Date());
    loadForecastYtdWh = sum(forecastYTD.map(d => d.y_axis_value_wh));

    const yearLongGenForecastData = currentYearAggGenForecastRes?.data || [];
    const aggForecastDataYTD = yearLongGenForecastData.filter(({ start_date }) => (new Date(start_date) <= new Date()));
    genForecastYtdWh = sum(aggForecastDataYTD.map(f => f.y_axis_value_wh));

    const genData = currentYearGenerationRes.data?.data || [];
    genActualYtdWh = sum(genData.map(d => (d.data as IUtilityGenerationData).sum_generated_wh));
    return [loadActualYtdWh, loadForecastYtdWh, genActualYtdWh, genForecastYtdWh, yearLongGenForecastData, yearLongLoadForecastData];
  }, [currentYearLoadRes.data, loadForecastRes.data, currentYearGenerationRes.data, currentYearAggGenForecastRes.data]);

  const generationById: Record<string, { x: number, y: number }[]> = {};
  generationData.forEach(ae => {
    const point = {
      x: new Date(ae.start_date).getTime(),
      y: (ae.data as IUtilityGenerationData).sum_generated_wh / 1_000_000_000, // GWh
    };
    if (generationById[ae.asset_id]) {
      generationById[ae.asset_id].push(point);
    } else {
      generationById[ae.asset_id] = [point];
    }
  });

  const forecastGenByDay: Record<number, number> = {};
  genForecastData.forEach(({ start_date, y_axis_value_wh }) => {
    const day = new Date(start_date);
    day.setUTCHours(0, 0, 0, 0);
    const epoch = day.valueOf();
    if (new Date(start_date) >= startDate && new Date(start_date) <= endDate) {
      if (forecastGenByDay[epoch]) {
        forecastGenByDay[epoch] += y_axis_value_wh;
      } else {
        forecastGenByDay[epoch] = y_axis_value_wh;
      }
    }
  });

  const loadChartOptions: Highcharts.Options = {
    legend: {
      enabled: true,
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: 'MWh',
    },
    series: [
      {
        type: 'line',
        name: 'Consumption actual',
        color: '#71b4ff',
        lineWidth: 1,
        data: loadData.map(ae => {
          const asLoadData = ae.data as ITotalLoadEventData;
          return [new Date(ae.start_date).getTime(), asLoadData.sum_consumed_kwh / 1_000]; // MWh
        }),
      }, {
        type: 'line',
        name: 'Consumption projection',
        color: 'var(--color-blue-1)',
        lineWidth: 1,
        dashStyle: 'ShortDash',
        data: loadForecastData.filter(fe => new Date(fe.start_date) >= startDate && new Date(fe.start_date) <= endDate).map(fe => {
          const start = new Date(fe.start_date);
          return [start.getTime(), (fe.y_axis_value_wh / 1_000_000)]; // MWh
        }),
      },
    ],
  };

  const generatorsSeries: SeriesOptionsType[] = Object.entries(generationById).map(([genId, points]) => {
    return {
      type: 'column',
      stacking: 'normal',
      name: generatorsRes.data?.data.find(({ id }) => genId === id)?.name || genId,
      data: points,
    }
  });

  const generationChartOptions: Highcharts.Options = {
    series: [
      {
        type: 'line',
        name: 'Generation projection',
        color: 'var(--color-red-0)',
        lineWidth: 1,
        dashStyle: 'ShortDash',
        data: Object.entries(forecastGenByDay).map(([epoch, valueWh]) => ([parseInt(epoch), valueWh / 1_000_000_000])) || [],
      },
      ...generatorsSeries,
    ],
    legend: {
      enabled: true,
    },
    colors: ['#3f9393', '#62c1ab'],
    tooltip: {
      valueDecimals: 1,
      valueSuffix: 'GWh',
    },
  };

  const subStart = subscription?.data.configuration.subscription_start;
  const subEnd = subscription?.data.configuration.subscription_end;
  const percentVsForecastedLoad = forecastedLoadYtdWh ? loadYtdWh / forecastedLoadYtdWh : 0;
  const percentVsForecastedGen = forecastedGenYtdWh ? generationYtdWh / forecastedGenYtdWh : 0;
  const formattedTotalLoadYtd = numberToSiFormat(loadYtdWh);
  const formattedTotalLoadForecastYtd = numberToSiFormat(forecastedLoadYtdWh);
  const formattedTotalGenerationYtd = numberToSiFormat(generationYtdWh);
  const formattedTotalGenForecastYtd = numberToSiFormat(forecastedGenYtdWh);
  const yearLongGenData = currentYearGenerationRes.data?.data || [];
  const hasYtdGeneration = yearLongGenData.length > 0;
  return (
    <>
      <AllocationCookies cfnCookies={cookiesRes.data}>
        <div className="program-subscription-page--scroll-container">
          <Stack gap="xl">
            <BasePaper
              titleContent={<div>Electricity Generation</div>}
              actions={<Group>
                <UtcDatePicker
                  value={startDate}
                  isStartDate={true}
                  maxDate={endDate || undefined}
                  minDate={new Date(2021, 1, 1)}
                />
                -
                <UtcDatePicker
                  value={endDate}
                  minDate={startDate || new Date(2021, 1, 1)}
                />
              </Group>}
            >
              <Group mb="24px" style={{ justifyContent: 'space-between' }}>
                <Stack gap={4}>
                  <Skeleton visible={programRes.isLoading}>
                    <Title fw="700" c="var(--color-green-2)" size="24px">{program?.name || 'Unknown program'}</Title>
                    <Text c="var(--color-grey-4)" fz="12px">
                      {`${subStart ? timestampToNumericDate(subStart) : 'not specified'} to ${subEnd ? timestampToNumericDate(subEnd) : 'not specified'}`}
                    </Text>
                  </Skeleton>
                </Stack>

                <Stack gap={4}>
                  <Skeleton visible={programRes.isLoading}>
                    <Center>
                      <Group>
                        <IconBolt color="var(--color-green-2)" />
                        {/* TODO: filter these to be "active" ? */}
                        <Title size="32px" c="var(--color-blue-3)">{assignments?.length || 0}</Title>
                      </Group>
                    </Center>
                    <Text c="var(--color-grey-4)" fz="12px">
                      Generators assigned
                    </Text>
                  </Skeleton>
                </Stack>

                <Stack gap={4}>
                  <Skeleton visible={programRes.isLoading}>
                    <Center>
                      <Group>
                        <Title size="32px">
                          {(subscription?.data.configuration.percent_generation_dedicated_ten_thousandths || 0) / 10_000} %
                        </Title>
                      </Group>
                    </Center>
                    <Text c="var(--color-grey-4)" fz="12px">
                      Commitment
                    </Text>
                  </Skeleton>
                </Stack>

                <Divider orientation='vertical' />

                <Stack gap={4}>
                  <Skeleton visible={currentYearLoadRes.isLoading}>
                    <Group gap="xs" align="baseline">
                      <Title size="32px" c="var(--color-blue-1)">{formattedTotalGenForecastYtd.value}</Title>
                      <Text c="var(--color-grey-4)" size="20px">{formattedTotalGenForecastYtd.unitPrefix}Wh</Text>
                    </Group>
                  </Skeleton>
                  <Text c="var(--color-grey-4)" fz="12px">Generation Year-on-year projection YTD</Text>
                </Stack>

                <Stack gap={4}>
                  {!hasYtdGeneration && !currentYearGenerationRes.isLoading && <Text c="var(--color-blue-3)" fw={600} fz={24}>
                    No generation this year
                  </Text>}
                  {hasYtdGeneration && <Skeleton visible={loadForecastRes.isLoading}>
                    <Group gap="xs" align="baseline">
                      <Title size="32px" c="var(--color-black-2)">{formattedTotalGenerationYtd.value}</Title>
                      <Text c="var(--color-grey-4)" size="20px">{formattedTotalGenerationYtd.unitPrefix}Wh</Text>
                    </Group>
                  </Skeleton>}
                  <Skeleton visible={currentYearLoadRes.isLoading || loadForecastRes.isLoading}>
                    <Group w="100%" gap="xs">
                      <Text c="var(--color-grey-4)" fz="12px">Generation Actual YTD</Text>
                      {hasYtdGeneration && <div className="subscription-page-vs-forecast--container">
                        <Pill ml={8} radius="sm" c={percentVsForecastedLoad <= 1 ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={percentVsForecastedLoad <= 1 ? "var(--color-teal-0)" : "var(--color-red-1)"}>
                          {`${percentVsForecastedGen > 1 ? '+' : ''} ${((percentVsForecastedGen - 1) * 100).toFixed(1)} %`}
                        </Pill>
                        <small className="subscription-page-vs-forecast--explanation">vs projection</small>
                      </div>}
                    </Group>
                  </Skeleton>
                </Stack>
              </Group>
              <BaseChart overrideOptions={generationChartOptions} />
            </BasePaper>

            <BasePaper titleContent={<div>Electricity Consumption</div>}>
              <Group mb="24px">
                <Tooltip label={`Total load projected for ${toNumericDateString(getThisYearStart())} - ${toNumericDateString(new Date())}`}>
                  <Stack gap={4} w="250px">
                    <Skeleton visible={currentYearLoadRes.isLoading}>
                      <Group gap="xs" align="baseline">
                        <Title c="var(--color-blue-1)" fz="32px">{formattedTotalLoadForecastYtd.value}</Title>
                        <Text c="var(--color-grey-4)" fz="20px">{formattedTotalLoadForecastYtd.unitPrefix}Wh</Text>
                      </Group>
                    </Skeleton>
                    <Text c="var(--color-grey-4)" fz={12}>Consumption Year-on-year Projection</Text>
                  </Stack>
                </Tooltip>
                <Tooltip label={`Total actual load for ${toNumericDateString(getThisYearStart())} - ${toNumericDateString(new Date())}`}>
                  <Stack gap={4} w="280px">
                    <Skeleton visible={loadForecastRes.isLoading}>
                      <Group gap="xs" align="baseline">
                        <Title c="var(--color-black-2)" fz="32px">{formattedTotalLoadYtd.value}</Title>
                        <Text c="var(--color-grey-4)" size="20px">{formattedTotalLoadYtd.unitPrefix}Wh</Text>
                      </Group>
                    </Skeleton>
                    <Skeleton visible={currentYearLoadRes.isLoading || loadForecastRes.isLoading}>
                      <Group w="100%" gap="xs" pos="relative">
                        <Text c="var(--color-grey-4)" fz={12}>Consumption Actual YTD</Text>
                        <div className="subscription-page-vs-forecast--container">
                          <Pill ml={8} radius="sm" c={percentVsForecastedLoad <= 1 ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={percentVsForecastedLoad <= 1 ? "var(--color-teal-0)" : "var(--color-red-1)"}>
                            {`${percentVsForecastedLoad > 1 ? '+' : ''} ${((percentVsForecastedLoad - 1) * 100).toFixed(1)} %`}
                          </Pill>
                          <small className="subscription-page-vs-forecast--explanation">vs projection</small>
                        </div>
                      </Group>
                    </Skeleton>
                  </Stack>
                </Tooltip>
              </Group>
              <BaseChart overrideOptions={loadChartOptions} />
            </BasePaper>
          </Stack>
        </div>
      </AllocationCookies>
    </>
  );
};


export default ProgramSubscriptionView;