import { Skeleton, Table, Text } from '@mantine/core';
import { sum } from 'ramda';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchReportInputDataQuery, useFetchReportSummaryResultsQuery } from 'amp/api/allocationRuns';
import { useFetchAssetEventsQuery } from 'amp/api/assetEvents';
import { useFetchCustomerReportQuery } from 'amp/api/reports';
import { useAllocationRunInputData, useAllocationRunSummaryOutputData } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import BaseTable from 'shared/components/Table/baseTable';
import { useCustomer } from 'shared/store/customers/hooks';
import { getCurrentUser } from 'shared/store/user/selectors';
import { IEnergyConsumptionData } from 'shared/types/assetEvents';
import { CustomerReport } from 'shared/types/report';
import { timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiFormat } from 'shared/utils/strings';
import { useAppSelector } from 'store';


const SubAccountResultRow = ({ subAccountId, subscriptionId, isReport }: { subAccountId: string, subscriptionId: string, isReport: boolean }) => {
  const { runId = '' } = useParams<{ runId: string }>();
  const allocationStartAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));
  const oci = useAppSelector(getViewingOpCoId);
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const inputRes = useAllocationRunInputData(runId, oci || currentCustomerId);
  const summaryRes = useAllocationRunSummaryOutputData(runId, oci || currentCustomerId);

  // if this page is viewing a report, fetch the data as a report not an allocation
  // TODO: this conditional logic is hard to follow, clean it up
  const reportRes = useFetchCustomerReportQuery({ id: runId, customerId: ''}, {skip: !isReport});
  const report = reportRes.data?.customer_report;
  const repStartAndEnd = {
    start: report?.config?.report_start_date ? new Date(report.config.report_start_date) : null,
    end: report?.config?.report_end_date ? new Date(report.config.report_end_date) : null,
  };
  const locations = report ? new CustomerReport(report).getReportOutputsLocations({}) : null;
  const repSummaryResultsResp = useFetchReportSummaryResultsQuery(
    { summaryLocation: locations?.summary || '' },
    { skip: !isReport || !locations}
  );
  const repInputsDataResp = useFetchReportInputDataQuery(
    { inputLocation: locations?.input || '' },
    { skip: !isReport || !locations}
  );

  const summaryData = isReport ? repSummaryResultsResp.data : summaryRes.data;
  const inputData = isReport ? repInputsDataResp.data : inputRes.data;
  const startAndEnd = isReport ? repStartAndEnd : allocationStartAndEnd;

  const customerRes = useCustomer(subAccountId);

  // TODO: this info (load broken out by sub-account) should be in the allocation results, otherwise results might change over time
  const consumptionRes = useFetchAssetEventsQuery({
    startDate: startAndEnd?.start?.toISOString() || '', // TODO default?
    endDate: startAndEnd?.end?.toISOString() || '',
    resolution: '1d',
    eventType: 'metered_consumption',
    customerId: subAccountId,
  });

  const [subscribedProgram, subscription] = useMemo(() => {
    if (inputData) {
      const subscription = inputData.customer_subscriptions.find(sub => sub.id === subscriptionId);
      const program = inputData.programs.find(p => p.id === subscription?.retail_program_id || '');
      return [program, subscription];
    } else {
      return [null, null];
    }
  }, [inputData, subscriptionId]);

  const [programGenerationMWh, programAllocationMWh] = useMemo(() => {
    if (summaryData) {
      const subResults = summaryData.summary_results.by_subscription_id[subscriptionId];
      return [subResults.program_generation_mwh, subResults.allocated_generation_mwh];
    } else {
      return [null, null];
    }
  }, [summaryData, subscriptionId]);

  const totalConsumptionKWh = useMemo(() => {
    if (consumptionRes.data) {
      return sum(consumptionRes.data.data.map(ae => (ae.data as IEnergyConsumptionData).sum_consumed_kwh));
    } else {
      return null;
    }
  }, [consumptionRes.data]);

  const subStart = subscription?.data.configuration.subscription_start;
  const subEnd = subscription?.data.configuration.subscription_end;
  const subActCommitment = (subscription?.data.configuration.sub_account_to_allocation_percent_ten_thousandths?.[subAccountId] || 0) / 10_000;
  const generationMatchedMWh = programAllocationMWh ? programAllocationMWh * (subActCommitment / 100) : 0;
  const formattedProgramGen = numberToSiFormat((programGenerationMWh || 0) * 1_000_000);
  const formattedGenMatched = numberToSiFormat(generationMatchedMWh * 1_000_000);
  const formattedTotalConsumption = numberToSiFormat((totalConsumptionKWh || 0) * 1_000);
  const percentLoadMatched = totalConsumptionKWh ? Math.min((generationMatchedMWh * 1_000) / totalConsumptionKWh, 1) : 0;
  return (
    <Table.Tr>
      <Table.Td>
        <Skeleton visible={customerRes.isLoading}>
          <Text fz={12}>{customerRes.data?.name || 'Unknown account'}</Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="center">
        <Skeleton visible={inputRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">{subscribedProgram?.name || 'Unknown program'}</Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="center">
        <Skeleton visible={inputRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">
            {(subStart && subEnd) ? `${timestampToNumericDate(subStart)} - ${timestampToNumericDate(subEnd)}` : 'No range'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="center">
        <Skeleton visible={summaryRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">
            {programGenerationMWh !== null ? `${formattedProgramGen.value} ${formattedProgramGen.unitPrefix}Wh` : 'unknown'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="center">
        <Skeleton visible={inputRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">{`${subActCommitment.toFixed(1)} %`}</Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="center">
        <Skeleton visible={summaryRes.isLoading || inputRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">
            {programAllocationMWh !== null ? `${formattedGenMatched.value} ${formattedGenMatched.unitPrefix}Wh` : 'unknown'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="center">
        <Skeleton visible={consumptionRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">
            {totalConsumptionKWh !== null ? `${formattedTotalConsumption.value} ${formattedTotalConsumption.unitPrefix}Wh` : 'unknown'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td ta="right">
        <Skeleton visible={consumptionRes.isLoading || summaryRes.isLoading}>
          <Text fz={10} c="var(--color-blue-3)">{(percentLoadMatched * 100).toFixed(1)} %</Text>
        </Skeleton>
      </Table.Td>
    </Table.Tr>
  )
}


const subAccountTableColumns = [
  { key: 'name', displayValue: 'Sub-account Name' },
  { key: 'program', displayValue: 'Program' },
  // TODO: how do we handle this?
  // { key: 'subscription', displayValue: 'Subscription' },
  { key: 'subscriptionDuration', displayValue: 'Duration of contract' },
  { key: 'programGeneration', displayValue: 'Program Generation' },
  { key: 'commitmentPercent', displayValue: 'Commitment' },
  { key: 'generationMatched', displayValue: 'Generation Matched' },
  { key: 'consumption', displayValue: 'Subscriber Consumption' },
  { key: 'percentLoadMatched', displayValue: '% Load Match' },
];

const SubAccountResultsTable = ({ isReport = false }: { isReport: boolean }) => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params, setParams] = useSearchParams();
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const customerId = params.get('c');

  const oci = useAppSelector(getViewingOpCoId);
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;

  const inputRes = useAllocationRunInputData(runId, oci || currentCustomerId);

  // if this page is viewing a report, fetch the data as a report not an allocation
  // TODO: this conditional logic is hard to follow, clean it up
  const reportRes = useFetchCustomerReportQuery({ id: runId, customerId: ''}, {skip: !isReport});
  const report = reportRes.data?.customer_report;
  const locations = report ? new CustomerReport(report).getReportOutputsLocations({}) : null;
  const repInputsDataResp = useFetchReportInputDataQuery(
    { inputLocation: locations?.input || '' },
    { skip: !isReport || !locations}
  );

  const inputData = isReport ? repInputsDataResp.data : inputRes.data;

  const subAccountRowList = useMemo(() => {
    const subAccountRows: { subActId: string, subscriptionId: string }[] = [];
    if (inputData) {
      inputData.customer_subscriptions.forEach(subscription => {
        if (subscription.customer_id !== customerId) {
          return;
        }

        // TODO: should we handle the case where this is null? the frontend creates all subscription with this object, not enforced on backend
        if (subscription.data.configuration.sub_account_to_allocation_percent_ten_thousandths) {
          Object.keys(subscription.data.configuration.sub_account_to_allocation_percent_ten_thousandths).forEach(subActId => {
            subAccountRows.push({ subActId, subscriptionId: subscription.id });
          });
        }
      });
    }

    return subAccountRows;
  }, [inputData, customerId]);

  const onPageChange = (newPage: number) => {
    setParams((params) => {
      params.set('p', newPage.toString());
      return params;
    });
  };

  // Page size is always 5, end index is not inclusive
  const startIdx = (page - 1) * 5;
  const endIdx = startIdx + 5;
  const currentSubAccountRows = subAccountRowList.filter((row, idx) => idx >= startIdx && idx < endIdx);
  return (
    <BasePaper titleContent="Subscribed Sub-accounts">
      <BaseTable
        rows={currentSubAccountRows.map(rowInfo => (
          <SubAccountResultRow
            key={`${rowInfo.subActId}_${rowInfo.subscriptionId}`}
            subAccountId={rowInfo.subActId}
            subscriptionId={rowInfo.subscriptionId}
            isReport={isReport} />
        ))}
        columnNames={subAccountTableColumns}
        totalPages={Math.ceil(subAccountRowList.length / 5)}
        currentPage={page}
        onPageChange={onPageChange}
      />
    </BasePaper>
  );
}

export default SubAccountResultsTable;