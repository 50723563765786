import { Skeleton, Title } from '@mantine/core';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchCustomerReportQuery } from 'amp/api/reports';
import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import AllocationResultsCustomer from 'amp/views/allocationResultsCustomer';
import { getCurrentCustomerProperty } from 'shared/store/user/selectors';
import { CustomerPropertyTypes } from 'shared/types/customer';
import { timestampToLongDate } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';

const ReportsCustomerDashboard = () => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params,] = useSearchParams();
  const customerId = params.get('c');

  const betaMessageProp = useAppSelector(s => getCurrentCustomerProperty(s, 'amp_customer_beta_message'));
  const betaMessage = betaMessageProp?.type === CustomerPropertyTypes.STRING ? betaMessageProp.value as string : null;

  const reportRes = useFetchCustomerReportQuery({ id: runId, customerId });
  const report = reportRes.data?.customer_report;

  const startDateString = report?.config.report_start_date ? timestampToLongDate(report.config.report_start_date) : 'unknown date';
  const endDateString = report?.config.report_end_date ? timestampToLongDate(report.config.report_end_date) : 'unknown date';
  const nameString = `Report for ${startDateString} - ${endDateString}`;

  return (
    <div>
      <div className="report-header--container">
        <PageBreadcrumbs />
        <Skeleton visible={reportRes.isFetching || reportRes.isLoading}>
          <Title size="24px" mt="16px" mb="16px">{nameString}</Title>
        </Skeleton>
        <div className="report-header--beta-message">
          {betaMessage || "Please be aware that there may be data issues/bugs in this beta version, and your feedback will be crucial in helping us identify and resolve them before the official release."}
        </div>
      </div>
      {/* TODO: probably shouldn't render a view from a view */}
      <AllocationResultsCustomer isReport />
    </div>
  );
}

export default ReportsCustomerDashboard;