import { getViewingCustomerType } from 'amp/store/ui/selectors';
import { Fragment, ReactNode } from 'react';

import { CustomerType } from 'shared/types/customer';
import { useAppSelector } from 'store';

const CustomerTypeRequired = ({ allowedTypes, children }: { allowedTypes: CustomerType[], children: ReactNode }) => {
  const customerType = useAppSelector(getViewingCustomerType);
  const hasPermission = customerType ? allowedTypes.includes(customerType as CustomerType) : false;

  if (hasPermission) {
    return <>{children}</>;
  }

  return <Fragment />;
}

export default CustomerTypeRequired;