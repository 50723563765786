import { Box, Group, LoadingOverlay, Pill, Select, Skeleton, Stack, Title } from '@mantine/core';
import { IconArrowRight, IconCalendar, IconChevronDown, IconRefresh, IconUserUp } from '@tabler/icons-react';
import { Map, Marker } from 'mapbox-gl';
import { mean, sum } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchAggregatedEventsQuery } from 'amp/api/assetEvents';
import { useFetchForecastForCustomerQuery } from 'amp/api/assetForecasts';
import { usePaginateSubscriptionsQuery } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { getAsDOMElement } from 'amp/components/MapIcons/small';
import CustomerSubscriptions from 'amp/components/SubscriptionsTable';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { useCustomer, useCustomersPage } from 'shared/store/customers/hooks';
import { getOpcos } from 'shared/store/user/selectors';
import { AggregationTypes } from 'shared/types/aggregatedEvents';
import { AssetEventResolution, IEnergyConsumptionData } from 'shared/types/assetEvents';
import { CustomerStatus, ICustomer, getCustomerLogoPath } from 'shared/types/customer';
import { getThisYearEnd, getThisYearStart, timestampToNumericDate } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';


const resolutionOptions = [
  { label: 'Daily', value: AssetEventResolution.DAY },
  { label: 'Monthly', value: AssetEventResolution.MONTH },
  // { label: 'Yearly', value: AssetEventResolution.YEAR },
];

const CustomerDetails = ({ customer }: { customer: ICustomer }) => {
  const opcos = useAppSelector(getOpcos);
  const hasParentCustomer = !opcos.find(o => o.id === customer.parent_id);
  const parentCustomerRes = useCustomer(customer.parent_id || '');
  const loadingParent = parentCustomerRes.isLoading || parentCustomerRes.isFetching;

  return (
    <BasePaper titleContent={<div>Customer Details</div>}>
      <div className="paper-card--body">
        <div className="customer-details--logo">
          <img className="allocation-results--logo" src={getCustomerLogoPath(customer)} width={167} alt={`${customer.name} logo`} />
        </div>
        {hasParentCustomer && <>
          {loadingParent && <Skeleton visible={true} h={16} w={140} />}
          {!loadingParent && <div className="customer-details--row">
            <IconUserUp size="16" />
            <div>Parent Customer: <AmpLink to={`/dashboard/customers/${customer.parent_id}`}>{parentCustomerRes.data?.name || 'unknown customer'}</AmpLink></div>
          </div>}
        </>}
        <div className="customer-details--row">
          <IconRefresh size="16" />
          <div>Customer ID: {parseInt(customer.id, 36)}</div>
        </div>
        <div className="customer-details--row">
          <IconCalendar size="16" />
          <div>Date created: {timestampToNumericDate(customer.created_at)}</div>
        </div>
        <div className="customer-details--row">
          <IconRefresh size="16" />
          <div>Date updated: {timestampToNumericDate(customer.updated_at)}</div>
        </div>
      </div>
    </BasePaper>
  );
};

const SubAccounts = ({ customer }: { customer: ICustomer }) => {
  const subCustRes = useCustomersPage({ page: 1, perPage: 10, parentCustomerId: customer.id, statuses: [CustomerStatus.ACTIVE] })
  const map = useRef<Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'customer-page--map-container',
        attributionControl: false,
        interactive: false,
        center: [-83, 33], // [lng, lat] is expected
        zoom: 4.3,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });
      setMapLoaded(true)
    }
  }, [setMapLoaded]);

  useEffect(() => {
    const mapVal = map.current;
    const accounts = subCustRes.data;

    if (mapLoaded && accounts && mapVal) {
      const lngs: number[] = [];
      const lats: number[] = [];
      accounts.forEach(account => {
        const latLng = (account.attributes.find(({ name }) => name === 'location_lat_lng')?.value) as number[]
        if (latLng) {
          const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
          const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
          lngs.push(lng);
          lats.push(lat);
          new Marker(getAsDOMElement()).setLngLat([lng, lat]).addTo(mapVal);
        }
      });

      if (lngs.length && lats.length) {
        mapVal.setCenter([mean(lngs), mean(lats)])
      }
    }
  }, [subCustRes.data, mapLoaded]);


  return (
    <BasePaper titleContent={<div>Sub-accounts</div>}>
      <div className="paper-card--body">
        <Group gap={0}>
          <Box w="280px" h="138px">
            <div id="customer-page--map-container" />
          </Box>
          <Stack w="15vw" gap={0} ml={32} className="customer-sub-accounts--body">
            <div>
              <Title fz={32}>{subCustRes.pagination?.total_items}</Title>
              <div color="var(--color-grey-4)">Total sub-accounts</div>
            </div>
            <AmpLink to="accounts" className="customer-view-sub-accounts--link">See sub-accounts <IconArrowRight size={14} /></AmpLink>
          </Stack>
        </Group>
      </div>
    </BasePaper>
  )
};

const CustomerEnergyConsumption = ({ customer }: { customer: ICustomer }) => {
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const startDateStr = params.get('s') || getThisYearStart().toISOString();
  const endDateStr = params.get('e') || getThisYearEnd().toISOString();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const resolution = params.get('r') || '1d';

  const forecastRes = useFetchForecastForCustomerQuery({
    customerId: oci,
    forecastCustomerId: customer.id,
    year: getThisYearEnd().getFullYear(),
  });

  const consumptionRes = useFetchAggregatedEventsQuery({
    customerId: customer.id,
    startDate: startDate?.toISOString() || getThisYearStart().toISOString(),
    endDate: endDate?.toISOString() || getThisYearEnd().toISOString(),
    resolution,
    aggregationType: AggregationTypes.TOTAL_LOAD,
  }, { skip: !startDate || !endDate });

  const forecastData = forecastRes.data?.event_forecast_batch.data?.results.filter(({ start_date }) => new Date(start_date) >= startDate && new Date(start_date) <= endDate);
  const consumptionData = consumptionRes.data?.data;

  const [consumptionSeriesData, totalConsumptionMwh] = useMemo(() => {
    if (!consumptionData) return [];

    const seriesData: number[][] = [];
    let totalKwh = 0;

    consumptionData.forEach(event => {
      const eventData = event.data as IEnergyConsumptionData;
      seriesData.push([new Date(event.start_date).valueOf(), Math.round(eventData.sum_consumed_kwh / 10) / 100])
      totalKwh += eventData.sum_consumed_kwh;
    })

    return [seriesData, Math.round(totalKwh / 1_000)];
  }, [consumptionData]);


  const [forecastSeriesData, totalForecastKwh] = useMemo(() => {
    if (!forecastData) return [];

    const seriesData: number[][] = [];
    let totalWh = 0;

    forecastData.forEach(event => {
      const date = new Date(event.start_date);
      // using the Math.round(x / 10_000) to get the nearest 2 digits behind the decimal for MWh
      seriesData.push([date.valueOf(), (Math.round(event.y_axis_value_wh / 10_000) / 100) * 1.05])
      totalWh += event.y_axis_value_wh;
    })

    return [seriesData.sort((x1, x2) => x1[0] - x2[0]), Math.round(totalWh / 1_000_000)];
  }, [forecastData]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const consumptionChartOptions: Highcharts.Options = {
    series: [
      {
        name: 'Consumption actual',
        data: consumptionSeriesData,
        type: 'area',
        color: '#72B3FF',
        lineWidth: 1,
      },
      {
        name: 'Consumption projection',
        data: forecastSeriesData,
        type: 'line',
        color: 'var(--color-blue-1)',
        dashStyle: 'LongDash',
        lineWidth: 1,
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0.25, 'rgba(202, 219, 244, 0.5)'], // start
            [1, 'rgba(202, 219, 244, 0)'] // end
          ]
        },
      },
    },

    yAxis: [
      {
        title: {
          text: 'Electricity consumption (MWh)',
          useHTML: true,
        }
      }
    ]
  };

  const isLoading = forecastRes.isLoading || consumptionRes.isLoading;

  const maxEpochForLoad = Math.max(...((consumptionData || []).map(d => new Date(d.start_date).valueOf()) || []));
  const totalForecastYTD = sum((forecastData || []).filter(({ start_date }) => new Date(start_date).valueOf() <= maxEpochForLoad).map(d => d.y_axis_value_wh));
  const percentVsForecasted = totalForecastYTD ? ((totalConsumptionMwh || 0) * 1_000_000) / totalForecastYTD : 0;

  return (
    <BasePaper
      titleContent={<div>Energy Consumption</div>}
      actions={
        <Group ml="lg" mr="lg" fw="400">
          <Select
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="120px"
            className="customer-view-consumption-resolution--select"
          />
          <UtcDatePicker
            value={startDate}
            isStartDate={true}
            maxDate={endDate}
            minDate={new Date(2021, 1, 1)}
          />
          -
          <UtcDatePicker
            value={endDate}
            minDate={startDate || new Date(2021, 1, 1)}
          />
        </Group>
      }
    >
      <div className="customer-view-consumption-topline-metrics--container">
        <div className="customer-view-consumption-topline-metric--container">
          <div className="customer-view-consumption-topline-metric-value--container">
            <Skeleton visible={isLoading} width="fit-content">
              <div className="customer-view-consumption-topline-metric--value">{totalConsumptionMwh?.toLocaleString()}</div>
            </Skeleton>
            <div className="customer-view-consumption-topline-metric--label">MWh</div>
          </div>
          <div className="customer-view-consumption-topline-metric-explanation--container">
            <div className="customer-view-consumption-topline-metric-explanation--label">Total consumption YTD</div>
            <div className="customer-view-vs-forecast--container">
              <Skeleton visible={isLoading}>
                <Pill fz="12px" radius="sm" size="xs" c={percentVsForecasted <= 1 ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={percentVsForecasted <= 1 ? "var(--color-teal-0)" : "var(--color-red-1)"}>
                  {`${percentVsForecasted > 1 ? '+' : ''} ${((percentVsForecasted - 1) * 100).toFixed(1)} %`}
                </Pill>
              </Skeleton>
              <small className="customer-view-vs-forecast--explanation">vs projection</small>
            </div>
          </div>
        </div>

        <div className="customer-view-consumption-topline-metric--container">
          <div className="customer-view-consumption-topline-metric-value--container">
            <Skeleton visible={isLoading} width="fit-content">
              <div className="customer-view-consumption-topline-metric--value highlight">{totalForecastKwh?.toLocaleString()}</div>
            </Skeleton>
            <div className="customer-view-consumption-topline-metric--label">MWh</div>
          </div>
          <div className="customer-view-consumption-topline-metric-explanation--container">
            <div className="customer-view-consumption-topline-metric-explanation--label">Consumption year-on-year projection full year</div>
          </div>
        </div>
      </div>
      <Skeleton visible={isLoading}>
        <BaseChart overrideOptions={consumptionChartOptions} />
      </Skeleton>
    </BasePaper>
  );
};


const CustomerProgramSubscriptions = ({ customer }: { customer: ICustomer }) => {
  const oci = useAppSelector(getViewingOpCoId);
  const pageRes = usePaginateSubscriptionsQuery({ page: 1, perPage: 50, customerId: oci, childCustomerId: customer.id })

  return (
    <CustomerSubscriptions title="Program Subscriptions to date" subscriptions={pageRes.data?.data || []} titleRow="program" />
  );
};

export default function CustomerView() {
  const { customerId = '' } = useParams<{ customerId: string }>();
  const {
    data: customer,
    isLoading,
  } = useCustomer(customerId);

  if (isLoading || !customer) {
    return <Box h="100vh">
      <LoadingOverlay visible={true} />
    </Box>;
  }

  return (
    <div className="customer-page--scroll-container">
      <Group align="flex-start">
        <CustomerDetails customer={customer} />
        <SubAccounts customer={customer} />
      </Group>
      <CustomerEnergyConsumption customer={customer} />
      <CustomerProgramSubscriptions customer={customer} />
    </div>
  );
}