import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, Select, TextInput } from "@mantine/core";
import { useAdminCreateUserMutation } from "admin/api/users";
import { FormEvent, useState } from "react";
import { UserRole, UserStatus } from "shared/types/user";


const CreateUserModal = ({
  onClose,
  isOpen,
  customerId,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customerId: string}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post] = useAdminCreateUserMutation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(UserStatus.ACTIVE);
  const [role, setRole] = useState(UserRole.USER);
  const [password, setPassword] = useState('');

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customerId) {
      return
    }
    try {
      await post({
        customer_id: customerId,
        name,
        status,
        role,
        email,
        password: password ? password : undefined,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setName('');
    setEmail('');
    setPassword('');
    setRole(UserRole.USER);
    setStatus(UserStatus.ACTIVE);
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Create a new user for this customer"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The ID of the customer</InputLabel>
          <TextInput value={customerId} disabled />
        </InputWrapper>

        <InputWrapper mt={16}>
          <InputLabel>Name</InputLabel>
          <TextInput value={name} onChange={(e) => setName(e.target.value)} />
        </InputWrapper>

        <InputWrapper mt={16}>
          <InputLabel>Email</InputLabel>
          <TextInput value={email} onChange={(e) => setEmail(e.target.value)} />
        </InputWrapper>

        <InputWrapper mt={16}>
          <InputLabel>Password</InputLabel>
          <InputDescription>Leave blank to create a user without a password.</InputDescription>
          <TextInput value={password} onChange={(e) => setPassword(e.target.value)} />
        </InputWrapper>

        <Select data={[
          {value: UserStatus.ACTIVE, label: 'active'},
          {value: UserStatus.ARCHIVED, label: 'archived'},
          {value: UserStatus.DELETED, label: 'deleted'},
          {value: UserStatus.INVITED, label: 'invited'},
          {value: UserStatus.UNVERIFIED, label: 'unverified'},
        ]} value={status} label="Status" onChange={(newStatus) => newStatus && setStatus(newStatus as UserStatus)} />

        <Select data={[
          {value: UserRole.ADMIN, label: 'admin'},
          {value: UserRole.USER, label: 'user'},
          {value: UserRole.REPORTER, label: 'reporter' },
          {value: UserRole.SADMIN, label: 'singularity admin'},
          {value: UserRole.OWNER, label: 'owner'},
          {value: UserRole.CONTRIBUTOR, label: 'contributor'},
          {value: UserRole.VISITOR, label: 'visitor'},
        ]} value={role} label="Role" onChange={(newRole) => newRole && setRole(newRole as UserRole)} />

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default CreateUserModal;