import { Button, Group, InputDescription, InputLabel, InputWrapper, Loader, Modal, Select, TextInput } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useAdminCreateCustomerMutation, useLazyAdminFetchCustomerQuery } from "admin/api/customers";
import { FormEvent, useState } from "react";
import { CustomerStatus, CustomerType } from "shared/types/customer";


const CreateCustomerModal = ({isOpen, onClose}: {isOpen: boolean, onClose: (didSucceed: boolean) => void}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post] = useAdminCreateCustomerMutation();
  const [name, setName] = useState('');
  const [parentId, setParentId] = useState('');
  const [status, setStatus] = useState(CustomerStatus.ACTIVE);
  const [type, setType] = useState<CustomerType | undefined>();
  const [doesCustomerExist, setDoesCustomerExist] = useState(false);
  const [fetchCustomer, fetchCustomerRes] = useLazyAdminFetchCustomerQuery();

  const checkDoesCustomerExist = async () => {
    if (!parentId) {
      setDoesCustomerExist(true);
      return
    }

    try {
      await fetchCustomer(parentId).unwrap();
      setDoesCustomerExist(true);
    } catch (err) {
      setDoesCustomerExist(false);
    }
  }

  const isValid = !!name && (!parentId || (!!parentId && doesCustomerExist));

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!isValid) {
      return
    }
    try {
      await post({
        name,
        status,
        parent_id: parentId ? parentId : null,
        attributes: [],
        customer_type: type,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setName('');
    setStatus(CustomerStatus.ACTIVE);
    setParentId('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Create a new customer"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={16}>
          <InputLabel>Name</InputLabel>
          <TextInput value={name} onChange={(e) => setName(e.target.value)} />
        </InputWrapper>

        <InputWrapper mt={16}>
          <InputLabel>Parent Customer ID</InputLabel>
          <TextInput value={parentId} onChange={(e) => setParentId(e.target.value)} onBlur={checkDoesCustomerExist} />
          {fetchCustomerRes.isLoading && <InputDescription><Loader size={8}/> Checking that customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && !!parentId && doesCustomerExist && <InputDescription><IconCheck color="var(--color-teal-6)" size={8} /> Customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && !doesCustomerExist && !fetchCustomerRes.isUninitialized && <InputDescription><IconX color="var(--color-se-red-1)" size={8} /> Customer does not exist</InputDescription>}
        </InputWrapper>

        <Select data={[
          {value: CustomerStatus.ACTIVE, label: 'active'},
          {value: CustomerStatus.ARCHIVED, label: 'archived'},
          {value: CustomerStatus.DELETED, label: 'deleted'},
        ]} value={status} label="Status" onChange={(newStatus) => newStatus && setStatus(newStatus as CustomerStatus)} />

        <Select data={[
          {value: CustomerType.UTILITY, label: 'Utility (e.g. SOCO)'},
          {value: CustomerType.UTILITY_OPCO, label: 'Utility opco (e.g. GPC)'},
          {value: CustomerType.UTILITY_PARENT_CUSTOMER, label: 'Parent utility customer (e.g. GSA)'},
          {value: CustomerType.UTILITY_CUSTOMER, label: 'Utility customer (e.g. Walmart)'},
          {value: CustomerType.UTILITY_CUSTOMER_ACCOUNT, label: 'Utility account (e.g. Walmart at 123 Main St.)'},
        ]} value={type} label="Type" description='Leave blank to not have a "type" of customer' onChange={(newtype) => setType(newtype as CustomerType)} />

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit" disabled={!isValid}>Submit</Button>
        </Group>
      </form>
    </Modal>
  );

};

export default CreateCustomerModal;