import { Drawer } from "@mantine/core";

import { ICertificate } from "shared/types/certificates";
import { timestampToNumericDate } from "shared/utils/dates";
import { moneyToString, numberToString } from "shared/utils/strings";
import './style.css';

const CertAttribute = ({label, value}: {label: string, value: React.ReactNode}) => {
  return <div className="cert-drawer-attr--container">
    <div className="cert-drawer-attr--label">{label}</div>
    <div className="cert-drawer-attr--value">{value || <em>None</em>}</div>
  </div>
}

const CertificateDrawer = ({cert, isOpen, onClose}: {cert: ICertificate | null, isOpen: boolean, onClose: () => void}) => {
  if (!cert) return null;

  return <Drawer
    opened={isOpen}
    onClose={onClose}
  >
    <div className="certificate-drawer--container">
      <h3>EAC</h3>
      <div>
        <p>Attributes</p>
        <div>
          <CertAttribute label="ID" value={cert.id} />
          <CertAttribute label="Owned by" value={cert.ownedBy || cert.customerId} />
          <CertAttribute label="Status" value={cert.status} />
          <CertAttribute label="Generation Event ID" value={cert.assetEventId} />
          <CertAttribute label="Carrier" value={cert.energyCarrier} />
          <CertAttribute label="Generator Operational Start" value={cert.deviceOpStart} />
          <CertAttribute label="Certificate Start Date" value={timestampToNumericDate(cert.start)} />
          <CertAttribute label="Certificate End Date" value={timestampToNumericDate(cert.end)} />
          <CertAttribute label="Issued On" value={timestampToNumericDate(cert.issuedAt)} />
          <CertAttribute label="Issued From" value={cert.issuedLocation} />
          <CertAttribute label="Production Source" value={cert.productionSource} />
          <CertAttribute label="Production Method" value={cert.productionTechnology} />
          <CertAttribute label="Watt-Hours" value={numberToString(cert.wattHours)} />
          <CertAttribute label="Grid Injection" value={cert.gridInjection} />
          <CertAttribute label="Informs Consumer Disclosure" value={cert.informsConsumerDisclosure ? 'Yes' : 'No'} />
          {cert.value && <CertAttribute label="Cost" value={moneyToString(cert.value.amount / 100)} />}
          {cert.emissions &&
            <>
              <CertAttribute label="Emission Factor" value={`${numberToString(cert.emissions.emissionFactor)} ${cert.emissions.emissionFactorUnits.replaceAll('_', ' ')}`} />
              <CertAttribute label="Emissions Methodology" value={cert.emissions.emissionFactorMethodology} />
            </>
          }
          <CertAttribute label="Generator ID" value={cert.device.id} />
          <CertAttribute label="Generator" value={cert.device.name} />
        </div>
      </div>
    </div>
  </Drawer>
}



export default CertificateDrawer;