import { GeneratorFuelCategories } from 'shared/types/generator';

export const getColorForFuel = (fuelType: GeneratorFuelCategories) => {
  switch (fuelType) {
    case GeneratorFuelCategories.batteries:
      return '#5C7CFA';
    case GeneratorFuelCategories.biomass:
      return '#FFA94D';
    case GeneratorFuelCategories.coal:
      return '#868E96';
    case GeneratorFuelCategories.hydro:
      return '#A5D8FF';
    case GeneratorFuelCategories.landfill_gas:
      return '#FFC8A8';
    case GeneratorFuelCategories.natural_gas:
      return '#ADB5BD';
    case GeneratorFuelCategories.nuclear:
      return '#91A7FF';
    case GeneratorFuelCategories.other:
      return '#DCDFE2';
    case GeneratorFuelCategories.petroleum:
      return '#5C5F66';
    // Note: no geothermal in SOCO data
    case GeneratorFuelCategories.geothermal:
      return '#A8D9CE';
    case GeneratorFuelCategories.solar:
      return '#FFE066';
    case GeneratorFuelCategories.waste:
      return '#A69098';
    case GeneratorFuelCategories.wind:
      return '#62C1AB';
    case GeneratorFuelCategories.wood:
      return '#FFA94D';
    default:
      // There shouldn't be any generation with "null" fuel type
      // if this shows up it's representative of something wrong in the data
      return '#000000';
  }
};