
export const isDefined = (value: unknown) => value !== null && value !== undefined;


export const getErrorMessagesFromApiError = (err: unknown) => {
  if (
    typeof err === 'object' &&
    err !== null &&
    'data' in err &&
    typeof err.data === 'object' &&
    err.data !== null &&
    'error_code' in err.data &&
    err.data.error_code === 'invalid-data' &&
    'error_message' in err.data &&
    typeof err.data.error_message === 'object' &&
    err.data.error_message !== null
  ) {
    return err.data.error_message as Record<string, string[]>;
  }
};

