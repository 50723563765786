import { useAdminPaginateConnectorsQuery } from "admin/api/connectors";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'status',
    displayValue: 'Status',
  },
  {
    key: 'provider',
    displayValue: 'Provider',
  },
  {
    key: 'externalAcctId',
    displayValue: 'Ext Acct Id',
  },
  {
    key: 'meta',
    displayValue: 'Metadata',
  },
]

const CustomerConnectorsView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateConnectorsQuery({
    page,
    perPage,
    customerId,
  }, {skip: !customerId});

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onClickRow = (connectorId: string) => {
    nav(`/admin/customers/${customerId}/connectors/${connectorId}`);
  };

  const pagination = data?.meta.pagination;
  const connectors = data?.data || [];

  return <AdminViewContainer title="Connectors">
    <BaseTable
      rows={connectors.map(conn => ({
        id: conn.id,
        status: conn.status,
        provider: conn.provider,
        externalAcctId: conn.external_account_id,
        meta: JSON.stringify(conn.meta),
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default CustomerConnectorsView;