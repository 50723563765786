import { ReactNode } from 'react';

import './basePaper.css';

interface IBasePaperProps extends React.ComponentPropsWithoutRef<'div'>{
  titleContent?: ReactNode | null | undefined
  actions?: ReactNode | null | undefined
}

const BasePaper = (props: IBasePaperProps) => {
  const { actions, className, children, titleContent, ...rest } = props;

  return (
    <div className={`base-paper--container ${className ? ' ' + className : ''}`} {...rest}>
      {(titleContent || actions) && (
        <div className="base-paper--title-container">
          {titleContent || <div></div>}
          {actions ? <div className="base-paper--text">{actions}</div> : <div></div>}
        </div>
      )}
      {children}
    </div>
  );
}

export default BasePaper;