import { Group, Skeleton, Stack } from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';

import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import Tabs from 'amp/components/Tabs';
import { useGenerator } from 'amp/store/generators/hooks';
import './style.css';


export default function InventoryOutlet() {
  const { generatorId = '' } = useParams<{ generatorId: string }>();
  const genRes = useGenerator(generatorId);

  if (generatorId) {
    return (
      <Stack pos="fixed" w="calc(100% - 220px)">
        <Stack m="60px" mt="32px" mb="16px" gap={32}>
          <PageBreadcrumbs />
          <Group align="center">
            {genRes.isLoading && <Skeleton visible={true} w={400} h={40} />}
            {!genRes.isLoading && <div className="inventory-outlet--title">
              {genRes.data?.name || ''}
            </div>}
          </Group>
        </Stack>
        <Outlet />
      </Stack>
    );
  }

  return (
    // width: 100% - (220px)[nav-bar-width]
    <Stack pos="fixed" w="calc(100% - 220px)" gap={0}>
      <Stack ml="60px" mr="60px" mt="60px" mb="20px" gap="12px">
        <div className="inventory-outlet--title">
          Inventory
        </div>
        {<Tabs tabs={[
          { title: 'Generation', path: '/dashboard/inventory' },
          { title: 'Sources', path: '/dashboard/inventory/sources' },
        ]} />}
      </Stack>
      <Outlet />
    </Stack>
  );
}