import { Box, Group, Pill, Skeleton, Table, Text } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconTrash } from '@tabler/icons-react';
import { sum } from 'ramda';
import { MouseEvent, ReactNode } from 'react';

import { useFetchAggregatedEventsQuery } from 'amp/api/assetEvents';
import { useFetchForecastForCustomerQuery } from 'amp/api/assetForecasts';
import { useFetchGenerationQuery } from 'amp/api/generators';
import { useDeleteSubscriptionMutation } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useProgram } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomer } from 'shared/store/customers/hooks';
import { AggregationTypes, ITotalLoadEventData } from 'shared/types/aggregatedEvents';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { ICustomer } from 'shared/types/customer';
import { IProgram } from 'shared/types/program';
import { ISubscription } from 'shared/types/subscription';
import { getThisYearEnd, getThisYearStart, timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiUnits } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';


const ProgramTableCell = ({ program }: { program: IProgram | undefined }) => {
  if (!program) {
    return <em>Unknown customer</em>;
  }

  return (
    <AmpLink to={`/dashboard/programs/${program.id}`} onClick={(e) => e.stopPropagation()}>
      <Group>
        <Text fz={12} c="var(--color-black-2)" truncate="end" style={{ textOverflow: 'ellipsis'}}>
          {program.name}
        </Text>
      </Group>
    </AmpLink>
  );
}


const CustomerTableCell = ({ customer, subscription }: { customer: ICustomer | undefined, subscription: ISubscription }) => {
  if (!customer) {
    return <Text fz={10} fw={400}>Unknown customer</Text>;
  }

  return (
    <AmpLink to={`/dashboard/programs/${subscription.retail_program_id}/${subscription.id}`} onClick={(e) => e.stopPropagation()}>
      <Group>
        <Box w={100}>
        <Text fz={10} fw={400} c="var(--color-black-2)" truncate="end" style={{ textOverflow: 'ellipsis'}} title={customer?.name || 'unknown customer'}>
          {customer?.name}
        </Text>
        </Box>
      </Group>
    </AmpLink>
  );
}

const SubscriptionTableRow = ({ subscription, titleRow }: { subscription: ISubscription, titleRow: 'customer' | 'program' }) => {
  const oci = useAppSelector(getViewingOpCoId);
  const { data: customer } = useCustomer(subscription.customer_id);
  const { data: program } = useProgram(subscription.retail_program_id);
  const subscriptionStart = subscription.data.configuration.subscription_start;
  const subscriptionEnd = subscription.data.configuration.subscription_end;
  const startYTD = getThisYearStart();
  const endYTD = getThisYearEnd();
  const generatorIds = program.assignments.map(assignment => assignment.asset_id);
  const nav = useAmpNav();
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  // this number is less than 1
  const generationDedicatedPct = subscription.data.configuration.percent_generation_dedicated_ten_thousandths / 1_000_000;

  const loadYTD = useFetchAggregatedEventsQuery({
    startDate: startYTD.toISOString(),
    endDate: endYTD.toISOString(),
    resolution: '1d',
    aggregationType: AggregationTypes.TOTAL_LOAD,
    customerId: subscription.customer_id,
  });

  const generationNotApplicable = generatorIds.length === 0 || !subscriptionStart || !subscriptionEnd
  const genDuringProgramRes = useFetchGenerationQuery({
    startDate: subscriptionStart || '',
    endDate: subscriptionEnd || '',
    resolution: '1d',
    generatorIds,
    customerIds: [oci],
  }, {skip: generationNotApplicable});
  const generationWh = sum(genDuringProgramRes.data?.data.map(gen => (gen.data as IUtilityGenerationData).sum_generated_wh) || []);
  const formattedGeneration = numberToSiUnits(generationWh);
  const formattedGenerationReserved = numberToSiUnits(generationWh * (subscription.data.configuration.percent_generation_dedicated_ten_thousandths / 10_000) / 100);
  // TODO: endpoint to fetch the forecasted generation for a program
  const forecastedGenDuringProgramRes = useFetchGenerationQuery({
    startDate: subscriptionStart || '',
    endDate: subscriptionEnd || '',
    resolution: '1d',
    generatorIds,
    customerIds: [oci],
  }, {skip: generationNotApplicable});

  const forecastedGenerationWh = sum(forecastedGenDuringProgramRes.data?.data.map(gen => {
    const rawWh = (gen.data as IUtilityGenerationData).sum_generated_wh;
    const fuzzed = rawWh * 0.97;
    return fuzzed;
  }) || []);
  const formattedForecastedGeneration = numberToSiUnits(forecastedGenerationWh);

  const totalLoadKWh = sum(loadYTD.data?.data.map(ae => (ae.data as ITotalLoadEventData).sum_consumed_kwh) || []);
  const formattedTotalLoad = numberToSiUnits(totalLoadKWh * 1000);

  const forecastRes = useFetchForecastForCustomerQuery({ customerId: oci, forecastCustomerId: subscription.customer_id, year: endYTD.getFullYear() });
  const maxEpochForLoad = Math.max(...(loadYTD.data?.data.map(d => new Date(d.start_date).valueOf()) || []));
  const filteredToYTD = forecastRes.data?.event_forecast_batch.data?.results.filter(f => new Date(f.start_date).valueOf() <= maxEpochForLoad) || [];
  const totalForecastWh = sum(filteredToYTD.map(f => f.y_axis_value_wh));
  const formattedTotalForecast = numberToSiUnits(totalForecastWh);

  const forecastedCFEMatching = Math.round(Math.min((forecastedGenerationWh * generationDedicatedPct) / (totalForecastWh), 1) * 100);
  const actualCFEMatching = Math.round(Math.min((generationWh * generationDedicatedPct) / (totalLoadKWh * 1_000), 1) * 100);
  const cfeDiff = Math.abs(actualCFEMatching - forecastedCFEMatching);
  const anyCFEIsNaN = isNaN(forecastedCFEMatching) || isNaN(actualCFEMatching) || isNaN(cfeDiff);

  const titleCell = titleRow === 'customer' ? <CustomerTableCell customer={customer} subscription={subscription} /> : <ProgramTableCell program={program.program} />

  const isAnyLoading = loadYTD.isLoading || forecastRes.isLoading || genDuringProgramRes.isLoading || forecastedGenDuringProgramRes.isLoading;

  const rowLink = `/dashboard/programs/${subscription.retail_program_id}/${subscription.id}`;

  if (program.program?.status !== 'active') {
    return null;
  }

  const onDeleteSubscription = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    deleteSubscription({subscriptionId: subscription.id, programId: subscription.retail_program_id, customerId: oci});
  }

  return (
    <Table.Tr className="subscription-table--row" onClick={() => nav(rowLink)}>
      <Table.Td p="16px 8px">
        {titleCell}
      </Table.Td>
      <Table.Td>
        <Text fz={10} c="var(--color-blue-3)" ta="center">{`${subscriptionStart ? timestampToNumericDate(subscriptionStart) : 'not specified'} - ${subscriptionEnd ? timestampToNumericDate(subscriptionEnd) : 'not specified'}`}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz={10} c="var(--color-blue-3)" ta="center">{subscription.data.configuration.percent_generation_dedicated_ten_thousandths / 10_000}%</Text>
      </Table.Td>
      <Table.Td className="subscription-table-cfe--td">
        <Text fz={10} c="var(--color-blue-1)" ta="center">{generationNotApplicable ? <em>N/A</em> : `${formattedForecastedGeneration.slice(0, -1)} ${formattedForecastedGeneration.slice(-1)}Wh`}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz={10} c="var(--color-black-2)" ta="center">{generationNotApplicable ? <em>N/A</em> : `${formattedGeneration.slice(0, -1)} ${formattedGeneration.slice(-1)}Wh`}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz={10} c="var(--color-black-2)" ta="center">{generationNotApplicable ? <em>N/A</em> : `${formattedGenerationReserved.slice(0, -1)} ${formattedGenerationReserved.slice(-1)}Wh`}</Text>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={forecastRes.isLoading}>
        <Text fz={10} c="var(--color-blue-1)" ta="center">{formattedTotalForecast.slice(0, -1)} {formattedTotalForecast.slice(-1)}Wh</Text>
        </Skeleton>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={loadYTD.isLoading}>
          <Text fz={10} c="var(--color-black-2)" ta="center">{formattedTotalLoad.slice(0, -1)} {formattedTotalLoad.slice(-1)}Wh</Text>
        </Skeleton>
      </Table.Td>
      <Table.Td className="subscription-table-cfe--td">
        <Skeleton visible={isAnyLoading} className="subscription-table-cfe--container">
          {!anyCFEIsNaN &&
            <>
              <Text fz={10} c="var(--color-blue-3)" fw="700">
                {actualCFEMatching}%
              </Text>
              <div className="subscription-table-cfe--comparison">
                {forecastedCFEMatching < actualCFEMatching ? <IconArrowUp className="subscription-table-cfe--icon" color="var(--color-teal-6)" /> : <IconArrowDown className="subscription-table-cfe--icon" color="var(--color-red-0)" />}
                <Pill fz="10px" size="sm" radius="sm" c={forecastedCFEMatching < actualCFEMatching ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={forecastedCFEMatching < actualCFEMatching ? "var(--color-teal-0)" : "var(--color-red-1)"}>{forecastedCFEMatching > actualCFEMatching ? '-' : '+'}{cfeDiff}%</Pill>
              </div>
            </>
          }
          {anyCFEIsNaN && <em>N/A</em>}
        </Skeleton>
      </Table.Td>
      <Table.Td onClick={onDeleteSubscription} className="subscription-table--delete-icon">
        <IconTrash size="12px"/>
      </Table.Td>
    </Table.Tr>
  );
}

export default function CustomerSubscriptions({
  subscriptions,
  title='Program Subscriptions',
  titleRow='customer'
}: { program?: IProgram, subscriptions: ISubscription[], title?: ReactNode, titleRow: 'customer' | 'program' }) {

  return (
    <BasePaper titleContent={title}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th className="subscription-table--header-container">
                <Text size="10px" c="var(--color-blue-2)" fw="600" w={100}>Name</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container">
                <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Duration of contract</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container">
                <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Commitment</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container subscription-table-cfe--td">
                <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Generation Forecast</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container">
                <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Generation Actual</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container">
                <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Generation Reserved</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container">
                <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Consumption Forecast</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container">
                <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Consumption Actual</Text>
              </Table.Th>
              <Table.Th className="subscription-table--header-container subscription-table-cfe--td">
                <Text size="10px" ta="right" c="var(--color-blue-2)" fw="600">Expected CFE Matching</Text>
              </Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {subscriptions.map(sub => <SubscriptionTableRow key={sub.id} subscription={sub} titleRow={titleRow} />)}
          </Table.Tbody>
        </Table>
    </BasePaper>
  );
}