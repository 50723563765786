import { Breadcrumbs } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IconArrowLeft } from '@tabler/icons-react';
import { looksLikeUUID, snakeToTitle } from 'shared/utils/strings';
import { AmpLink } from '../Link';
import './style.css';

const PageBreadcrumbs = () => {
  const [crumbs, setCrumbs] = useState<string[]>([]);
  const {pathname} = useLocation();


  useEffect(() => {
    setCrumbs(pathname.split('/').filter(c => c).slice(1, -1) || []);
  }, [pathname]);

  const prevCrumbs: string[] = [];
  const crumbRows: ReactNode[] = [];
  crumbs.forEach((crumb, index, arr) => {
    // Don't render the current page as a link
    const priorCrumbs = prevCrumbs.join('/');
    const href = `/dashboard/${priorCrumbs ? priorCrumbs + '/' : ''}${crumb}`;
    const name = looksLikeUUID(crumb) ? 'selection' : snakeToTitle(crumb);
    crumbRows.push(
      <AmpLink className="breadcrumb--link" to={href} key={index}>{index === 0 && <IconArrowLeft style={{marginRight: 8}} size={16}/>} {name}</AmpLink>
    );
    prevCrumbs.push(crumb);
  });

  return (
    <Breadcrumbs>
      {crumbRows}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
