import { Button } from "@mantine/core";
import { useAdminPaginateSSOConfigsQuery } from "admin/api/customers";
import EditSSOConfigModal from "admin/components/EditSSOConfigModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";
import { ICustomerSSOConfig } from "shared/types/customer";


const tableColumns = [
  {
    key: 'schema',
    displayValue: 'SSO Config Type',
  },
  {
    key: 'status',
    displayValue: 'Status',
  },
  {
    key: 'metadata',
    displayValue: 'Meta data',
  },
  {
    key: 'created',
    displayValue: 'Created at',
  },
  {
    key: 'updated',
    displayValue: 'Last updated',
  },
]



const CustomerSSOView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingSSOConfig, setEditingSSOConfig] = useState<undefined | ICustomerSSOConfig>(undefined);

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useAdminPaginateSSOConfigsQuery(customerId, {skip: !customerId});

  const closeModal = () => {
    setEditingSSOConfig(undefined);
    setIsEditModalOpen(false);
  }

  const onClickRow = (configId: string) => {
    const config = data?.data.find(({id}) => id === configId);
    setEditingSSOConfig(config);
    setIsEditModalOpen(true);
  };

  if (isError) {
    return <div>Error loading SSO configs</div>;
  }

  return <AdminViewContainer title="Customer SSO Configurations" actions={<Button onClick={() => setIsEditModalOpen(true)}>Create new SSO Config</Button>}>
    <EditSSOConfigModal isOpen={isEditModalOpen} onClose={closeModal} customerId={customerId} ssoConfig={editingSSOConfig}/>
    <BaseTable
      rows={(data?.data || []).map(config => ({
        id: config.id,
        schema: config.data_schema,
        status: config.status,
        metadata: JSON.stringify(config.meta),
        config: JSON.stringify(config.data),
        created: new Date(config.created_at).toLocaleString(),
        updated: new Date(config.updated_at).toLocaleString(),
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={data?.meta.pagination.last || 0}
      totalItems={data?.meta.pagination.total_items || 0}
      currentPage={data?.meta.pagination.this || 1}
      onPageChange={() => undefined}
    />
  </AdminViewContainer>
};

export default CustomerSSOView;