import { Group, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchAssetQuery } from "admin/api/assets";
import AdminViewContainer from "admin/components/ViewContainer";
import './style.css';


const CustomerAssetView = () => {
  const { assetId='' } = useParams<{assetId: string}>();
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchAssetQuery(assetId, {skip: !assetId});

  if (isError) {
    return <div>Failed to load asset</div>;
  }

  const asset = data?.data;

  return <AdminViewContainer title={asset?.name || assetId}>

    {(isLoading || !asset) && <Loader />}
    {asset && <div>
      <div>
        actions to do things here
      </div>
      <div>
        <Group>
          <div>Name</div>
          <div>{asset.name}</div>
        </Group>
        <Group>
          <div>Description</div>
          <div>{asset.description || <em>no description</em>}</div>
        </Group>
        <Group>
          <div>Type</div>
          <div>{asset.asset_type}</div>
        </Group>
        <Group>
          <div>Base resolution</div>
          <div>{asset.base_resolution || <em>no base resolution</em>}</div>
        </Group>
        <Group>
          <div>Location info</div>
          <div>{JSON.stringify(asset.location)}</div>
        </Group>
        <Group>
          <div>Metadata</div>
          <div>{JSON.stringify(asset.meta)}</div>
        </Group>
      </div>
    </div>}
  </AdminViewContainer>
};

export default CustomerAssetView;
