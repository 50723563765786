import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getViewingOpCoId } from "amp/store/ui/selectors";
import { fetchCustomersPage } from "shared/api/customers";
import { IUserAuthPagination, IUserAuthPaginationResponse } from "shared/types/api";
import { CustomerType, ICustomer } from "shared/types/customer";
import { IAsyncDataSlice } from "shared/types/store";
import { AppDispatch, RootState } from "store";
import { getOpcos } from "../user/selectors";
import { getUtilityCustomerPageLoading } from "./selectors";

interface ICustomerSlice {
  byId: Record<string, ICustomer>
  customersPageResponseByOCI: Record<string, IAsyncDataSlice<string[]>>,
}

export interface IListCustomersArgs {
  limit: number
  offset: number
  statuses?: string[]
}


const initialState: ICustomerSlice = {
  byId: {},
  customersPageResponseByOCI: {},
}

export const fetchUtilityCustomers = createAsyncThunk<{data?: IUserAuthPaginationResponse<ICustomer>, oci: string, status: 'SUCCESS' | 'FAILED'} | undefined, IListCustomersArgs, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'customer/fetchUtilityCustomers',
  async ({limit, offset, statuses}, {getState, dispatch}) => {
    const state = getState();
    const opcoId = getViewingOpCoId(state);
    const opcos = getOpcos(state);
    const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
    const oci = customerIds.sort().join(',');
    dispatch(customersSlice.actions.initializeCustomerPageForOCI(oci));

    if (getUtilityCustomerPageLoading(getState(), oci)) {
      // short circuit
      return undefined;
    }
    dispatch(customersSlice.actions.setCustomerPageLoading({oci, isFetching: true}));

    // This function is specific to utility customers, always filter on those customer types
    const qs = new URLSearchParams();
    qs.append('customer_type', CustomerType.UTILITY_CUSTOMER);
    qs.append('customer_type', CustomerType.UTILITY_PARENT_CUSTOMER);

    qs.set('limit', limit.toString());
    qs.set('offset', offset.toString());
    statuses?.forEach(status => {
      qs.append('statuses', status);
    });

    try {
      const customersById: Record<string, ICustomer> = {};
      let pagination: IUserAuthPagination = {this: 1, last: 1, total_items: 1, first: 1};
      const customersRes = await Promise.all(customerIds.map(cid => fetchCustomersPage(cid, qs.toString())));
      customersRes.forEach(res => {
        pagination = res.data.meta.pagination;
        res.data.data.forEach(cust => {
          customersById[cust.id] = cust;
        });
      })
      return {data: {data: Object.values(customersById), meta: {pagination}}, status: 'SUCCESS', oci};
    } catch (err) {
      return {status: 'FAILED', oci};
    }
  },
);


const customersSlice = createSlice({
  name: 'amp__customers',
  initialState,
  reducers: {
    receiveCustomers: (state, action: PayloadAction<ICustomer[]>) => {
      action.payload.forEach(cust => {
        state.byId[cust.id] = cust;
      })
    },

    setCustomerPageLoading: (state, action: PayloadAction<{oci: string, isFetching: boolean}>) => {
      state.customersPageResponseByOCI[action.payload.oci].isFetching = action.payload.isFetching;
    },

    initializeCustomerPageForOCI: (state, action: PayloadAction<string>) => {
      if (!state.customersPageResponseByOCI[action.payload]) {
        state.customersPageResponseByOCI[action.payload] = {
          data: null,
          lastReceived: null,
          isFetching: false,
          fetchFailed: false,
          pagination: null,
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUtilityCustomers.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      const ociState = state.customersPageResponseByOCI[action.payload.oci];

      ociState.isFetching = false;

      if (action.payload.data) {
        const customers = action.payload.data.data;
        ociState.data = customers.map(c => c.id);
        customers.forEach(cust => {
          state.byId[cust.id] = cust;
        });
        ociState.fetchFailed = false;
        ociState.lastReceived = new Date();
      } else {
        ociState.fetchFailed = true;
      }
    })
  }

});


export const { receiveCustomers } = customersSlice.actions;
export default customersSlice.reducer;