import { isDefined } from 'shared/utils/data';
import { IDashboardPagination } from './api';
import { IGeneratorAssignment } from './assignment';


export interface IGeneratorLocation {
  us_state?: string | null
  lat_lng?: string[] | null | number[]
  zip_code?: string | null
  eia_balancing_authority?: string | null
}

export interface IGeneratorMetadata {
  // TODO: enum
  fuel_category?: string | null
  capacity_mw?: number | null
  operation_start_date?: string | null
  legal_status?: string | null
  support_received?: string | null
  quality_scheme_reference?: string | null
  dissemination_level?: string | null
  issuance_post_energy_carrier_conversion?: boolean | null
  fuel_technology?: string | null
  'emissions_backstop_lbs_per_mwh.co2'?: number | null
  'emissions_backstop_lbs_per_mwh.co2e'?: number | null
  'emissions_backstop_lbs_per_mwh.ch4'?: number | null
  'emissions_backstop_lbs_per_mwh.n2o'?: number | null
}



// TODO: make a class that implements dataToDisplayValues?
export interface IGenerator {
  id: string
  customer_id: string
  created_at: string
  updated_at?: string
  parent_id?: string
  location: IGeneratorLocation
  // TODO: enum
  asset_type: string
  // TODO: enum
  base_resolution?: string

  name: string
  description?: string | null
  meta: IGeneratorMetadata
}


export interface IListGeneratorsResponse {
  data: IGenerator[];
  meta: {
    pagination: IDashboardPagination
  }
}

export interface IFetchGeneratorResponse {
  generator: IGenerator;
  assignments: IGeneratorAssignment[];
}


export const dataToDisplayValues = (generator?: IGenerator) => {
  const defaultLatLng = (generator?.location.lat_lng?.map(l => Number(l)) || [0, 0]);

  return {
    // base fields
    name: generator?.name || '',
    description: generator?.description || '',

    // location fields
    usState: generator?.location.us_state || '',
    zipCode: generator?.location.zip_code || '',
    latitude: defaultLatLng[0],
    longitude: defaultLatLng[1],

    // Granular certificate scheme compliance fields
    fuelCategory: generator?.meta.fuel_category || '',
    capacityMw: generator?.meta.capacity_mw || 0,
    operationStartDate: generator?.meta.operation_start_date || '2021-01-01T00:00:00Z',
    legalStatus: generator?.meta.legal_status || '',
    supportReceived: generator?.meta.support_received || '',
    qualitySchemeReference: generator?.meta.quality_scheme_reference || '',
    disseminationLevel: generator?.meta.dissemination_level || '',
    issuancePostEnergyCarrierConversion: generator?.meta.issuance_post_energy_carrier_conversion || false,
  };
};


export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  // TODO: stricter typing?
  const data: Record<string, unknown> = {};

  if (isDefined(values.name)) {
    data.name = values.name;
  }

  if (isDefined(values.description)) {
    data.description = values.description;
  }

  if (isDefined(values.latitude) && isDefined(values.longitude)) {
    data.lat_lng = [values.latitude.toString(), values.longitude.toString()];
  }

  if (isDefined(values.usState)) {
    data.us_state = values.usState;
  }

  if (isDefined(values.zipCode)) {
    data.zip_code = values.zipCode;
  }

  if (isDefined(values.capacityMw)) {
    data.capacity_mw = values.capacityMw;
  }

  if (isDefined(values.disseminationLevel)) {
    data.dissemination_level = values.disseminationLevel;
  }

  if (isDefined(values.fuelCategory)) {
    data.fuel_category = values.fuelCategory;
  }

  if (isDefined(values.issuancePostEnergyCarrierConversion)) {
    data.issuance_post_energy_carrier_conversion = values.issuancePostEnergyCarrierConversion;
  }

  if (isDefined(values.supportReceived)) {
    data.support_received = values.supportReceived;
  }

  if (isDefined(values.operationStartDate)) {
    data.operation_start_date = values.operationStartDate;
  }

  if (isDefined(values.legalStatus)) {
    data.legal_status = values.legalStatus;
  }

  if (isDefined(values.qualitySchemeReference)) {
    data.quality_scheme_reference = values.qualitySchemeReference;
  }

  return data;
};

export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
    name: 'name',
    description: 'description',
    us_state: 'usState',
    // TODO: decompose one field into multiple
    // lat_lng: 'latLng'
    zip_code: 'zipCode',
    fuel_category: 'fuelCategory',
    capacity_mw: 'capacityMw',
    operation_start_date: 'operationStartDate',
    legal_status: 'legalStatus',
    support_received: 'supportReceived',
    quality_scheme_reference: 'qualitySchemeReference',
    dissemination_level: 'disseminationLevel',
    issuance_post_energy_carrier_conversion: 'issuancePostEnergyCarrierConversion',
  };

  Object.entries(errs).forEach(([field, errors]) => {
    if (errors.length && fieldMap[field]) {
      displayErrors[fieldMap[field]] = errors[0];
    }
  });

  return displayErrors;
};

// TODO do we need "geothermal" as well?
export enum GeneratorFuelCategories {
  batteries = 'batteries',
  biomass = 'biomass',
  coal = 'coal',
  geothermal = 'geothermal',
  hydro = 'hydro',
  landfill_gas = 'landfill_gas',
  natural_gas = 'natural_gas',
  nuclear = 'nuclear',
  oil = 'oil',
  other = 'other',
  petroleum = 'petroleum',
  solar = 'solar',
  waste = 'waste',
  wind = 'wind',
  wood = 'wood',
}

// TODO: this feels gross :(
export const GenFuelCategoriesOrder = {
  nuclear: 1,
  hydro: 2,
  wind: 3,
  solar: 4,
  oil: 5,
  petroleum: 6,
  landfill_gas: 7,
  biomass: 8,
  waste: 9,
  wood: 10,
  coal: 11,
  natural_gas: 12,
  other: 13,
  batteries: 14,
  unspecified_purchase: 15,
  // geothermal: 15,
}

export const fuelDisplayName = (fuelType: string) => {
  const asFuelCategory = GeneratorFuelCategories[fuelType as keyof typeof GeneratorFuelCategories];
  if (asFuelCategory === null) {
    return 'None';
  }

  const words = fuelType.split('_');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
}

const cfeFuels = [
  GeneratorFuelCategories.geothermal,
  GeneratorFuelCategories.hydro,
  GeneratorFuelCategories.nuclear,
  GeneratorFuelCategories.solar,
  GeneratorFuelCategories.wind,
]

export const isCfeFuel = (fuelType: string) => {
  const asFuelCategory = GeneratorFuelCategories[fuelType as keyof typeof GeneratorFuelCategories];
  if (asFuelCategory === null) {
    return false;
  }

  return cfeFuels.includes(asFuelCategory);
}

export const usStates: { name: string, value: string }[] = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Deleware' },
  { value: 'GA', name: 'Georgia' },
  { value: 'FL', name: 'Florida' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'MD', name: 'Maryland' },
  { value: 'ME', name: 'Maine' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MT', name: 'Montana' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NY', name: 'New York' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },
];

export const stateValueToName: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Deleware',
  GA: 'Georgia',
  FL: 'Florida',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MD: 'Maryland',
  ME: 'Maine',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MT: 'Montana',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NY: 'New York',
  NM: 'New Mexico',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}