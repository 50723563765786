import { Select, Text } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { useAmpNav } from 'amp/hooks';
import { getCurrentUser } from 'shared/store/user/selectors';
import { isSingularityAdmin } from 'shared/types/user';
import { useAppSelector } from 'store';
import './style.css';


const AppPicker = () => {
  const user = useAppSelector(getCurrentUser);
  const {pathname} = useLocation();
  const nav = useAmpNav();

  // TODO: check the customer attributes for the configured/allowed products

  const products = [
    {name: 'AMP (Utility)', path: '/dashboard'},
  ];

  if (isSingularityAdmin(user)) {
    products.push({name: 'Admin', path: '/admin'});
  }

  const navigateToProduct = (productPath: string | null) => {
    if (productPath) {
      nav(productPath);
    }
  }

  if (products.length === 1) {
    return null;
  }

  return <Select
      className="app--selector"
      size="xs"
      allowDeselect={false}
      data={products.map(program => ({label: program.name, value: program.path}))}
      onChange={navigateToProduct}
      value={products.find(({path}) => pathname.startsWith(path))?.path}
      renderOption={(option) => <Text fz="10px">{option.option.label}</Text>}
    />
};

export default AppPicker;