import { Box, LoadingOverlay } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useProgram } from 'amp/store/programs/hooks';
import GeneratorAssignments from './generatorAssignments';
import './style.css';

const ProgramAssignments = () => {
  const { programId = '' } = useParams<{ programId: string }>();
  const programRes = useProgram(programId);
  const program = programRes.data.program;
  const assignments = programRes.data.assignments;

  if (!program) {
    return <Box w="100%" h="100%">
      <LoadingOverlay visible={true} />
    </Box>
  }

  return (
    <div className='program-assignments--scroll-container'>
      <GeneratorAssignments program={program} assignments={assignments} />
    </div>
  );
}

export default ProgramAssignments;