import { queries } from "shared/api/queries";
import { IAllocationRun } from "shared/types/allocation";
import { IDashboardPaginationResponse } from "shared/types/api";


interface IListRunsArgs {
  page: number
  perPage: number
  customerId: string
}


const adminAllocationRunsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateRuns: build.query<IDashboardPaginationResponse<IAllocationRun>, IListRunsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        qs.set('customer_id', body.customerId);

        return {
          url: `/admin/allocation_runs/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'AllocationRun', id: 'PAGE'}],
    }),

    adminFetchRun: build.query<{data: IAllocationRun}, string>({
      query: (id) => `/admin/allocation_runs/${id}`,

      providesTags: (res) => res ? [{type: 'AllocationRun', id: res.data.id}] : [],
    }),

  }),
  overrideExisting: false,
});


export const {
  useAdminFetchRunQuery,
  useAdminPaginateRunsQuery,
} = adminAllocationRunsQueries;