import Tabs from "amp/components/Tabs";
import { Outlet } from "react-router-dom";

import AdminViewContainer from "admin/components/ViewContainer";
import './style.css';


const AsyncWorkOutlet = () => {
  return <AdminViewContainer title="Async work" isRootView>
    <Tabs tabs={[
      { title: 'Jobs', path: `/admin/async-work/jobs` },
      { title: 'Scheduled Tasks', path: `/admin/async-work/scheduled-tasks` },
    ]} />
    <Outlet />
  </AdminViewContainer>
};

export default AsyncWorkOutlet;
