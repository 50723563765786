import { Button } from "@mantine/core";
import { useAdminFetchCustomerQuery } from "admin/api/customers";
import UpsertCustomerPropertyModal from "admin/components/UpsertCustomerPropertyModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";
import { IParsedCustomerProperty } from "shared/types/customer";


const tableColumns = [
  {
    key: 'property',
    displayValue: 'Property Name',
  },
  {
    key: 'value',
    displayValue: 'Property Value',
  },
]



const CustomerSettingsView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingProperty, setEditingProperty] = useState<undefined | IParsedCustomerProperty>(undefined);

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useAdminFetchCustomerQuery(customerId, {skip: !customerId});

  const onClickAddProperty = () => {
    setEditingProperty(undefined);
    setIsEditModalOpen(true);
  }

  const onClickRow = (propName: string) => {
    const existing = data?.customer.attributes.find(({name}) => name === propName)
    if (existing) {
      setEditingProperty(existing);
    }
    setIsEditModalOpen(true);
  }

  if (isError) {
    return <div>Error loading customer</div>;
  }

  return <AdminViewContainer title="Customer Settings" actions={<Button onClick={onClickAddProperty}>Add property</Button>}>
    <UpsertCustomerPropertyModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} customerId={customerId} customerProperty={editingProperty}/>
    <BaseTable
      rows={(data?.customer.attributes || []).map(attr => ({
        id: attr.name,
        property: attr.name,
        value: JSON.stringify(attr.value),
      }))}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={0}
      totalItems={0}
      currentPage={1}
      onPageChange={() => undefined}
      onTableRowClicked={onClickRow}
    />
  </AdminViewContainer>
};

export default CustomerSettingsView;