import { Group, Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { getAllocationRunStartAndEnd } from "amp/store/allocationRuns/selectors";
import { SeriesOptionsType } from "highcharts";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BaseChart from "shared/components/Chart/baseChart";
import UtcDatePicker from "shared/components/DatePicker/utcDatePicker";
import BasePaper from "shared/components/Paper/basePaper";
import { IAllocationSummaryResult, IProgramAllocationSummaryResult } from "shared/types/allocation";
import { AssetEventResolution } from "shared/types/assetEvents";
import { ICustomer } from "shared/types/customer";
import { IProgram } from "shared/types/program";
import { groupData } from "shared/utils/chart";
import { getThisYearEnd, getThisYearStart } from "shared/utils/dates";
import { useAppSelector } from "store";


const resolutionOptions = [
  { label: 'Hourly', value: '1h' },
  { label: 'Daily', value: '1d' },
  // { label: 'Monthly', value: 'month' },
];

const AllocationResultsOverviewProgramMatchingChart = ({ program, summary, customers, programResults }: { program: IProgram, summary: IAllocationSummaryResult['summary_results'], customers: ICustomer[], programResults: IProgramAllocationSummaryResult | undefined }) => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params, setParams] = useSearchParams();
  const startDateStr = params.get('hs');
  const endDateStr = params.get('he');
  const resolution = params.get('hr') || '1d';

  const startAndEndDate = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));
  const fallbackStart = getThisYearStart();
  const fallbackEnd = getThisYearEnd();

  const startDate = useMemo(() => {
    return startDateStr ? new Date(startDateStr) : startAndEndDate?.start || fallbackStart;
  }, [startDateStr, startAndEndDate?.start, fallbackStart]);
  const endDate = useMemo(() => {
    return endDateStr ? new Date(endDateStr) : startAndEndDate?.end || fallbackEnd;
  }, [endDateStr, startAndEndDate?.end, fallbackEnd]);



  const [allocatedMWhSeriesByCustomerId, totalLoadMWhSeries, totalGenerationMWhSeries, excessGenerationMWhSeries] = useMemo(() => {
    if (!programResults) {
      return [null, null, null, null];
    }

    const allocatedTimeSeriesByCustomerId: Record<string, number[][]> = {};
    const customerLoadSeries: number[][] = [];
    const totalGenerationSeries: number[][] = [];
    const excessGenerationSeries: number[][] = [];
    programResults.hourly_results.forEach(hourResult => {
      const hour = new Date(hourResult.datetime);
      const isInRange = startDate <= hour && hour < endDate;
      if (isInRange) {
        let totalCustomerLoadMWh = 0;
        let totalExcessMWh = 0;
        Object.values(hourResult.program_allocation_by_subscription_id).forEach(subResult => {
          if (!allocatedTimeSeriesByCustomerId[subResult.customer_id]) {
            allocatedTimeSeriesByCustomerId[subResult.customer_id] = [];
          }
          const totalAllocatedMWhBySub = subResult.allocated_wh / 1_000_000;
          const dataPoint = [hour.valueOf(), totalAllocatedMWhBySub];
          allocatedTimeSeriesByCustomerId[subResult.customer_id].push(dataPoint);
          totalCustomerLoadMWh += subResult.consumption_wh / 1_000_000;
          totalExcessMWh += subResult.excess_gen_wh / 1_000_000
        });

        customerLoadSeries.push([hour.valueOf(), totalCustomerLoadMWh]);
        totalGenerationSeries.push([hour.valueOf(), hourResult.generation_mwh]);
        excessGenerationSeries.push([hour.valueOf(), totalExcessMWh]);
      }
    });


    const grouping = resolution === AssetEventResolution.DAY ? 'day' : 'hour';
    const groupedLoad = groupData(customerLoadSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedGeneration = groupData(totalGenerationSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedExcessGen = groupData(excessGenerationSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedAllocationSeriesByCustomer: Record<string, number[][]> = {};
    Object.entries(allocatedTimeSeriesByCustomerId).forEach(([cid, allocationSeries]) => {
      const grouped = groupData(allocationSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);
      groupedAllocationSeriesByCustomer[cid] = grouped;
    });


    return [groupedAllocationSeriesByCustomer, groupedLoad, groupedGeneration, groupedExcessGen];
  }, [resolution, startDate, endDate, programResults]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'hr', value: newResolution }]);
  };

  const hourlyMatchingChartOptions: Highcharts.Options = {
    chart: {
      height: '300px',
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      valueSuffix: ' MWh',
      valueDecimals: 1,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      }
    },
    series: [{
      type: 'column',
      name: 'Excess Generation',
      color: '#DEE2E6',
      data: excessGenerationMWhSeries || [],
    },
    ...Object.entries(allocatedMWhSeriesByCustomerId || {}).map(([cid, allocationSeries]) => {
      const customerName = customers.find(c => c.id === cid)?.name || 'unknown customer';
      return {
        type: 'column',
        name: customerName,
        data: allocationSeries || [],
      } as SeriesOptionsType;
    }), {
      type: 'line',
      name: 'Total Program Generation',
      color: '#62C1AB',
      data: totalGenerationMWhSeries || [],
    }, {
      type: 'line',
      name: 'Total Subscribers Consumption',
      color: '#339AF0',
      data: totalLoadMWhSeries || [],
    }],
    colors: ["#C3FAE8", "#A5D8FF", "#91A7FF", "#4D7396"],
  };

  return (
    <BasePaper titleContent={`${program.name} Generation Matching`} actions={
      <Group ml="lg" mr="lg" fw="400">
        <Select
          value={resolution}
          data={resolutionOptions}
          onChange={onResolutionChange}
          rightSection={<IconChevronDown size={20} />}
          w="120px"
          className="customer-view-consumption-resolution--select"
        />
        <UtcDatePicker
          value={startDate}
          isStartDate={true}
          minDate={startAndEndDate?.start || undefined}
          maxDate={endDate || startAndEndDate?.end}
          startQs="hs"
          endQs="he"
        />
        -
        <UtcDatePicker
          value={endDate}
          minDate={startDate || startAndEndDate?.start}
          maxDate={startAndEndDate?.end || undefined}
          startQs="hs"
          endQs="he"
        />
      </Group>
    }>
      <BaseChart overrideOptions={hourlyMatchingChartOptions} />
    </BasePaper>
  )
}

export default AllocationResultsOverviewProgramMatchingChart;