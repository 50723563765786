export enum AuditLogSubjectType {
  ASSET = "customer_asset",
  ASSET_EVENT = "customer_asset_event",
  CERT = "customer_certificate",
  CERT_PROP = "certificate_property",
  CERT_PROP_DEF = "certificate_property_definition",
  PROGRAM = "retail_program",
  PROGRAM_SUBSCRIPTION = "retail_program_customer_subscription",
  PROGRAM_ASSIGNMENT = "retail_program_generator_assignment",
  ALLOCATION_RUN = "allocation_run",
  EVENT_FORECAST_BATCH = "event_forecast_batch",
}

export const auditLogSubjectTypeToDisplayName = (type: AuditLogSubjectType): string => {
  switch (type) {
    case AuditLogSubjectType.ASSET:
      return "asset";
    case AuditLogSubjectType.ASSET_EVENT:
      return "event";
    case AuditLogSubjectType.CERT:
      return "certificate";
    case AuditLogSubjectType.CERT_PROP:
      return "certificate property";
    case AuditLogSubjectType.CERT_PROP_DEF:
      return "certificate property definition";
    case AuditLogSubjectType.PROGRAM:
      return "program";
    case AuditLogSubjectType.PROGRAM_SUBSCRIPTION:
      return "subscription";
    case AuditLogSubjectType.PROGRAM_ASSIGNMENT:
      return "assignment";
    case AuditLogSubjectType.ALLOCATION_RUN:
      return "allocation run";
    case AuditLogSubjectType.EVENT_FORECAST_BATCH:
      return "forecast";
    default:
      return "(unknown)";
  }
}


export interface IAuditLog {
  id: string
  timestamp: string
  actor_id: string
  subject_type: AuditLogSubjectType
  subject_id: string
  action: 'create' | 'update' | 'delete'
  data: Record<string, unknown>
  customer_id: string
}


export interface IListAuditLogsResponse {
  data: IAuditLog[];
}

export interface IFetchAuditLogResponse {
  audit_log: IAuditLog;
}

export interface IListAuditLogsArgs {
  page: number
  perPage: number
  action?: string | null
  subjectType?: string | null
  subjectIds?: string[] | null
  customerId?: string | null
}

