import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, TextInput } from "@mantine/core";
import { useLazyAdminFetchUserByEmailQuery, useLazyAdminFetchUserQuery } from "admin/api/users";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "shared/store/user/selectors";
import { IUser } from "shared/types/user";
import { useAppSelector } from "store";

import './style.css';


const FindUserModal = ({
  onClose,
  isOpen,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [fetchByEmail] = useLazyAdminFetchUserByEmailQuery();
  const [fetchById] = useLazyAdminFetchUserQuery();
  const currentUser = useAppSelector(getCurrentUser);
  const nav = useNavigate();

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!userId && !userEmail) {
      return
    }
    const fetchFn = userId ? fetchById : fetchByEmail;
    try {
      const {user} = await fetchFn(userId || userEmail).unwrap();
      goToUser(user);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const goToUser = (user: IUser) => {
    nav(`/admin/customers/${user.customer_id}/users/${user.id}`);
    onCloseHandler();
  };

  const onCloseHandler = (didSucceed = false) => {
    setUserId('');
    setUserEmail('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this user's role"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The ID of the user</InputLabel>
          <InputDescription>This is a UUID. It's long and difficult to read.</InputDescription>
          <TextInput value={userId} onChange={(e) => setUserId(e.target.value)}/>
        </InputWrapper>

        <InputWrapper mt={24}>
          <InputLabel>The email of the user</InputLabel>
          <InputDescription>This is an electronic mail address of the user.</InputDescription>
          <TextInput value={userEmail} onChange={(e) => setUserEmail(e.target.value)}/>
        </InputWrapper>

        <Button onClick={() => goToUser(currentUser)} mt={32} variant="outline">
          Just take me to my user
        </Button>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default FindUserModal;