import { equals } from "ramda";
import { useSearchParams } from "react-router-dom";

import { Divider, Drawer, Pill, Select, Title } from "@mantine/core";
import { useFetchAuditLogsPageQuery } from "amp/api/auditLogs";
import { ReactNode, useState } from "react";
import { usePaginateTeammatesQuery } from "shared/api/users";
import BaseTable, { IBaseTableColumn } from "shared/components/Table/baseTable";
import { IAuditLog } from "shared/types/auditLog";
import { IUser } from "shared/types/user";
import { timestampToLongDate } from "shared/utils/dates";

import { getViewingOpCoId } from "amp/store/ui/selectors";
import { useAppSelector } from "store";
import './style.css';

const actionOptions = [
  {value: 'create', label: 'Create'},
  {value: 'update', label: 'Update'},
  {value: 'delete', label: 'Delete'},
];

const subjectTypeOptions = [
  {label: 'Allocation Run', value: 'allocation_run'},
  {label: 'Event', value: 'customer_asset_event'},
  {label: 'Certificate', value: 'customer_certificate'},
  {label: 'Certificate Property', value: 'certificate_property'},
  {label: 'Certificate Property Definition', value: 'certificate_property_definition'},
  {label: 'Generator', value: 'customer_asset'},
  {label: 'Retail Program', value: 'retail_program'},
  {label: 'Customer Program Subscription', value: 'retail_program_customer_subscription'},
  {label: 'Generator Program Assignment', value: 'retail_program_generator_assignment'},
];

const subjectTypeToDisplay: Record<string, string> = {
  'allocation_run': 'Allocation Run',
  'customer_asset': 'Generator',
  'customer_asset_event': 'Event',
  'customer_certificate': 'Certificate',
  'certificate_property': 'Certificate Metadata',
  'certificate_property_definition': 'Certificate Property Definition',
  'retail_program': 'Retail Program',
  'retail_program_customer_subscription': 'Customer program subscription',
  'retail_program_generator_assignment': 'Generator program assignment',
};

const auditLogsTableColumns: IBaseTableColumn[] = [
  {key: 'subject_type', displayValue: 'Subject'},
  {key: 'timestamp', displayValue: 'Timestamp'},
  {key: 'actor', displayValue: 'Actor'},
  {key: 'action', displayValue: 'Action'},
];


const SystemBadge = () => <Pill>SYSTEM</Pill>;
const AdminBadge = () => <Pill>Singularity team</Pill>;

const AuditLogDrawer = ({log, usersById}: {log: IAuditLog, usersById: Record<string, IUser>}) => {
  let actor = <SystemBadge />;
  if (log.actor_id.startsWith('sadmin')) {
    actor = <AdminBadge />;
  } else if (log.actor_id.startsWith('user')) {
    const userAndId = log.actor_id.split(':');
    const userId = userAndId[1];
    actor = <div>{usersById[userId]?.name || 'teammate'}</div>;
  }
  const changes: {field: string, oldValue: string, newValue: string}[] = [];
  if ('changes' in log.data) {
    Object.entries(log.data.changes as Record<string, {from: unknown, to: unknown}>).forEach(([field, values]) => {
      if (equals(values.from, values.to)) return;

      switch (typeof values.from) {
        case 'string':
          changes.push({field, oldValue: values.from, newValue: values.to as string});
          break;
        case 'number':
          changes.push({field, oldValue: values.from.toString(), newValue: (values.to as number).toString()});
          break;
        case 'boolean':
          changes.push({field, oldValue: values.from.toString(), newValue: (values.to as boolean).toString()});
          break;
        case 'object':
          changes.push({field, oldValue: JSON.stringify(values.from), newValue: JSON.stringify(values.to as object)});
          break;
      }
    });
  }
  return (
    <div>
      <h4 className="audit-log-drawer--header">Basic information</h4>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Action taken</label>
        <div className="audit-log-info--value">{log.action}</div>
      </div>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Action done by</label>
        <div className="audit-log-info--value">{actor}</div>
      </div>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Action done to</label>
        <div className="audit-log-info--value">{subjectTypeToDisplay[log.subject_type] || log.subject_type}</div>
      </div>
      <Divider mt={12}/>
      <h4 className="audit-log-drawer--header">Detailed information</h4>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Timestamp</label>
        <div className="audit-log-info--value">{new Date(log.timestamp).toLocaleString('default', {month: 'short', year: 'numeric', day: 'numeric', weekday: 'short'})}</div>
      </div>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Actor ID</label>
        <div className="audit-log-info--value">{log.actor_id}</div>
      </div>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Customer ID</label>
        <div className="audit-log-info--value">{log.customer_id}</div>
      </div>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">{subjectTypeToDisplay[log.subject_type] || log.subject_type} ID</label>
        <div className="audit-log-info--value">{log.subject_id}</div>
      </div>
      {log.action === 'update' &&
        <>
          <Divider mt={12}/>
          <h4 className="audit-log-drawer--header">Edited information</h4>
          {changes.length === 0 && <p><em>No changes made</em></p>}
          {changes.map(change => (
            <div className="audit-log-info--container" key={change.field}>
              <label className="audit-log-info--label">{change.field}</label>
              <div className="audit-log-info--value old">{change.oldValue}</div>
              <div className="audit-log-info--value new">{change.newValue}</div>
            </div>
          ))}
        </>
      }
      <Divider mt={12}/>
      <h4 className="audit-log-drawer--header">Troubleshooting information</h4>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">Audit log ID</label>
        <div className="audit-log-info--value">{log.id}</div>
      </div>
      <div className="audit-log-info--container">
        <label className="audit-log-info--label">System trace ID</label>
        <div className="audit-log-info--value">{log.data.request_id as string}</div>
      </div>
    </div>
  );
};


const SettingsAuditLogsView = () => {
  const oci = useAppSelector(getViewingOpCoId);
  const [params, setParams] = useSearchParams();
  const [selectedLog, setSelectedLog] = useState<IAuditLog | null>(null);
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const action = params.get('a');
  const subjectType = params.get('st');

  const res = useFetchAuditLogsPageQuery({page, perPage, action, subjectType, customerId: oci});
  // TODO: what happens if the user belongs to a parent/child customer? e.g. the parent of the opco or the opco?
  // the list of teammates should likely come back in bootstrap
  const teamRes = usePaginateTeammatesQuery({page: 1, perPage: 100});

  const usersById: Record<string, IUser> = {};
  teamRes.data?.data.forEach(user => usersById[user.id] = user);


  const onTableRowClick = (auditLogId: string) => {
    const auditLog = res.data?.data.find(al => al.id === auditLogId) || null;
    setSelectedLog(auditLog);
  };

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setParams(newParams => {

      // reset the page when search params change
      if (paramName !== 'p') {
        newParams.delete('p');
      }

      // remove query params that would prevent the DB
      // from using an index
      if (paramName === 'st') {
        newParams.delete('a');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }

      return newParams;
    })
  };

  const onPageChange = (newPage: number) => {
    onParamChange('p', newPage.toString());
  };

  const canChooseAction = !!subjectType;

  const rows = res.data?.data.map((row) => {
    const [actorType, actorId] = row.actor_id.split(':');
    let actor: ReactNode = <SystemBadge />;
    if (actorType === 'user') {
      actor = usersById[actorId]?.name || 'teammate';
    } else if (actorType === 'sadmin') {
      actor = <AdminBadge />;
    }

    return {
      id: row.id,
      subject_type: subjectTypeToDisplay[row.subject_type] || row.subject_type,
      timestamp: timestampToLongDate(row.timestamp),
      action: row.action,
      actor,
    };
  }) || [];

  return (
    <div>
      <Title order={1} m={'lg'} className="singularity--auditLogs-title">
        Audit Logs
      </Title>
      <div className="audit-logs-dropdown--container">
        <Select
          label="Subject type"
          data={subjectTypeOptions}
          onChange={(e) => onParamChange('st', e)}
          value={subjectType}
          clearable
          className="audit-logs--dropdown"
        />
        <Select
          label="Action performed"
          data={actionOptions}
          onChange={(e) => onParamChange('a', e)}
          clearable
          // disable this dropdown if subject type is not chosen
          // otherwise the DB will cursor the entire table
          disabled={!canChooseAction}
          value={canChooseAction ? action : null}
          className="audit-logs--dropdown"
        />
      </div>
      <BaseTable
        columnNames={auditLogsTableColumns}
        rows={rows}
        totalPages={rows.length !== 10 ? page : page + 1}
        currentPage={page}
        onPageChange={onPageChange}
        onTableRowClicked={onTableRowClick}
      />
      <Drawer opened={!!selectedLog} onClose={() => setSelectedLog(null)} title="Audit log information">
        {!!selectedLog && <AuditLogDrawer log={selectedLog} usersById={usersById} />}
      </Drawer>
    </div>
  );

};

export default SettingsAuditLogsView;