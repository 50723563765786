import { Button, Group, InputDescription, InputLabel, InputWrapper, Modal, MultiSelect, SegmentedControl, Select, TextInput } from "@mantine/core";
import { useAdminCreateSSOConfigMutation, useAdminPatchSSOConfigMutation } from "admin/api/customers";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { ICustomerSSOConfig, SSOConfigStatus } from "shared/types/customer";


const EditSSOConfigModal = ({
  onClose,
  isOpen,
  customerId,
  ssoConfig,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customerId: string, ssoConfig?: ICustomerSSOConfig}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post] = useAdminCreateSSOConfigMutation();
  const [patch] = useAdminPatchSSOConfigMutation();
  const defaultConfigType = useMemo(() => {
    if (ssoConfig) {
      return ssoConfig.data_schema as 'saml2_v1' | 'oidc_v1';
    } else {
      return 'oidc_v1';
    }
  }, [ssoConfig])
  const [ssoConfigType, setSSOConfigType] = useState(defaultConfigType);
  const [samlMetadataEndpoint, setSamlMetadataEndpoint] = useState((ssoConfig?.data.saml_metadata_endpoint as string) || '');
  const [clientId, setClientId] = useState((ssoConfig?.data.client_id as string) || '');
  const [clientSecret, setClientSecret] = useState((ssoConfig?.data.client_secret as string) || '');
  const [responseType] = useState('code');
  const [scopes, setScopes] = useState((ssoConfig?.data.scopes as string[]) || ['openid']);
  const [authEndpoint, setAuthEndpoint] = useState((ssoConfig?.data.authorization_endpoint as string) || '');
  const [tokenEndpoint, setTokenEndpoint] = useState((ssoConfig?.data.token_endpoint as string) || '');
  const [userinfoEndpoint, setUserinfoEndpoint] = useState((ssoConfig?.data.userinfo_endpoint as string) || '');
  const [oidcConfigEndpoint, setOidcConfigEndpoint] = useState((ssoConfig?.data.oidc_config_endpoint as string) || '');
  const [status, setStatus] = useState(SSOConfigStatus.ACTIVE);
  const [name, setName] = useState((ssoConfig?.meta.sso_provider_name as string) || '');

  useEffect(() => {
    setClientId((ssoConfig?.data.client_id as string) || '');
    setClientSecret((ssoConfig?.data.client_secret as string) || '');
    setScopes((ssoConfig?.data.scopes as string[]) || ['openid']);
    setAuthEndpoint((ssoConfig?.data.authorization_endpoint as string) || '');
    setTokenEndpoint((ssoConfig?.data.token_endpoint as string) || '');
    setUserinfoEndpoint((ssoConfig?.data.userinfo_endpoint as string) || '');
    setOidcConfigEndpoint((ssoConfig?.data.oidc_config_endpoint as string) || '');
    setName((ssoConfig?.meta.sso_provider_name as string) || '');
    setSamlMetadataEndpoint((ssoConfig?.data.saml_metadata_endpoint as string) || '');
    setSSOConfigType(ssoConfig?.data_schema as 'oidc_v1' | 'saml2_v1' || 'oidc_v1');
  }, [ssoConfig]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customerId) {
      return
    }
    const oidcData = {
      client_id: clientId,
      client_secret: clientSecret,
      response_type: responseType,
      scopes,
      authorization_endpoint: authEndpoint,
      token_endpoint: tokenEndpoint,
      userinfo_endpoint: userinfoEndpoint,
      oidc_confg_endpoint: oidcConfigEndpoint,
    };
    const samlData = {
      saml_metadata_endpoint: samlMetadataEndpoint,
    };

    const payload: Partial<ICustomerSSOConfig> = {
      status,
      data: ssoConfigType === 'oidc_v1'? oidcData : samlData,
      data_schema: ssoConfigType,
      meta: {},
    }
    if (name) {
      (payload.meta as Record<string, unknown>).sso_provider_name = name;
    }
    let apiCall = undefined
    // in this case, we're patching an existing sso config
    if (ssoConfig) {
      payload.id = ssoConfig.id;
      apiCall = patch;
    } else {
      // in this case, we're creating a new sso config
      payload.customer_id = customerId;
      apiCall = post;
    }
    try {
      await apiCall(payload).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title={!ssoConfig ? "Create a new SSO config for this customer" : "Edit this SSO Config"}
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The ID of the customer</InputLabel>
          <TextInput value={customerId} disabled />
        </InputWrapper>

        <InputWrapper mt={16}>
          <InputLabel>Login provider name</InputLabel>
          <InputDescription>If provided and there is more than 1 SSO config, the user will see a button saying "Login via NAME"</InputDescription>
          <TextInput value={name} onChange={(e) => setName(e.target.value)} />
        </InputWrapper>

        <Select data={[
          {value: SSOConfigStatus.ACTIVE, label: 'active'},
          {value: SSOConfigStatus.DISABLED, label: 'disabled'},
          {value: SSOConfigStatus.DELETED, label: 'deleted'},
          {value: SSOConfigStatus.SUSPENDED, label: 'suspended'},
        ]} value={status} label="Status" onChange={(newStatus) => newStatus && setStatus(newStatus as SSOConfigStatus)} />

        <InputWrapper mt={16}>
          <InputLabel>SSO Protocol</InputLabel>
          <div>
            <SegmentedControl
              value={ssoConfigType}
              color="blue"
              onChange={(type: string) => setSSOConfigType(type as 'oidc_v1' |'saml2_v1')}
              data={[
                {value: 'oidc_v1', label: 'OpenID Connect'},
                {value: 'saml2_v1', label: 'SAML 2.0'},
              ]}
            />
          </div>
        </InputWrapper>

        {ssoConfigType === 'oidc_v1' &&
        <>
          <InputWrapper mt={16}>
            <InputLabel>Client ID</InputLabel>
            <TextInput value={clientId} onChange={(e) => setClientId(e.target.value)} />
          </InputWrapper>

          <InputWrapper mt={16}>
            <InputLabel>Client Secret</InputLabel>
            <TextInput value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} />
          </InputWrapper>

          <InputWrapper mt={16}>
            <InputLabel>Response type</InputLabel>
            <InputDescription>Unable to be changed for now</InputDescription>
            <TextInput value={responseType} disabled />
          </InputWrapper>

          <MultiSelect data={[
            {value: 'openid', label: 'openid'},
            {value: 'profile', label: 'profile'},
            {value: 'email', label: 'email'},
          ]} value={scopes} label="Scopes" onChange={(newScopes) => setScopes(newScopes)} />

          <InputWrapper mt={16}>
            <InputLabel>Auth endpoint</InputLabel>
            <TextInput value={authEndpoint} onChange={(e) => setAuthEndpoint(e.target.value)} />
          </InputWrapper>

          <InputWrapper mt={16}>
            <InputLabel>Token endpoint</InputLabel>
            <TextInput value={tokenEndpoint} onChange={(e) => setTokenEndpoint(e.target.value)} />
          </InputWrapper>

          <InputWrapper mt={16}>
            <InputLabel>Userinfo endpoint</InputLabel>
            <TextInput value={userinfoEndpoint} onChange={(e) => setUserinfoEndpoint(e.target.value)} />
          </InputWrapper>

          <InputWrapper mt={16}>
            <InputLabel>OIDC Well Known Config endpoint</InputLabel>
            <TextInput value={oidcConfigEndpoint} onChange={(e) => setOidcConfigEndpoint(e.target.value)} />
          </InputWrapper>
        </>}

        {ssoConfigType ==='saml2_v1' &&
        <>
          <InputWrapper mt={16}>
            <InputLabel>Metadata endpoint</InputLabel>
            <InputDescription>The URL of the SAML metadata document.</InputDescription>
            <TextInput value={samlMetadataEndpoint} onChange={(e) => setSamlMetadataEndpoint(e.target.value)} />
          </InputWrapper>
        </>}

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditSSOConfigModal;