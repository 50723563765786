import { Box, Button, Group, LoadingOverlay, Skeleton, Stack, Text, Title } from '@mantine/core';
import { IconBolt, IconPlus } from '@tabler/icons-react';
import { sum } from 'ramda';

import { AmpLink } from 'amp/components/Link';
import { useProgram, useUtilityPrograms } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { UserRole } from 'shared/types/user';
import { isDateWithinDateRange } from 'shared/utils/dates';
import { snakeToTitle } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';


const actPeriodText = (actPeriod: string | undefined) => {
  if (!actPeriod) {
    return '';
  }

  return actPeriod === 'annual' ? 'Annual Program' : '24/7 Program';
}

const ProgramCard = ({ programId }: { programId: string }) => {
  const programRes = useProgram(programId);
  const { assignments, subscriptions, program } = programRes.data;

  const now = new Date();

  const activeAssignments = assignments?.filter(a => {
    if (!a.data.configuration.assignment_start || !a.data.configuration.assignment_end) {
      // assignments without date ranges set are considered "always active"
      return true;
    } else if (!a.data.configuration.assignment_end) {
      // start exists but end doesn't - always active after start
      const start = new Date(a.data.configuration.assignment_start);
      return isDateWithinDateRange(now, start, new Date('2099-01-01T00:00:00Z'));
    } else if (!a.data.configuration.assignment_start) {
      // end exists but start doesn't - always active before end
      const end = new Date(a.data.configuration.assignment_end);
      return isDateWithinDateRange(now, new Date('1990-01-01T00:00:00Z'), end);
    } else {
      // both are present
      const start = new Date(a.data.configuration.assignment_start);
      const end = new Date(a.data.configuration.assignment_end);
      return isDateWithinDateRange(now, start, end);
    }
  });

  const activeSubscriptions = subscriptions?.filter(s => {
    if (!s.data.configuration.subscription_start || !s.data.configuration.subscription_end) {
      return true;
    } else if (!s.data.configuration.subscription_end) {
      // start exists but end doesn't - always active after start
      const start = new Date(s.data.configuration.subscription_start);
      return isDateWithinDateRange(now, start, new Date('2099-01-01T00:00:00Z'));
    } else if (!s.data.configuration.subscription_start) {
      // end exists but start doesn't - always active before end
      const end = new Date(s.data.configuration.subscription_end);
      return isDateWithinDateRange(now, new Date('1990-01-01T00:00:00Z'), end);
    } else {
      // both are present
      const start = new Date(s.data.configuration.subscription_start);
      const end = new Date(s.data.configuration.subscription_end);
      return isDateWithinDateRange(now, start, end);
    }
  });

  const currentCommitmentPercentTenThousandths = sum(activeSubscriptions.map(s => {
    return (s.data?.configuration?.percent_generation_dedicated_ten_thousandths / 10_000) || 0
  }));

  if (!program) {
    return (
      <BasePaper>
        <div style={{ height: '80px' }} />
      </BasePaper>
    );
  }

  return (
    <BasePaper>
      <div className="programs-page--card-container">
        <Skeleton visible={programRes.isLoading} maw={260}>
          <div className="programs-page--card-info-container">
            <div className="programs-page--program-status-container">
              <Text
                className="programs-page--program-status"
                p="4px 6px"
                display="inline"
                bg={program.status === 'active' ? '#ecf8f5' : '#f8f3ec'}
                c={program.status === 'active' ? 'var(--color-blue-1)' : 'var(--color-blue-3)'}
              >
                {snakeToTitle(program.status)}
              </Text>
            </div>

            <div className="programs-page--program-card-name">{program.name}</div>
            <Text bg="f8f9fa" c="#4d7396">
              {actPeriodText(program.data.program_config?.accounting_period)}
            </Text>
          </div>
        </Skeleton>

        <div className="programs-page--card-stats-container">
          <Skeleton visible={programRes.isLoading}>
            <Stack gap="4px" align="center">
              <div className="programs-page--card-topline-number">{activeSubscriptions?.length || 0}</div>
              <div className="programs-page--stat-subtext">Active Subscriptions</div>
            </Stack>
          </Skeleton>
          <Skeleton visible={programRes.isLoading}>
            <Stack gap="4px" align="center">
              <Group gap="6px">
                <IconBolt color="#245d5b" />
                <div className="programs-page--card-topline-number">{activeAssignments?.length || 0}</div>
              </Group>
              <div className="programs-page--stat-subtext">Generators Assigned</div>
            </Stack>
          </Skeleton>
          <Skeleton visible={programRes.isLoading}>
            <Stack gap="4px" align="center">
              <div className="programs-page--card-topline-number">{currentCommitmentPercentTenThousandths.toFixed(1) || 0} %</div>
              <div className="programs-page--stat-subtext">Current Commitment</div>
            </Stack>
          </Skeleton>
        </div>
      </div>
    </BasePaper>
  );
};

const ProgramsView = () => {
  const oci = useAppSelector(getViewingOpCoId);
  // This should cover the scale of the pilot
  const res = useUtilityPrograms({ page: 1, perPage: 50, statuses: ['active', 'inactive'] });
  const programs = res.data;

  return (
    <div className="programs-page--container">
      <div className="programs-page--title-container">
        <Title size="24px">
          Programs
        </Title>
        <RoleRequired role={UserRole.ADMIN}>
          <AmpLink to="/dashboard/programs/create">
            <Button fw={400} size="sm" className="programs-page--create-button" leftSection={<IconPlus size={20} />}>
              Add Program
            </Button>
          </AmpLink>
        </RoleRequired>
      </div>

      <div className="programs-page--scroll-area">
        {res.isLoading && <Box pos="relative" w="100%" h="100%">
          <LoadingOverlay visible={true} />
        </Box>}
        <Stack gap="lg">
          {(programs && programs.length === 0) && <BasePaper>
            No programs found
          </BasePaper>}
          {programs && programs.map(program => (
            <AmpLink to={`/dashboard/programs/${program.id}?oci=${oci}`} key={program.id}>
              <ProgramCard programId={program.id} />
            </AmpLink>
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default ProgramsView;