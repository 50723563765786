import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardPagination, IDashboardPaginationResponse } from 'shared/types/api';

import { fetchProgramsPage, IListProgramsArgs } from 'amp/api/programs';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { getOpcos } from 'shared/store/user/selectors';
import { IGeneratorAssignment } from 'shared/types/assignment';
import { IProgram } from 'shared/types/program';
import { IAsyncDataSlice } from 'shared/types/store';
import { ISubscription } from 'shared/types/subscription';
import { AppDispatch, RootState } from 'store';
import { getUtilityProgramPageLoading } from './selectors';

interface IProgramSlice {
  byId: Record<string, IProgram>
  subscriptionsById: Record<string, ISubscription>,
  assignmentsById: Record<string, IGeneratorAssignment>,
  programsPageResponseByOCI: Record<string, IAsyncDataSlice<string[]>>,
}

const initialState: IProgramSlice = {
  byId: {},
  subscriptionsById: {},
  assignmentsById: {},
  programsPageResponseByOCI: {},
}


export const fetchUtilityPrograms = createAsyncThunk<{data?: IDashboardPaginationResponse<IProgram>, oci: string, status: 'SUCCESS' | 'FAILED'} | undefined, IListProgramsArgs, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'amp__programs/fetchUtilityPrograms',
  async ({perPage, page, statuses}, {getState, dispatch}) => {
    const state = getState();
    const opcoId = getViewingOpCoId(state);
    const opcos = getOpcos(state);
    const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
    const oci = customerIds.sort().join(',');
    dispatch(programsSlice.actions.initializeProgramPageForOCI(oci));

    if (getUtilityProgramPageLoading(getState(), oci)) {
      // short circuit
      return undefined;
    }
    dispatch(programsSlice.actions.setProgramPageLoading({oci, isFetching: true}));

    // TODO: make these parameters
    const qs = new URLSearchParams();
    qs.set('per_page', perPage.toString());
    qs.set('page', page.toString());
    statuses?.forEach(status => {
      qs.append('statuses', status);
    })

    try {
      const programsById: Record<string, IProgram> = {};
      let pagination: IDashboardPagination = {this: 1, last: 1, total: 1, first: 1};
      const programsRes = await Promise.all(customerIds.map(cid => fetchProgramsPage(qs.toString() + `&customer_id=${cid}`)));
      programsRes.forEach(res => {
        pagination = res.data.meta.pagination;
        res.data.data.forEach(prog => {
          programsById[prog.id] = prog;
        });
      })
      return {data: {data: Object.values(programsById), meta: {pagination}}, status: 'SUCCESS', oci};
    } catch (err) {
      return {status: 'FAILED', oci};
    }
  },
);


const programsSlice = createSlice({
  name: 'amp__programs',
  initialState,
  reducers: {
    receivePrograms: (state, action: PayloadAction<IProgram[]>) => {
      action.payload.forEach(program => {
        state.byId[program.id] = program;
      });
    },

    receiveSubscriptions: (state, action: PayloadAction<ISubscription[]>) => {
      action.payload.forEach(sub => {
        state.subscriptionsById[sub.id] = sub;
      });
    },

    receiveAssignments: (state, action: PayloadAction<IGeneratorAssignment[]>) => {
      action.payload.forEach(assignment => {
        state.assignmentsById[assignment.id] = assignment;
      });
    },

    setProgramPageLoading: (state, action: PayloadAction<{oci: string, isFetching: boolean}>) => {
      state.programsPageResponseByOCI[action.payload.oci].isFetching = action.payload.isFetching;
    },

    initializeProgramPageForOCI: (state, action: PayloadAction<string>) => {
      if (!state.programsPageResponseByOCI[action.payload]) {
        state.programsPageResponseByOCI[action.payload] = {
          data: null,
          lastReceived: null,
          isFetching: false,
          fetchFailed: false,
          pagination: null,
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUtilityPrograms.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      const ociState = state.programsPageResponseByOCI[action.payload.oci];

      ociState.isFetching = false;

      if (action.payload.data) {
        const programs = action.payload.data.data;
        ociState.data = programs.map(c => c.id);
        programs.forEach(prog => {
          state.byId[prog.id] = prog;
        });
        ociState.fetchFailed = false;
        ociState.lastReceived = new Date();
      } else {
        ociState.fetchFailed = true;
      }
    })
  }
});


export const { receivePrograms, receiveSubscriptions, receiveAssignments } = programsSlice.actions;
export default programsSlice.reducer;