import { Button, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchUserQuery } from "admin/api/users";
import EditCustomerIdModal from "admin/components/EditCustomerIdModal";
import EditRoleModal from "admin/components/EditRoleModal";
import EditStatusModal from "admin/components/EditStatusModal";
import SendWelcomeEmailModal from "admin/components/SendWelcomeEmailModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { useState } from "react";
import './style.css';


const CustomerUserView = () => {
  const { userId='' } = useParams<{userId: string}>();
  const [userRoleModalOpen, setUserRoleModalOpen] = useState(false);
  const [userStatusModalOpen, setUserStatusModalOpen] = useState(false);
  const [userCustomerIdModalOpen, setUserCustomerIdModalOpen] = useState(false);
  const [welcomeEmailModalOpen, setWelcomeEmailModalOpen] = useState(false);
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchUserQuery(userId, {skip: !userId});

  if (isError) {
    return <div>Failed to load user</div>;
  }

  const user = data?.user;

  const onCloseRoleModal = (didSucceed: boolean) => {
    setUserRoleModalOpen(false);
  }

  const onCloseStatusModal = (didSucceed: boolean) => {
    setUserStatusModalOpen(false);
  }

  const onCloseCustomerIdModal = (didSucceed: boolean) => {
    setUserCustomerIdModalOpen(false);
  }

  const onCloseWelcomEmailModal = (didSucceed: boolean) => {
    setWelcomeEmailModalOpen(false);
  }

  return <AdminViewContainer title={user?.name || userId} actions={
    <>
      <Button mr={16} onClick={() => setUserRoleModalOpen(true)} variant="light" size="xs">Change role</Button>
      <Button mr={16} onClick={() => setUserStatusModalOpen(true)} variant="light" size="xs">Change status</Button>
      <Button mr={16} onClick={() => setUserCustomerIdModalOpen(true)} variant="light" size="xs">Change customer</Button>
      <Button mr={16} onClick={() => setWelcomeEmailModalOpen(true)} variant="light" size="xs">Send AMP Welcome Email</Button>
    </>
  }>
    {(isLoading || !user) && <Loader />}
    {user && <div className="admin-user-data--container">
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">Name</div>
        <div className="admin-user-data-field--value">{user.name}</div>
      </div>
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">Email</div>
        <div className="admin-user-data-field--value">{user.email}</div>
      </div>
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">Role</div>
        <div className="admin-user-data-field--value">{user.role}</div>
      </div>
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">Status</div>
        <div className="admin-user-data-field--value">{user.status}</div>
      </div>
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">Customer</div>
        <div className="admin-user-data-field--value">{user.customer_id}</div>
      </div>
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">Created</div>
        <div className="admin-user-data-field--value">{user.created_at}</div>
      </div>
      <div className="admin-user-data-field--container">
        <div className="admin-user-data-field--label">ID</div>
        <div className="admin-user-data-field--value">{user.id}</div>
      </div>
      <div>
        <div>Properties</div>
        <pre>{JSON.stringify(user.attributes, undefined, 2)}</pre>
      </div>
    </div>}
    {user &&
      <>
        <EditRoleModal user={user} isOpen={userRoleModalOpen} onClose={onCloseRoleModal} />
        <EditStatusModal user={user} isOpen={userStatusModalOpen} onClose={onCloseStatusModal} />
        <EditCustomerIdModal user={user} isOpen={userCustomerIdModalOpen} onClose={onCloseCustomerIdModal} />
        <SendWelcomeEmailModal user={user} isOpen={welcomeEmailModalOpen} onClose={onCloseWelcomEmailModal} />
      </>
    }
  </AdminViewContainer>
};

export default CustomerUserView;
