import { Box, Button, Group, Notification, Select, TextInput, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useCreateCertPropDefMutation } from 'amp/api/certPropDefs';
import { FormEvent, useState } from 'react';

import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { CertificatePropertyType, ICreateCertificatePropertyDefinitionBody, displayValuesToRequestData, errorResponseToDisplayErrors } from 'shared/types/certificateProperties';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { useAppSelector } from 'store';
import './style.css';

const emptyCertPropDef: Partial<ICreateCertificatePropertyDefinitionBody> = {
  property_type: CertificatePropertyType.STRING,
};

const propTypeOptions = Object.values(CertificatePropertyType);

const isCodeNameTakenErr = (err: unknown) => {
  return typeof err === 'object' && err !== null &&
    'data' in err && typeof err.data === 'object' &&
    err.data !== null && 'error_code' in err.data &&
    'error_message' in err.data && typeof err.data.error_message === 'string' &&
    err.data.error_code === 'cert-prop-def-code-name-taken';
}

export default function SettingsCertificatePropertyDefinitionCreateView() {
  const [isLoading, setIsLoading] = useState(false);
  const oci = useAppSelector(getViewingOpCoId);
  const [submittedState, setSubmittedState] = useState<null | 'success' | 'fail'>(null);
  const [create] = useCreateCertPropDefMutation();

  const form = useForm({ initialValues: emptyCertPropDef });

  const onSubmit = async (values: typeof form.values, e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      await create({data: displayValuesToRequestData(values), customerId: oci}).unwrap();
      setSubmittedState('success');
    } catch (err) {
      setSubmittedState('fail');
      const errMsgs = getErrorMessagesFromApiError(err) || {};
      if (isCodeNameTakenErr(err)) {
        errMsgs.display_name = [(err as {data: {error_message: string}}).data.error_message];
      }

      if (errMsgs && Object.keys(errMsgs).length) {
        form.setErrors(errorResponseToDisplayErrors(errMsgs));
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSubmittedState(null);
      }, 1200);
    }
  };

  return (
    <div>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box maw="50vw" ml="lg">
          <div className="certificate-prop-def-create-form--field-section-container">
            <TextInput
              withAsterisk
              placeholder="Name"
              label="Display Name"
              mb="lg"
              {...form.getInputProps('display_name')}
            />
            <TextInput
              placeholder="Property Description"
              label="description"
              mb="lg"
              {...form.getInputProps('description')}
            />
            <Select
              label="Property type"
              data={propTypeOptions}
              className="audit-logs--dropdown"
              clearable
              {...form.getInputProps('property_type')}
            />
          </div>

          <Group justify="flex-end" mt="md">
            <Button loading={isLoading} type="submit">Submit</Button>
          </Group>
        </Box>
      </form>
      <Transition mounted={submittedState === 'success'}>
        {(transitionStyle) => (
          <Notification
            onClose={() => setSubmittedState(null)}
            title="Success"
            icon={<IconCheck style={{ width: '20px', height: '20px' }} />}
            color="teal"
            className="dashboard-global--notification-container"
            style={transitionStyle}
          >
            Your changes have been saved
          </Notification>
        )}
      </Transition>
      <Transition mounted={submittedState === 'fail'}>
        {(transitionStyle) => (
          <Notification
            onClose={() => setSubmittedState(null)}
            title="Error"
            icon={<IconX style={{ width: '20px', height: '20px' }} />}
            color="red"
            className="dashboard-global--notification-container"
            style={transitionStyle}
          >
            We failed to save your changes, please try again
          </Notification>
        )}
      </Transition>
    </div>
  );
}