// https://stackoverflow.com/questions/64489395/converting-snake-case-string-to-title-case
export const snakeToTitle = (str: string) => (
  str.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase()).replace(/-/g, ' ')
);

export const looksLikeUUID = (maybeUUIDStr: string) => {
  if (maybeUUIDStr.length === 36) {
    // if it has dashes, there will be a -4 in this spot
    return maybeUUIDStr.slice(13, 15) === '-4';
  } else if (maybeUUIDStr.length === 32) {
    // if no dashes, there will be a 4 in this spot
    return maybeUUIDStr[12] === '4';
  }
  return false;
};

const numberFmt = new Intl.NumberFormat('default', {});
export const numberToString = (number: number) => numberFmt.format(number);

const moneyFmt = new Intl.NumberFormat('default', { style: 'currency', currency: 'USD' });
export const moneyToString = (money: number) => moneyFmt.format(money);


// Converts any number to a shorthand notation with 3 sigfigs. e.g. 4,567,890 -> 4.57M
export const numberToShortString = (number: number) => number.toLocaleString('default', { notation: 'compact', maximumFractionDigits: 2, maximumSignificantDigits: 3 });


// currently used for converting wh -> kWh, MWh, GWh, or TWh
// e.x. 50_000_000 -> 50M
//      50_000_000_000 -> 50G
// TODO: return a structured object instead with units and number fields
export const numberToSiUnits = (number: number) => {
  if (number >= 1_000_000_000_000) {
    return `${(number / 1_000_000_000_000).toFixed(1)}T`
  } else if (number >= 1_000_000_000) {
    return `${(number / 1_000_000_000).toFixed(1)}G`
  } else if (number >= 1_000_000) {
    return `${(number / 1_000_000).toFixed(1)}M`
  } else if (number >= 1_000) {
    return `${(number / 1_000).toFixed(1)}k`
  } else  {
    return `${number.toFixed(0)} `;
  }
}


// currently used for converting wh -> kWh, MWh, GWh, or TWh
// e.x. 50_000_000 -> 50M
//      50_000_000_000 -> 50G
export const numberToSiFormat = (number: number) => {
  if (number >= 1_000_000_000_000) {
    return {
      value: (number / 1_000_000_000_000).toFixed(1),
      unitPrefix: 'T',
    }
  } else if (number >= 1_000_000_000) {
    return {
      value: (number / 1_000_000_000).toFixed(1),
      unitPrefix: 'G',
    }
  } else if (number >= 1_000_000) {
    return {
      value: (number / 1_000_000).toFixed(1),
      unitPrefix: 'M',
    }
  } else if (number >= 1_000) {
    return {
      value: (number / 1_000).toFixed(1),
      unitPrefix: 'k',
    }
  } else  {
    return {
      value: number.toFixed(1),
      unitPrefix: '',
    }
  }
}